<template>
  <svg v-if="theme === 'light'" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.6416 4.00409C8.30827 4.38899 8.30827 5.35124 7.6416 5.73614L1.94043 9.02772C1.27376 9.41262 0.440429 8.93149 0.440429 8.16169L0.44043 1.57854C0.44043 0.808745 1.27376 0.327618 1.94043 0.712518L7.6416 4.00409Z" fill="white"/>
  </svg>
  <svg v-if="theme === 'dark'" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.6416 4.00409C8.30827 4.38899 8.30827 5.35124 7.6416 5.73614L1.94043 9.02772C1.27376 9.41262 0.440429 8.93149 0.440429 8.16169L0.44043 1.57854C0.44043 0.808745 1.27376 0.327618 1.94043 0.712518L7.6416 4.00409Z" fill="#1B3B3D"/>
  </svg>


</template>
<script >
export default {
  name: "PlayIcon",
  props: {
    theme: {
      type: String,
      default: '',
    }
  }
}
</script>