<script >
export default {
  name: "FacebookIcon",
  props: {
    theme: {
      type: String,
      default: '',
    }
  }
}
</script>

<template>
  <svg v-if="theme === 'light'" width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7_47)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.39551 2.21776H6.5V0H4.77377C4.0088 0.00097818 3.27542 0.323894 2.7345 0.897917C2.19358 1.47194 1.88929 2.2502 1.88837 3.06199V4.43553H0.5V6.65329H1.89324V14H3.98311V6.65329H6.0876L6.5 4.43553H3.98311V2.65466C3.98473 2.5392 4.02872 2.42896 4.10573 2.34737C4.18274 2.26579 4.2867 2.21929 4.39551 2.21776Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_7_47">
        <rect width="6" height="14" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
  <svg v-if="theme === 'dark'" width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7_47)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.39551 2.21776H6.5V0H4.77377C4.0088 0.00097818 3.27542 0.323894 2.7345 0.897917C2.19358 1.47194 1.88929 2.2502 1.88837 3.06199V4.43553H0.5V6.65329H1.89324V14H3.98311V6.65329H6.0876L6.5 4.43553H3.98311V2.65466C3.98473 2.5392 4.02872 2.42896 4.10573 2.34737C4.18274 2.26579 4.2867 2.21929 4.39551 2.21776Z" fill="#1B3B3D"/>
    </g>
    <defs>
      <clipPath id="clip0_7_47">
        <rect width="6" height="14" fill="#1B3B3D" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>
