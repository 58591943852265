<template>
  <div class="cocktails-container d-flex justify-content-center">
    <div class="cocktails-wrapper ">
      <div class="d-md-flex align-items-center cocktails-nav">
        <div class="me-auto all-cocktails text-center">All Cocktails</div>
        <div class="d-flex justify-content-center nav-filters">
          <div @click="toggleFilterByBrand">
            <MainButton :theme="'dark'" :button-text="'Select a brand'" :button-icon="'arrow-down'"/>
          </div>
          <div @click="toggleFilterByType">
            <MainButton :theme="'dark'" :button-text="'Cocktail type'" :button-icon="'arrow-down'"/>
          </div>
        </div>
      </div>
      <div class="filter-modal">
        <CocktailsFilterModal
          :filter-type="'brand'"
          :brands="brands"
          @onBrandFilter="brandFilter"
          @close-modal="closeModal"
          v-if="filterByBrand"/>
        <CocktailsFilterModal
          :filter-type="'type'"
          @close-modal="closeModal"
          @onBrandFilter="brandFilter"
          v-if="filterByType"/>
      </div>
      <div class="cocktails-grid">
        <div class="row">
          <div class="col-md-4 col-6" v-for="(cocktail, index) in paginatedCocktails" :key="index">
            <div @click="openOneCocktail(cocktail)" class="no-underline">
              <OneCocktailBox
                :cocktail-title="cocktail.title"
                :cocktail-subtitle="cocktail.subtitle"
                :thumb-url="cocktail.thumbnail[0]" />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center cocktails-pagination" v-if="totalPages > 1">
          <div class="">
            <CocktailsPagination :current-page="currentPage" :total-pages="totalPages" @page-changed="setPage" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <OneCocktailComponent ref="oneCocktailComponent" :cocktail="openCocktail"/>
</template>

<script>
import MainButton from "@/components/Common/Buttons/MainButton";
import OneCocktailBox from "@/components/Components/OneCocktailBox";
import CocktailsPagination from "@/components/Components/CocktailsPagination";
import CocktailsFilterModal from "@/components/Components/CocktailsFilterModal";
import OneCocktailComponent from "@/components/Components/OneCocktailComponent.vue";

export default {
  name: "AllCocktailsView",
  components: {
    OneCocktailComponent,
    MainButton,
    OneCocktailBox,
    CocktailsPagination,
    CocktailsFilterModal
  },
  data() {
    return {
      filterByType: false,
      filterByBrand: false,
      allCocktails: [],
      cocktails: [],
      brands: [],
      currentPage: 1,
      itemsPerPage: 6,
      openCocktail: null
    };
  },
  async mounted() {
    this.allCocktails = await this.$store.dispatch("cocktail/getCocktails");
    this.cocktails = this.allCocktails;
    this.brands = this.cocktails.map((item) => {
      return item.subtitle;
    });
    this.brands = this.brands.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
  },
  computed: {
    paginatedCocktails() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.cocktails.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.cocktails.length / this.itemsPerPage);
    }
  },
  methods: {
    toggleFilterByBrand() {
      this.filterByBrand = !this.filterByBrand;
      this.filterByType = false;
    },
    toggleFilterByType() {
      this.filterByType = !this.filterByType;
      this.filterByBrand = false;
    },
    closeModal() {
      // Close the modal
      this.filterByBrand = false;
      this.filterByType = false;
    },
    setPage(page) {
      this.currentPage = page;
    },
    openOneCocktail(cocktail) {
      this.openCocktail = cocktail;
      this.$store.dispatch("utils/toggleHeader", false);
      setTimeout(()=>{
        this.$refs.oneCocktailComponent.show();
      }, 100);
    },
    brandFilter(brands) {
      this.cocktails = this.allCocktails;
      this.currentPage = 1;
      if (!brands || brands.length === 0 || brands.includes('All Cocktails')) {
        return;
      }
      this.cocktails = this.cocktails.filter((item) => {
        return brands.includes(item.subtitle);
      })
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~@/assets/scss/fonts.scss";

.cocktails-container {
  background: #FFFFFF;
  padding-top: 100px;
  height: 100vh;
}

.cocktails-wrapper {
  background: #F2F2E4;
  width: 90%;
  border-radius: 20px;
  padding-bottom: 100px;
  margin-bottom: 20px;
  padding-right: 5px;
  overflow: hidden;

  .filter-modal {
    position: absolute;
    width: 90%;
    z-index: 1;
  }

  .cocktails-nav {
    padding: 20px;
    border-bottom: 1px solid #CDCDCD;

    .all-cocktails {
      font-size: 14px;
      font-family: $font-bold;
    }

    .nav-filters {
      margin-top: 25px;
    }
  }

  .cocktails-grid {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    padding-bottom: 50px;

    .cocktails-pagination {
      margin-top: 50px;
    }
  }
}

.cocktails-grid::-webkit-scrollbar {
  width: 4px; /* Set the width of the scrollbar */
  padding: 10px;
}

.cocktails-grid::-webkit-scrollbar-track {
  background: transparent;
}

.cocktails-grid::-webkit-scrollbar-thumb {
  background: #1b3b3d;
}

@media only screen and (min-width: 768px) {
  .cocktails-container {
    height: 100vh;
  }
  .cocktails-wrapper {
    padding-bottom: 50px;

    .cocktails-nav {
      .nav-filters {
        margin-top: 0;
      }
    }

    .cocktails-grid {
      padding-top: 10px;

      .cocktails-pagination {
        margin-top: 80px;

      }
    }
  }

}
</style>
