import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'bootstrap/dist/css/bootstrap.css';
import { i18n } from './i18n'
import {VueReCaptcha} from "vue-recaptcha-v3";

createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(VueReCaptcha, {
        siteKey: '6Le3pjUpAAAAAM7aXcSXFv8nvAYUormZRv_H1jel',
        loaderOptions: {
            autoHideBadge: true
        }
    })
    .mount("#app");
