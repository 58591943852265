<template>
  <div class="wrapper d-flex align-items-center justify-content-center">
    <div class="age-gateway align-self-center">
      <div>
        <div class="mb-5">Are you above 18 years old?</div>
        <div>
          <BorderOutlineButton
              class="me-2"
              theme="dark"
              :button-text="'Yes'"
              :show-icon="false"
              @click="$emit('pasAgeGate')"
          />
          <BorderOutlineButton
              theme="red"
              :button-text="'No'"
              :show-icon="false"
              @click="$emit('leaveAgeGate')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import BorderOutlineButton from "@/components/Common/Buttons/BorderOutlineButton.vue";

export default defineComponent({
  name: "AgeGateComponent",
  components: {BorderOutlineButton},
  props: {
    progress: Number
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/fonts.scss";

.wrapper {
  background: #F2F2E4;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;

  .age-gateway {
    text-transform: uppercase;
    font-family: CommissionerBold;
    color: #1B3B3D;
    text-align: center;
    font-size: 45px;
    line-height: 1;
  }
}

</style>
