<template>
  <div class="wrapper g-0" v-bind:class="{'wrapper-visible': open }" v-if="cocktail">
    <div class="left-column d-flex justify-content-center align-items-center" v-bind:class="{'left-open': open}">
      <div class="cocktails-gallery">
        <CocktailsCarousel v-if="cocktail.thumbnail" :imageUrls="cocktail.thumbnail" />
      </div>
    </div>
    <div class="right-column" v-bind:class="{'right-open': open}">
      <div>
        <div class="link-no-decoration" @click="hide()">
          <MainButton :button-text="'Back'" :button-icon="'arrow-left-black'" :theme="'dark'"/>
        </div>
        <div class="cocktail-title"> {{ cocktail.title }} </div>
        <div class="cocktail-subtitle"> {{ cocktail.subtitle }} </div>
        <div class="cocktail-description"> {{ cocktail.description }} </div>
        <div class="scrollable-content">
          <div class="cocktails-subtitle">Ingredients</div>
          <div class="cocktail-ingredients" v-for="(ingredient, index) in cocktail.ingredients" :key="index">
            {{ ingredient }}
          </div>
          <div class="cocktails-subtitle">Instructions</div>
          <div class="cocktail-description"> {{ cocktail.instructions }} </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import CocktailsCarousel from "@/components/Components/CocktailsCarousel";
import MainButton from "@/components/Common/Buttons/MainButton";
import {defineComponent} from 'vue';
import store from "@/store";

export default defineComponent({
  name: "OneCocktailComponent",
  components: {
    CocktailsCarousel,
    MainButton
  },
  props: {
    cocktail: Object
  },
  data() {
    return {
      open: false
    }
  },
  methods: {
    show() {
      this.open = true;
    },
    hide() {
      this.open = false;
      store.dispatch("utils/toggleHeader", true);
    }
  }
});
</script>

<style lang="scss" scoped>
.left-column {
  position: fixed;
  left: 0;
  top: 0;
  width: 50%;
  height: 70vh;
  background: #FFFFFF;
  padding: 40px;
  transform: translateX(-100%);
  transition: transform 0.7s;
}
.right-column {
  position: fixed;
  right: 0;
  top: 0;
  width: 50%;
  background: #F2F2E4;
  padding: 40px 40px 50px 40px;
  color: #1b3b3d;
  transform: translateX(100%);
  transition: transform 0.7s;

  .cocktail-title {
    font-family: $font-bold;
    font-size: 40px;
    padding-top: 20px;
  }
  .cocktail-description {
    font-size: 14px;
    padding: 20px 0;
  }
  .cocktails-subtitle {
    font-family: $font-bold;
    font-size: 18px;
    padding-top: 20px;
  }
  .cocktail-ingredients {
    border-bottom: 1px solid #CFCFBA;
    padding: 15px 0;
    font-size: 17px;
  }

}
.left-open,
.right-open, {
  transition: transform 0.7s;
  transform: translateX(0);
}
.scrollable-content::-webkit-scrollbar {
  width: 4px; /* Set the width of the scrollbar */

}
.scrollable-content::-webkit-scrollbar-track {
  background: transparent;
}
.scrollable-content::-webkit-scrollbar-thumb {
  background: #1b3b3d;
}
@media screen and (min-width: 768px) {
  .left-column, .right-column {
    height: 100vh;
  }
  .right-column {
    height: 100vh;
    overflow: hidden;
    .scrollable-content {
      height: 65vh;
      overflow-y: auto;
      padding-right: 10px;
    }
  }

}

@media screen and (max-width: 768px) {
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
  }
  .wrapper-visible {
    display: block;
  }
  .left-column, .right-column {
    position: static;
    height: auto;
    width: 100%;
  }
}

</style>