<template>
  <div class="container">
    <div class="button" v-bind:class="{
    'theme-dark': theme === 'dark',
    'theme-light': theme === 'light',
    }">
      <span class="label-up-roll" v-if="!open">Menu</span>
      <span class="label-up-roll" v-if="!open">Menu</span>
      <div class="label-up2-roll" v-if="open">
        <span class=" x-icon"></span>
        <span class=" x-icon x-icon2"></span>
      </div>
      <div class="label-up2-roll" v-if="open">
        <span class="x-icon"></span>
        <span class="x-icon x-icon2"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesktopMenuButton",
  props: {
    theme: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/fonts.scss";

.container {
  width: 100%;
  text-align: center;
  margin: 0px 0;
  font-family: sans-serif;
  cursor: pointer;
}

.button {
  display: inline-block;
  height: 65px;
  width: 65px;
  text-align: center;
  overflow: hidden;
  position: relative;
  border: 2px solid white;
  color: white;
  border-radius: 50%;
  transition: 0.3s;
  &:hover {
    background: transparent;
    .label-up-roll {
      top: -65%;
    }
    .label-up2-roll {
      top: -100%;
    }
  }
}

/* BUTTON UP */
.label-up-roll {
  display: block;
  margin: 0px 0px;
  height: 100%;
  position: relative;
  top: 35%;
  transition: 0.5s;
  font-size: 13px;
}

.label-up2-roll {
  display: block;
  margin: 0px 0px;
  height: 100%;
  position: relative;
  top: 0%;
  left: 3%;
  transition: 0.5s;
}

.x-icon {
  display: block;
  position: absolute;
  height: 2px;
  width: 70%;
  left: 11%;
  top: 47%;
  background: #1B3B3D;
  border-radius: 9px;
  opacity: 1;
  transform: rotate(45deg);
  transition: .25s ease-in-out;
}

.x-icon2 {
  transform: rotate(-45deg);
}

.theme-dark {
  color: #1B3B3D;
  fill: #1B3B3D;
  border: 2px solid #1B3B3D;
}

.theme-light {
  color: #ffffff;
}

</style>