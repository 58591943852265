<template>
  <div class="welcome-container" v-if="show">
    <div class="overlay" ref="overlay"></div>
    <div class="welcome-text-wrapper" ref="titleWrapper">
      <div class="welcome-title">We let our drinks</div>
      <div class="welcome-subtitle">Do the talk</div>
      <div class="d-flex justify-content-center journey-button">
        <JourneyButton @click="$emit('startJourney')"/>
      </div>
    </div>
    <div class="clouds d-flex">
      <div class="clouds-left" ref="leftCloud"></div>
      <div class="clouds-right" ref="rightCloud"></div>
    </div>
  </div>
</template>

<script>
import JourneyButton from "@/components/Common/Buttons/JourneyButton";
import {defineComponent} from "vue";
import gsap from "gsap"

export default defineComponent({
  name: 'WelcomeComponent',
  components: {
    JourneyButton
  },
  data() {
      return {
        show: true
      }
  },
  methods: {
    startAnimation() {
      let vm = this;
      const leftCloud = this.$refs.leftCloud;
      const rightCloud = this.$refs.rightCloud;
      const titleWrapper = this.$refs.titleWrapper;
      const overlay = this.$refs.overlay;
      gsap.to(leftCloud, {duration: 2.5, transform: 'translateX(-100%)', ease: "power2.in"});
      gsap.to(rightCloud, {duration: 2.5, transform: 'translateX(100%)', ease: "power2.in"});
      gsap.to(overlay, {duration: 2.5, opacity: 0, ease: "power2.in"});
      gsap.to(titleWrapper, {
        duration: 1.5,
        zoom: 0.01,
        opacity: 0,
        ease: "power2.in",
        delay: 1,
        onComplete: function() {
          vm.show = false;
        }
      });
    }
  }
});
</script>

<style lang="scss" scoped>

@import "~@/assets/scss/fonts.scss";

.welcome-container {
  //background-image: url("@/assets/images/welcome-map.png");
  //background-size: cover;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9998;
}

.overlay {
  background: rgba(150, 150, 150);
  opacity: 0.5;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.clouds-left {
  background-image: url("@/assets/images/cloud-left.png");
  background-size: cover;
  height: 100vh;
  width: 100vh;
  position: fixed;
  top: 0;
  left: -50%;
  z-index: 2;
}

.clouds-right {
  background-image: url("@/assets/images/cloud-right.png");
  background-size: contain;
  width: 100vh;
  height: 100vh;
  position: fixed;
  bottom: 0;
  right: -50%;
  z-index: 2;
}

.welcome-text-wrapper {
  position: absolute;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  width: 100%;
}

.welcome-title {
  font-size: 50px;
  text-transform: uppercase;
  font-family: CommissionerBlack;
  line-height: 50px;

}

.journey-button {
  padding-top: 25px;
}

.welcome-subtitle {
  font-size: 70px;
  font-family: Blackson;
  color: #C0FF6F;
  line-height: 55px;
}

@media only screen and (min-width: 768px) {
  .clouds-right {
    top: 0;
    right: -6%;
  }
  .clouds-left {
    top: 0;
    left: 0;
  }
  .welcome-title {
    font-size: 80px;
    line-height: 120px;
  }
  .welcome-subtitle {
    line-height: 60px;
    font-size: 120px;
  }

}
</style>