<template>
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_791_944)">
      <path d="M11.9997 6.04265C11.9768 7.73767 11.2779 9.14139 10.1746 10.4071C8.9421 11.8202 7.75538 13.2709 6.548 14.7057C6.16424 15.1611 5.81737 15.1632 5.4373 14.7122C4.11405 13.1386 2.78711 11.5679 1.46756 9.99143C-0.915468 7.14351 -0.327276 2.93958 2.72217 0.996635C6.32807 -1.30049 11.1989 0.793522 11.8772 4.93674C11.9369 5.30248 11.9598 5.67402 11.9997 6.04265ZM5.99154 8.13161C7.2897 8.13161 8.348 7.09075 8.34431 5.81641C8.33988 4.55798 7.29191 3.52868 6.00778 3.52218C4.70372 3.51567 3.63878 4.54714 3.6373 5.8193C3.63582 7.09581 4.6897 8.13161 5.99154 8.13233V8.13161Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_791_944">
        <rect width="12" height="15" fill="white" transform="translate(0 0.0483398)"/>
      </clipPath>
    </defs>
  </svg>


</template>

<script>
export default {
  name: "MapPinWhite"
}
</script>

<style scoped>

</style>
