<template>
  <div class="wrapper d-flex align-items-center justify-content-center">
    <div class="progress-text align-self-center">
      {{ progress }}%
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: "LoaderComponent",
  components: {},
  props: {
    progress: Number
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/fonts.scss";

.wrapper {
  background: #F2F2E4;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10001;

  .progress-text {
    text-transform: uppercase;
    font-family: CommissionerBold;
    color: #1B3B3D;
    text-align: center;
    font-size: 85px;
    line-height: 0;
  }


}

</style>
