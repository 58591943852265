<template>
  <div class="brands-wrapper">
    <div class="carousel-wrapper">
      <carousel v-bind="settings"
                :breakpoints="breakpoints"
                :wrap-around="true">
        <slide v-for="(brand, index) in brands" :key="index">
          <div class="brand-item" @click="$emit('onBrandClick', brand)">
            <div class="brand-name">{{ brand.name }}</div>
            <div class="brand-category">{{ brand.category }}</div>
          </div>
        </slide>

        <template #addons>
          <navigation>
            <template #next>
              <span>  &gt;  </span>
            </template>
            <template #prev>
              <span> &lt; </span>
            </template>
          </navigation>
        </template>
      </carousel>
    </div>
  </div>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css'
import {Carousel, Navigation, Slide} from 'vue3-carousel'
import {reactive} from "vue";

const brands = reactive([
  {
    name: 'Tatratea',
    category: 'Herbal liqueur',
    checkpoint: 1
  },
  {
    name: 'On Lemon',
    category: 'Craft Lemonade',
    checkpoint: 2
  },
  {
    name: 'Gin 1689',
    category: 'Gin',
    checkpoint: 3
  },
  {
    name: 'Berkshire',
    category: 'Gin',
    checkpoint: 4
  },
  {
    name: 'Mates',
    category: 'Rum',
    checkpoint: 5
  },
  {
    name: 'LALA',
    category: 'Tequila',
    checkpoint: 6
  },
  {
    name: 'San Cosme',
    category: 'Mezcal',
    checkpoint: 7
  },
  {
    name: 'Padre Azul',
    category: 'Tequila',
    checkpoint: 8
  },
  {
    name: 'Xiaman',
    category: 'Mezcal',
    checkpoint: 9
  },
  {
    name: 'Gelston\'s',
    category: 'Whiskey',
    checkpoint: 10
  },
  {
    name: 'Czechoslovakia',
    category: 'Vodka',
    checkpoint: 11
  },
  {
    name: 'Bandoeng22\'',
    category: 'Liqueur',
    checkpoint: 12
  },
]);

const settings = reactive({
  itemsToShow: 12,
  snapAlign: 'center',
  wrapAround: true,
});

const breakpoints = reactive({
  // 700px and up
  0: {itemsToShow: 1, snapAlign: 'center',},

  400: {itemsToShow: 3, snapAlign: 'center',},
  // 700px and up
  700: {itemsToShow: 6, snapAlign: 'center',},
  // 830 and up
  830: {itemsToShow: 7, snapAlign: 'center',},
  // 930 and up
  930: {itemsToShow: 8, snapAlign: 'center',},
  // 1024 and up
  1024: {itemsToShow: 9, snapAlign: 'center',},
  // 1200 and up
  1100: {itemsToShow: 11, snapAlign: 'center',},
  // 1200 and up
  1200: {itemsToShow: 12, snapAlign: 'center',},
});


</script>

<style lang="scss" scoped>
.brands-wrapper {
  position: relative;
}

.brand-item {
  line-height: 1.2;
  cursor: pointer;

  &:hover {
    background: #c2ecef;
  }

  .brand-name {
    width: 100px;
    text-align: center;
    font-family: $font-semi-bold;
    font-size: 11px;
    padding: 20px 10px 0 10px;
  }

  .brand-category {
    width: 100px;
    text-align: center;
    font-family: $font-thin;
    font-size: 9px;
    padding: 0 10px 20px 10px;
  }
}

</style>
<style lang="scss">
.brands-wrapper {

  .carousel-wrapper {
    padding: 0 50px;
    position: relative;
    background: #DFFCFE;
    border-radius: 50px;
  }

  .carousel {
    position: static;
  }

  .carousel__icon {
    fill: #fff;
  }

  .carousel__prev,
  .carousel__next {
    position: absolute;
    background: #387377 !important;
    border-radius: 50px;
    font-size: 12px;
    color: #fff;
  }

  .carousel__next {
    right: 0;
  }

  .carousel__prev {
    left: 0;
  }
}
</style>