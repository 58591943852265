<template>
  <div class="process-wrapper container">
    <div class="d-flex" v-for="step in productionSteps" :key="step.id">
      <div class="div-4 col-md-2 col-4 d-flex justify-content-center" v-if="step.imageUrl !== ''">
        <img :src="step.imageUrl" loading="lazy" class="img"/>
      </div>
      <div class="col-md-10 col-8 d-flex">
        <div class="progress-container position-relative d-flex">
          <div class="bullet-point">&#8226;</div>
          <div
              :class="['progress-bar', step.imageUrl !== '' ? 'progress-bar-with-image' : 'progress-bar-no-image']"></div>
        </div>
        <div class="div-2">
          <div class="step-title">{{ step.stepTitle }}</div>
          <div class="step-description">{{ step.stepDescription }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productionSteps: {
      type: Array,
    }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.process-wrapper {
  display: flex;
  padding-top: 14px;
  flex-direction: column;
  font-family: $font-regular;
  position: relative;
  z-index: 1;


}

.progress-container {
  display: flex;
  align-items: center;
  position: relative;
}

.progress-bar {
  height: 100%;
  width: 2px;
  background: #C0FF6F;
  z-index: 1;
  transform: translateX(-120%);
}

.bullet-point {
  z-index: 22;
  display: flex;
  align-self: start;
  padding: 0 10px;
  position: relative;
  font-size: 20px;
  margin-top: 50px;
  left: 45%;
}

.div-2 {
  align-self: start;
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: start;
  color: #1b3b3d;
  padding: 0 20px;
  margin-top: 50px;

  .step-title {
    font-size: 12px;
  }

  .step-description {
    font-size: 16px;
  }
}

.img {
  object-fit: auto;
  object-position: center;
  width: 120px;
  height: 120px;
  margin-top: 50px;
}

@media (max-width: 991px) {
  .bullet-point {
    padding: 0 20px;
    z-index: 22;

  }
  .progress-bar {
    top: 0;

    z-index: 1;
  }
  .div-2 {
    padding: 0 20px;

    .step-title {
      font-size: 12px;
    }

    .step-description {
      font-size: 10px;
    }
  }
}

@media (min-width: 1200px) {
  .progress-bar {
    transform: translateX(-56%);
  }
}

@media (min-width: 1600px) {
  .progress-bar {
    transform: translateX(-30%);
  }
}

</style>
