<template>
  <svg v-if="color === 'light'" width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.25669 5.40127L0.31111 0.299368L6.20228 0.299369L3.25669 5.40127Z" fill="white"/>
  </svg>
  <svg v-if="color === 'dark'" width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.25669 5.40127L0.31111 0.299368L6.20228 0.299369L3.25669 5.40127Z" fill="black"/>
  </svg>
</template>
<script>
export default {
  name: 'ArrowDownIcon',
  props: {
    color: {
      type: String,
      default: 'white'
    }
  },
};

</script>
