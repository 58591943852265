<template>
  <svg class="arrow-right" v-if=" color === 'white'" width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.646447 3.7701C0.451184 3.96537 0.451184 4.28195 0.646447 4.47721L3.82843 7.65919C4.02369 7.85445 4.34027 7.85445 4.53553 7.65919C4.7308 7.46393 4.7308 7.14735 4.53553 6.95208L1.70711 4.12366L4.53553 1.29523C4.7308 1.09997 4.7308 0.783385 4.53553 0.588123C4.34027 0.392861 4.02369 0.392861 3.82843 0.588123L0.646447 3.7701ZM17.9355 3.62366L1 3.62366V4.62366L17.9355 4.62366V3.62366Z" fill="white"/>
  </svg>
  <svg class="arrow-right" v-if=" color === 'black'" width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.646447 3.7701C0.451184 3.96537 0.451184 4.28195 0.646447 4.47721L3.82843 7.65919C4.02369 7.85445 4.34027 7.85445 4.53553 7.65919C4.7308 7.46393 4.7308 7.14735 4.53553 6.95208L1.70711 4.12366L4.53553 1.29523C4.7308 1.09997 4.7308 0.783385 4.53553 0.588123C4.34027 0.392861 4.02369 0.392861 3.82843 0.588123L0.646447 3.7701ZM17.9355 3.62366L1 3.62366V4.62366L17.9355 4.62366V3.62366Z" fill="black"/>
  </svg>
</template>

<script>

export default {
  name: 'ArrowLeftIcon',
  props: {
    color: {
      type: String,
      default: 'white'
    }
  },
};
</script>

<style lang="scss">

.arrow-right {
  transform: rotate(180deg);
}

</style>