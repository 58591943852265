<template>
  <div class="header-wrapper"  v-bind:class="{'header-hidden': !showHeader, theme: true}" ref="header" v-if="!studioMode">
    <div class="d-flex align-items-center">
      <div class="drinkplus-logo d-flex">
        <div>
          <router-link :to="{ name: 'AllProductsView' }">
            <DrinkPlusLogo :theme="theme"/>
          </router-link>
        </div>
      </div>
      <div class="mobile-menu-button ms-auto d-md-none">
        <div>
          <HamburgerMenuButton :theme="theme" :show-close="isMenuActive" @click="toggleMenu"/>
        </div>
      </div>
      <div class="align-items-center ms-auto  d-none d-md-flex">
        <div class="" v-if="false">
          <MainButton :button-icon="'language'" :button-text="'Bulgarian'" :theme="theme"/>
        </div>
        <div class="desktop-menu-button d-none d-md-block mt-2">
          <DesktopMenuButton :open="isMenuActive" :theme="theme" @click="toggleMenu"/>
        </div>
      </div>
    </div>
  </div>
  <div class="menu-wrapper" :class="{ 'menu-active': isMenuActive }">
    <div class="menu-content">
      <div class="align-items-center">
        <div @click="goToPage('home')">
          <div class="button">
            <span class="label-up menu-item"
                  v-bind:class="{  'level-up-hide': !showSecondButtons && selectedButton !== 'home'}">home</span>
            <span class="label-up menu-item"
                  v-bind:class="{'btn-selected': selectedButton === 'home'}"
                  v-if="showSecondButtons || selectedButton === 'home'">home</span>
          </div>
        </div>
        <div @click="goToPage('AllProductsView' )">
          <div class="button">
            <span class="label-up menu-item"
                  v-bind:class="{'level-up-hide': !showSecondButtons && selectedButton !== 'AllProductsView'}">all products</span>
            <span class="label-up menu-item"
                  v-bind:class="{'btn-selected': selectedButton === 'AllProductsView'}"
                  v-if="showSecondButtons || selectedButton === 'AllProductsView'">all products</span>
          </div>
        </div>
        <div @click="goToPage('OurStoryView' )">
          <div class="button">
            <span class="label-up menu-item"
                  v-bind:class="{'level-up-hide': !showSecondButtons && selectedButton !== 'OurStoryView'}">our story</span>
            <span class="label-up menu-item"
                  v-bind:class="{'btn-selected': selectedButton === 'OurStoryView'}"
                  v-if="showSecondButtons || selectedButton === 'OurStoryView'">our story</span>
          </div>
        </div>
        <div @click="goToPage('AllCocktailsView' )">
          <div class="button">
            <span class="label-up menu-item"
                  v-bind:class="{'level-up-hide': !showSecondButtons && selectedButton !== 'AllCocktailsView'}">cocktails</span>
            <span class="label-up menu-item"
                  v-bind:class="{'btn-selected': selectedButton === 'AllCocktailsView'}"
                  v-if="showSecondButtons || selectedButton === 'AllCocktailsView'">cocktails</span>
          </div>
        </div>
        <div @click="goToPage('ContactsView' )">
          <div class="button">
            <span class="label-up menu-item"
                  v-bind:class="{'level-up-hide': !showSecondButtons && selectedButton !== 'ContactsView'}">contact</span>
            <span class="label-up menu-item"
                  v-bind:class="{'btn-selected': selectedButton === 'ContactsView'}"
                  v-if="showSecondButtons || selectedButton === 'ContactsView'">contact</span>
          </div>
        </div>
        <div class="d-md-none d-flex justify-content-center language-button" v-if="false">
          <div>
            <MainButton :button-icon="'language'" :button-text="'Bulgarian'" :theme="theme"/>
          </div>
        </div>
        <div class="footer-wrapper">
          <div class="d-flex justify-content-center socials-wrapper">
            <a href="https://www.facebook.com/drinkplusbulgaria" class="footer-item">
              <MainButton :button-text="'Facebook'" :button-icon="'facebook'" :theme="'dark'"/>
            </a>
            <a href="https://www.instagram.com/drinkplus_bg" class="footer-item">
              <MainButton :button-text="'Instagram'" :button-icon="'instagram'" :theme="'dark'"/>
            </a>
          </div>
          <div class="d-flex justify-content-center info-nav">
            <div class="" v-if="false">
              <MainButton :button-text="'Privacy policy'" :theme="'dark'" :size="'small'"/>
            </div>
            <div class="" v-if="false">
              <MainButton :button-text="'GDPR'" :theme="'dark'" :size="'small'"/>
            </div>
            <div class="" @click="$router.push({name: 'TermsView'})">
              <MainButton :button-text="'Terms and conditions'" :theme="'dark'" :size="'small'"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DrinkPlusLogo from "@/components/Common/Icons/DrinkPlusLogo"
import HamburgerMenuButton from "@/components/Common/Buttons/HamburgerMenuButton";
import DesktopMenuButton from "@/components/Common/Buttons/DesktopMenuButton";
import MainButton from "@/components/Common/Buttons/MainButton";
import {defineComponent} from 'vue';
import gsap from "gsap";

export default defineComponent({
  name: "HeaderComponent",
  components: {
    DrinkPlusLogo,
    HamburgerMenuButton,
    DesktopMenuButton,
    MainButton,
  },
  computed: {
    theme() {
      if (this.isMenuActive) {
        return 'dark';
      }
      const lightThemeRoutes = ['home', 'AllProductsView'];
      return lightThemeRoutes.includes(this.$route.name) ? 'light' : 'dark'
    },
    showHeader() {
      return this.$store.state.utils.showHeader;
    }
  },
  data() {
    return {
      isMenuActive: false,
      studioMode: false,
      showSecondButtons: true,
      selectedButton: null
    }
  },
  mounted() {
    if (this.$route.query.studio) {
      this.studioMode = true;
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    hide() {
      const header = this.$refs.header;
      gsap.to(header, {
        duration: 1,
        transform: 'translateY(-100%)',
      });
    },
    show() {
      const header = this.$refs.header;
      gsap.to(header, {
        duration: 1,
        transform: 'translateY(0)',
      });
    },
    async closeModal() {
      await this.$store.dispatch("products/closeProductModal");
    },
    goToPage(routeName) {
      this.$router.push({name: routeName});
      this.selectedButton = routeName
      this.showSecondButtons = false

      let vm = this;
      setTimeout(() => {
        vm.toggleMenu();
        setTimeout(() => {
          vm.showSecondButtons = true;
        }, 600);
      }, 600);
    }
  }
});
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/fonts.scss";
.header-hidden {
  transform: translateY(-100%);
}

.header-wrapper {
  width: 100%;
  position: fixed;
  padding: 15px;
  z-index: 10000;
  transition: transform 1s;
}

.mobile-menu-button {
  transform: scale(80%);
}


.drinkplus-logo {
  cursor: pointer;
}

.menu-wrapper {
  background: #F2F2E4;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 9999;
  transform: translateX(-100%);
  transition: 0.5s ease-in-out;

  .menu-item {
    text-transform: uppercase;
    font-family: CommissionerBlack;
    color: #1B3B3D;
    text-align: center;
    font-size: 24px;
    line-height: 0;
  }

  .menu-content {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .footer-wrapper {
    margin-top: 70px;

    .socials-wrapper {
      padding-bottom: 20px;
    }

    .info-nav {
      width: 100%;
      text-align: center;
    }
  }

}

.menu-active {
  transform: translateX(0);
  transition: 0.5s ease-in-out;
}

.button {
  cursor: pointer;
  display: block;
  height: 60px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  position: relative;
  color: #1B3B3D;
  transition: 0.5s;

  .label-up {
    display: block;
    margin: 0 0;
    height: 100%;
    position: relative;
    top: 20%;
    transition: 0.5s;
    color: #1B3B3D;
  }

  &:hover {
    background: transparent;

    .label-up {
      top: -80%;
      color: #C0FF6F;
    }
  }

  .level-up-hide {
    top: -80%;
  }
  .btn-selected {
    color: #1B3B3D!important;
  }
}


@media only screen and (min-width: 768px) {

  .header-wrapper {
    padding: 15px 50px 15px 50px;
  }

  .button {
    height: 80px;
  }

  .menu-wrapper {
    .menu-item {
      font-size: 60px;
      line-height: 60px;
    }
  }

  .drinkplus-logo {
    transform: translate(0) scale(80%);
  }
}

</style>
