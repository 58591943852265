<template>
  <HeaderComponent/>
  <router-view/>
  <div class="product-modal" v-if="modal.open">
    <OneProductModal :title="modal.modalTitle"
                     :description="modal.modalDescription"
                     :imageUrl="modal.modalImageUrl"
                     @close-modal="closeModal"/>
  </div>
  <AgeGateComponent v-if="showAgeGate" @pasAgeGate="pasAgeGate" @leaveAgeGate="leaveAgeGate"/>
</template>

<style lang="scss" scoped>
</style>
<script setup>
import HeaderComponent from "@/components/Components/HeaderComponent.vue";
import store from "@/store";
import {computed, onMounted, ref, watch} from "vue";
import OneProductModal from "@/components/Components/OneProductModal.vue";
import {useRoute} from "vue-router";
import AgeGateComponent from "@/components/Components/AgeGateComponent.vue";
import router from "@/router";

const showAgeGate = ref();

const modal = computed(() => store.state.products.productModal)
const route = useRoute()

watch(route, () => {
  closeModal();
}, {flush: 'pre', immediate: true, deep: true})

watch(route, () => {
  const ageGateCookie = getCookie('ageGatePassed');
  showAgeGate.value = !ageGateCookie || ageGateCookie !== 'true';
});

async function closeModal() {
  await store.dispatch("products/closeProductModal");
}

onMounted(() => {
  const ageGateCookie = getCookie('ageGatePassed');
  showAgeGate.value = !ageGateCookie || ageGateCookie !== 'true';
});

function pasAgeGate() {
  document.cookie = "ageGatePassed=true; path=/; max-age=3600000";
  showAgeGate.value = false;
}
function leaveAgeGate() {
  router.push({name: 'TermsView'})
  setTimeout(()=>{
    showAgeGate.value = false;
  }, 50)
}

// Function to get a cookie by name
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
</script>
