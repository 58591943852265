<template>
  <div class="one-product-header-wrapper"
       v-bind:style="{'background-image': 'url(' + (isMobile ? mobilePosterUrl : desktopPosterUrl) + ')'}">
    <div class="one-product-header-nav d-flex">
      <div class="me-auto">
        <MainButton
            :button-text="'Back'"
            :size="'small'"
            :button-icon="'arrow-left'"
            @click="goBackToBrandsList"
        />
      </div>
      <div class="d-none d-md-flex">
        <a :href="siteUrl" target="_blank" v-if="siteUrl">
          <MainButton
              :button-text="'Official website'"
              :size="'small'"
              :button-icon="'arrow-up'"
          />
        </a>
        <a :href="shopUrl" target="_blank" v-if="shopUrl">
          <MainButton
              :button-text="'Online store'"
              :size="'small'"
              :button-icon="'store'"
          />
        </a>
      </div>
    </div>
    <div
        class="one-product-content-wrapper d-flex justify-content-center align-items-center"
    >
      <div class="">
        <slot class="brand-logo"></slot>
        <div class="brand-title">{{ title }}</div>
        <div class="brand-subtitle">{{ subtitle }}</div>
        <div class="play-video-button">
          <BorderOutlineButton
              :button-text="'Play full video'"
              @click="playVideo"
          />
        </div>
        <div class="d-flex justify-content-center d-md-none mobile-nav">
          <div v-if="siteUrl">
            <MainButton
                :button-text="'Official website'"
                :size="'small'"
                :button-icon="'arrow-up'"
            />
          </div>
          <div v-if="shopUrl">
            <MainButton
                :button-text="'Online store'"
                :size="'small'"
                :button-icon="'store'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/Common/Buttons/MainButton";
import BorderOutlineButton from "@/components/Common/Buttons/BorderOutlineButton";

export default {
  name: "OneProductHeaderComponent",
  components: {
    MainButton,
    BorderOutlineButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    websiteUrl: {
      type: String,
      default: "",
    },
    siteUrl: {
      type: String,
      default: "",
    },
    shopUrl: {
      type: String,
      default: "",
    },
    desktopVideoUrl: {
      type: String,
      default: "",
    },
    mobileVideoUrl: {
      type: String,
      default: "",
    },
    desktopPosterUrl: {
      type: String,
      default: "",
    },
    mobilePosterUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      videoPlaying: false,
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
  },
  methods: {
    goBackToBrandsList() {
      if (this.isMobile) {
        this.$router.push({name: 'home'})
      } else {
        this.$emit('goBackToMainScreen')
      }
    },
    playVideo() {

      this.$emit('playFullVideo')
      // const video = this.$refs.videoPlayer;
      // if (video) {
      //   if (this.videoPlaying) {
      //     video.pause();
      //   } else {
      //     video.play();
      //   }
      //   this.videoPlaying = !this.videoPlaying;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.one-product-header-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 500px;
  background-size: cover;
  background-position: center;

  .header-video-desktop {
    width: 100%;
    height: 100%;
  }

  .header-video-mobile {
    width: 100%;
    height: 100%;
  }

  .one-product-header-nav {
    position: absolute;
    padding: 30px;
    top: 0;
    width: 100%;
    z-index: 1111;
  }

  .one-product-content-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;

    .brand-title {
      margin-top: 80px;
      font-family: $font-black;
      font-size: 35px;
      line-height: 0px;
      text-transform: uppercase;
      color: #ffffff;
      text-shadow: 2px 2px 50px rgba(0, 0, 0, 1.5);
    }

    .brand-subtitle {
      font-family: $font-brush-en;
      font-size: 40px;
      color: #c0ff6f;
      line-height: 60px;
      margin-bottom: 10px;
    }

    .mobile-nav {
      padding-top: 80px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .one-product-header-wrapper {
    background: none;
    min-height: 400px;

    .one-product-content-wrapper {
      .brand-title {
        font-size: 66px;
      }

      .brand-subtitle {
        font-size: 80px;
        line-height: 120px;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .one-product-header-wrapper {
    min-height: 500px;

    .one-product-content-wrapper {
      .brand-title {
        font-size: 86px;
      }

      .brand-subtitle {
        font-size: 80px;
        line-height: 120px;
      }
    }
  }
}
</style>
