import {Brands} from "@/enum/brands";

export const ProductsMapConfig = [
    {buttonIcon: Brands.TATRA_TEA, buttonText: 'Tatratea', top: '14%', left: '41%'},
    {buttonIcon: Brands.ON_LEMON, buttonText: 'OnLemon', top: '33%', left: '41%'},
    {buttonIcon: Brands.GIN_1689, buttonText: 'Gin 1689', top: '49%', left: '44%'},
    {buttonIcon: Brands.BERKSHIRE, buttonText: 'Berkshire Gin', top: '32%', left: '52.5%'},
    {buttonIcon: Brands.MATES, buttonText: 'Mates Rum', top: '11%', left: '53%'},
    {buttonIcon: Brands.LALA, buttonText: 'Lala Tequila', top: '16%', left: '66%'},
    {buttonIcon: Brands.SAN_COSME, buttonText: 'San Cosme', top: '42%', left: '75%'},
    {buttonIcon: Brands.PADRE_AZUL, buttonText: 'Padre Azul', top: '52%', left: '63.5%'},
    {buttonIcon: Brands.XIAMAN, buttonText: 'Xiaman Mezcal', top: '60%', left: '77%'},
    {buttonIcon: Brands.GELSTONS, buttonText: 'Samuel Gelston`s', top: '68.3%', left: '62.5%'},
    {buttonIcon: Brands.CZECHOSLOVAKIA, buttonText: 'Vodka Czechoslovakia', top: '75%', left: '52%'},
    {buttonIcon: Brands.BANDOENG, buttonText: 'Bandoeng', top: '81.5%', left: '40%'},
];