<script >
export default {
  name: "InstagramIcon",
  props: {
    theme: {
      type: String,
      default: '',
    }
  }
}
</script>

<template>
  <svg v-if="theme === 'light'" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7_44)">
      <path d="M7.50512 0.505753C8.69277 0.505753 9.88042 0.50028 11.0735 0.505753C12.5841 0.516699 13.9086 1.50732 14.3355 2.95219C14.4285 3.26963 14.4887 3.61443 14.4887 3.94281C14.4997 6.31263 14.5051 8.67698 14.4942 11.0468C14.4832 12.9569 12.9617 14.4784 11.0462 14.4893C8.68182 14.5003 6.31748 14.5003 3.95313 14.4893C2.0321 14.4784 0.510596 12.9569 0.505123 11.0359C0.494177 8.67698 0.494177 6.31811 0.505123 3.95923C0.510596 2.21334 1.76392 0.79035 3.48792 0.538591C3.67401 0.511226 3.87103 0.505753 4.05712 0.505753C5.21193 0.50028 6.36126 0.50028 7.50512 0.505753C7.50512 0.50028 7.50512 0.50028 7.50512 0.505753ZM7.50512 1.90137C6.33937 1.90137 5.17361 1.8959 4.00786 1.90137C2.80379 1.90685 1.90622 2.80442 1.90622 4.00302C1.90074 6.33452 1.90074 8.66603 1.90622 10.9975C1.91169 12.1852 2.79832 13.0882 3.99144 13.0937C6.3339 13.1047 8.67088 13.0992 11.0133 13.0937C12.201 13.0882 13.0986 12.1961 13.0986 11.003C13.104 8.66603 13.104 6.32905 13.0986 3.99207C13.0986 3.65274 13.0329 3.32983 12.8742 3.02882C12.4746 2.27354 11.8288 1.90685 10.986 1.8959C9.82569 1.89043 8.6654 1.90137 7.50512 1.90137ZM10.9969 7.50028C10.9969 9.45962 9.45352 11.003 7.4887 10.9975C5.53483 10.9921 3.99691 9.45415 3.99691 7.49481C3.99691 5.53546 5.54031 3.99207 7.50512 3.99754C9.46447 4.00302 10.9969 5.54641 10.9969 7.50028ZM9.60129 7.48933C9.59582 6.30716 8.68182 5.39864 7.49965 5.39316C6.312 5.39316 5.39801 6.31263 5.39801 7.50028C5.40348 8.68245 6.31748 9.59645 7.49965 9.59645C8.6873 9.59645 9.60676 8.67698 9.60129 7.48933ZM11.0024 3.30247C10.6193 3.30247 10.2964 3.6199 10.2964 4.00849C10.2964 4.3916 10.6138 4.70356 10.9914 4.70356C11.3746 4.70904 11.6975 4.38613 11.6975 3.99754C11.6975 3.61443 11.3855 3.30247 11.0024 3.30247Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_7_44">
        <rect width="14" height="14" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
    </defs>
  </svg>
  <svg v-if="theme === 'dark'" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7_44)">
      <path d="M7.50512 0.505753C8.69277 0.505753 9.88042 0.50028 11.0735 0.505753C12.5841 0.516699 13.9086 1.50732 14.3355 2.95219C14.4285 3.26963 14.4887 3.61443 14.4887 3.94281C14.4997 6.31263 14.5051 8.67698 14.4942 11.0468C14.4832 12.9569 12.9617 14.4784 11.0462 14.4893C8.68182 14.5003 6.31748 14.5003 3.95313 14.4893C2.0321 14.4784 0.510596 12.9569 0.505123 11.0359C0.494177 8.67698 0.494177 6.31811 0.505123 3.95923C0.510596 2.21334 1.76392 0.79035 3.48792 0.538591C3.67401 0.511226 3.87103 0.505753 4.05712 0.505753C5.21193 0.50028 6.36126 0.50028 7.50512 0.505753C7.50512 0.50028 7.50512 0.50028 7.50512 0.505753ZM7.50512 1.90137C6.33937 1.90137 5.17361 1.8959 4.00786 1.90137C2.80379 1.90685 1.90622 2.80442 1.90622 4.00302C1.90074 6.33452 1.90074 8.66603 1.90622 10.9975C1.91169 12.1852 2.79832 13.0882 3.99144 13.0937C6.3339 13.1047 8.67088 13.0992 11.0133 13.0937C12.201 13.0882 13.0986 12.1961 13.0986 11.003C13.104 8.66603 13.104 6.32905 13.0986 3.99207C13.0986 3.65274 13.0329 3.32983 12.8742 3.02882C12.4746 2.27354 11.8288 1.90685 10.986 1.8959C9.82569 1.89043 8.6654 1.90137 7.50512 1.90137ZM10.9969 7.50028C10.9969 9.45962 9.45352 11.003 7.4887 10.9975C5.53483 10.9921 3.99691 9.45415 3.99691 7.49481C3.99691 5.53546 5.54031 3.99207 7.50512 3.99754C9.46447 4.00302 10.9969 5.54641 10.9969 7.50028ZM9.60129 7.48933C9.59582 6.30716 8.68182 5.39864 7.49965 5.39316C6.312 5.39316 5.39801 6.31263 5.39801 7.50028C5.40348 8.68245 6.31748 9.59645 7.49965 9.59645C8.6873 9.59645 9.60676 8.67698 9.60129 7.48933ZM11.0024 3.30247C10.6193 3.30247 10.2964 3.6199 10.2964 4.00849C10.2964 4.3916 10.6138 4.70356 10.9914 4.70356C11.3746 4.70904 11.6975 4.38613 11.6975 3.99754C11.6975 3.61443 11.3855 3.30247 11.0024 3.30247Z" fill="#1B3B3D"/>
    </g>
    <defs>
      <clipPath id="clip0_7_44">
        <rect width="14" height="14" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>
