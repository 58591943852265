import axios from "axios";

export default {
    namespaced: true,
    state: {
        categories: [],
    },
    mutations: {},
    actions: {
        async getCocktail({state}, payload) {
            if (!state) {
                return;
            }
            const response = await axios.get('/mock-api/cocktail/' + payload.id + '.json');
            return response.data;
        },
        async getCocktails({state},) {
            if (!state) {
                return;
            }
            const response = await axios.get('/mock-api/all-cocktails.json');
            return response.data;
        },
    }
};
