<template>
  <div class="carousel-wrapper product-carousel">
    <div class="carousel-title container">All products</div>
    <Carousel ref="slider" v-bind="settings" :breakpoints="breakpoints">
      <Slide v-for="(slide, index) in carouselSlides" :key="index">
        <div class="carousel__item" @click="openModal(slide)">
          <div class="item-container">
            <OneProductCarouselItem :imageUrl="slide.imageUrl" :title="slide.title"/>
          </div>
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {Carousel, Slide} from 'vue3-carousel'
import OneProductCarouselItem from "@/components/Components/OneProductCarouselItem";
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'ProductsCarousel',
  components: {
    Carousel,
    Slide,
    OneProductCarouselItem
  },
  props: {
    carouselSlides: {
      type: Array,
      required: true
    },

  },
  data() {
    return {
      modalVisible: false,
      modalTitle: '',
      modalDescription: '',
      modalImageUrl: '',
      settings: {
        itemsToShow: 1.8,
        snapAlign: 'center',
        wrapAround: false
      },
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 1.8,
          snapAlign: 'start',
        },
        // 1024 and up
        1024: {
          itemsToShow: 1.8,
          snapAlign: 'start',
        },
        1400: {
          itemsToShow: 2.2,
          snapAlign: 'start',
        },
        1900: {
          itemsToShow: 2.8,
          snapAlign: 'start',
        },
      },
    };
  },
  methods: {
    async openModal(slide) {
      await this.$store.dispatch("products/openProductModal", slide);
    },
    slideToNext() {
      this.$refs.slider.next();
      return this.$refs.slider.data.currentSlide.value < (this.$props.carouselSlides.length - 1)
    },
    slideToPrev() {
      this.$refs.slider.prev();
      return this.$refs.slider.data.currentSlide.value === 0;
    }
  }
})
</script>
<style lang="scss">
.carousel-wrapper {
  position: relative;
  background: #FFFFFF;
  padding-top: 20px;
}

.carousel-title {
  font-family: $font-black;
  font-size: 34px;
  text-transform: uppercase;
  margin-bottom: 30px;
}


.product-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  padding: 0;
  z-index: 9998;
}

.product-carousel {
  .carousel__viewport {
    margin: 0;
  }

  .item-container {
    margin: 0 5px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .product-modal {
    width: 100%;
    left: 0;
  }
}


</style>
