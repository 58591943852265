<template>
  <div class="container quote-wrapper">
    <div class="quote-title">
      <div class="title">{{ title1 }}</div>
      <div class="title">{{ title2 }}</div>
      <div class="title">{{ title3 }}</div>
    </div>
    <div class="body-text" v-if="body"> {{ body }}</div>

  </div>
</template>

<script>
export default {
  name: "OneProductQuoteComponent",
  components: {},
  props: {
    title1: {
      type: String,
    },
    title2: {
      type: String,
    },
    title3: {
      type: String,
    },
    imageUrl: {
      type: String,
    },
    body: {
      type: String,
    }
  },
  data() {
    return {};
  },
}
</script>

<style lang="scss" scoped>

.quote-title {

  font-family: $font-black;
  font-size: 120px;
  line-height: 110px;
  text-transform: uppercase;
  color: #C0FF6F;
  align-self: center;
}


.body-text {
  padding: 50px;
  font-family: $font-thin;
}


@media (max-width: 767px) {

  .quote-title {
    font-size: 60px;
    line-height: 50px;
  }
  .left-column {
    transform: translate(-15%, 10%);

    .intro-3d-object {
      background-size: contain;
      background-position: center;
    }
  }
  .body-text {
    margin-top: 30px;
    padding: 30px;
  }
;
}

@media screen and (min-width: 1900px) {
  .left-column {
    padding: 50px;

    p {
      font-size: 35px;
    }


  }
  .quote-title {
    font-size: 145px;
    line-height: 130px;
  }
}


</style>
