<template>
  <div class="viewport" ref="viewport">
    <div ref="draggable"
         class="draggable-content"
         v-bind:style="{ width: width + 'px', height: height + 'px' }">
      <ProductsMapComponent :products="products"/>
    </div>
  </div>
</template>

<script setup>
import {defineProps, onMounted, ref, nextTick} from 'vue';
import interact from 'interactjs';
import ProductsMapComponent from "@/components/Components/ProductsMapComponent.vue";

defineProps({
  products: Array,
  width: Number,
  height: Number,
})

const viewport = ref(null);
const draggable = ref(null);

onMounted(() => {
  // Ensure DOM is ready before calculating
  nextTick(() => {
    const viewportRect = viewport.value.getBoundingClientRect();
    const targetRect = draggable.value.getBoundingClientRect();

    // Calculate initial centering offsets
    const startX = (viewportRect.width - targetRect.width) / 2; // Horizontal center
    const startY = 0; // Align bottom

    // Apply the initial position (centered horizontally, aligned to bottom)
    draggable.value.style.transform = `translate(${startX}px, ${startY}px)`;
    draggable.value.setAttribute('data-x', startX);
    draggable.value.setAttribute('data-y', startY);

    // Enable dragging functionality
    interact(draggable.value).draggable({
      inertia: {
        resistance: 10,    // Lower value gives more friction
        minSpeed: 10,     // Minimum speed to keep inertia
        endSpeed: 8,      // Speed at which inertia will stop
      },
      listeners: {
        move(event) {
          const target = event.target;
          const viewportRect = viewport.value.getBoundingClientRect();
          const targetRect = target.getBoundingClientRect();

          // Calculate the new position
          let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
          let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

          // Ensure that the draggable always stays outside the viewport (map-like behavior)
          if (x > 0) x = 0;
          if (x < viewportRect.width - targetRect.width) {
            x = viewportRect.width - targetRect.width;
          }

          if (y > 0) y = 0;
          if (y < viewportRect.height - targetRect.height) {
            y = viewportRect.height - targetRect.height;
          }

          // Apply the calculated position
          target.style.transform = `translate(${x}px, ${y}px)`;

          // Store the position for later use
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        },
      },
    });
  });
});
</script>

<style lang="scss" scoped>
.viewport {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: #252A34;
}

.draggable-content {
  width: 1000px;
  height: 800px;
  position: absolute;
  cursor: grab;
}
</style>
