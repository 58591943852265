export const Brands = Object.freeze({
    TATRA_TEA: "tatra-tea",
    ON_LEMON: "on-lemon",
    GIN_1689: "gin-1689",
    BERKSHIRE: "berkshire",
    MATES: "mates",
    LALA: "lala-tequila",
    SAN_COSME: "san-cosme",
    PADRE_AZUL: "padre-azul",
    XIAMAN: "xiaman",
    GELSTONS: "gelstons",
    CZECHOSLOVAKIA: "czechoslovakia",
    BANDOENG: "bandoeng",
});