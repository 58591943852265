<script setup>

</script>

<template>
  <svg width="49" height="24" viewBox="0 0 49 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.71695 13.6962C8.71695 11.4985 8.71695 9.30932 8.71695 7.11164C7.99606 8.68989 7.28364 10.2766 6.57123 11.8549C6.07932 11.9737 5.58742 12.084 5.09552 12.2113C4.3831 10.9724 3.67069 9.72509 2.94979 8.48625C2.94979 10.6839 2.94979 12.8816 2.94979 15.0793C2.2289 15.2829 1.508 15.4951 0.787109 15.7157C0.787109 11.9482 0.787109 8.18078 0.787109 4.41333C1.49104 4.2012 2.20346 3.98907 2.91587 3.78543C3.8912 5.56733 4.85804 7.35771 5.82489 9.1481C6.78326 6.89102 7.75859 4.64244 8.7424 2.39385C9.46329 2.2496 10.1842 2.11384 10.9136 1.98656C10.9136 5.754 10.9136 9.52145 10.9136 13.2889C10.1757 13.4162 9.44633 13.5519 8.71695 13.6962Z" fill="white"/>
    <path d="M18.1904 1.07014C17.6052 1.12106 17.0285 1.17197 16.4433 1.23136C15.0524 5.16851 13.6784 9.11415 12.313 13.0683C13.0848 12.9495 13.8481 12.8392 14.6199 12.7458C14.8488 12.05 16.8843 5.89824 17.3677 4.48121C17.8342 5.81339 19.8018 11.6003 20.0223 12.2537C20.7941 12.2113 21.5744 12.1773 22.3461 12.1519C20.9722 8.44381 19.5813 4.75274 18.1904 1.07014Z" fill="white"/>
    <path d="M27.1632 2.80111C27.1632 5.9152 27.1632 9.02079 27.1632 12.1349C26.4168 12.1179 25.679 12.1009 24.9326 12.1009C24.9326 8.98685 24.9326 5.88125 24.9326 2.76717C23.9403 2.76717 22.9396 2.78414 21.9473 2.81808C21.9473 2.16472 21.9473 1.50287 21.9473 0.849509C24.6867 0.764657 27.4176 0.807083 30.1485 0.976788C30.1485 1.63015 30.1485 2.292 30.1485 2.94536C29.1477 2.88597 28.1554 2.83505 27.1632 2.80111Z" fill="white"/>
    <path d="M31.9551 12.4149C31.9551 8.64747 31.9551 4.88002 31.9551 1.11258C34.457 1.34168 36.9505 1.68109 39.4269 2.12232C39.4269 2.77569 39.4269 3.43753 39.4269 4.0909C37.6798 3.77694 35.9327 3.52239 34.1771 3.31026C34.1771 4.19272 34.1771 5.07518 34.1771 5.95765C35.6698 6.13584 37.1625 6.34797 38.6467 6.60253C38.6467 7.25589 38.6467 7.91774 38.6467 8.5711C37.1625 8.31655 35.6698 8.10441 34.1771 7.92622C34.1771 8.84263 34.1771 9.75904 34.1771 10.6754C35.9327 10.8876 37.6798 11.1506 39.4269 11.4561C39.4269 12.1094 39.4269 12.7713 39.4269 13.4247C36.9505 12.9834 34.457 12.6525 31.9551 12.4149Z" fill="white"/>
    <path d="M44.6599 14.655C43.0739 14.2562 41.6236 13.5349 40.5635 12.2452C41.0384 11.8634 41.5049 11.4815 41.9883 11.1082C42.6753 11.9482 43.676 12.4319 44.6938 12.6949C45.4571 12.8901 46.6868 12.9155 46.6699 11.8209C46.6614 11.4306 46.5596 11.0573 46.3222 10.7772C46.1016 10.5142 45.8557 10.3275 45.3383 10.1154C44.8888 9.93721 44.4393 9.75902 43.9898 9.58931C43.1757 9.27536 42.3784 8.83413 41.8187 8.15531C41.2674 7.49346 41.013 6.62797 41.013 5.76247C41.013 3.54783 42.9891 2.73324 44.9143 3.22539C46.3476 3.59025 47.5943 4.36241 48.5018 5.54186C48.0438 5.8643 47.5858 6.19522 47.1363 6.51766C46.5596 5.78793 45.7285 5.35518 44.8549 5.12608C44.0747 4.91395 43.1163 5.17699 43.1248 6.13582C43.1248 6.41584 43.2181 6.74676 43.4555 7.01829C43.676 7.27284 44.0407 7.53589 44.4902 7.71408C44.9312 7.89227 45.3723 8.06197 45.8048 8.24865C46.6105 8.57109 47.3823 9.03777 47.9251 9.71659C48.5188 10.4633 48.7732 11.4306 48.7817 12.3725C48.7817 14.7144 46.5172 15.1217 44.6599 14.655Z" fill="white"/>
    <path d="M19.8273 21.3244L21.0486 23.0045H20.3192L19.1912 21.3923H19.081V23.0045H18.4873V19.0673H19.1828C19.7001 19.0673 20.0818 19.1692 20.3107 19.3643C20.5652 19.5849 20.6924 19.8734 20.6924 20.2298C20.6924 20.5098 20.6161 20.7474 20.4549 20.9511C20.2938 21.1462 20.0818 21.2735 19.8273 21.3244ZM19.081 20.8747H19.2676C19.8273 20.8747 20.1072 20.6626 20.1072 20.2298C20.1072 19.831 19.8358 19.6274 19.2845 19.6274H19.0725V20.8747H19.081Z" fill="white"/>
    <path d="M22.6938 19.0673V21.4432C22.6938 21.7826 22.7531 22.0287 22.8634 22.1814C23.0245 22.4105 23.262 22.5208 23.5588 22.5208C23.8557 22.5208 24.0932 22.4105 24.2543 22.1814C24.3646 22.0287 24.4239 21.7826 24.4239 21.4432V19.0673H25.0176V21.6044C25.0176 22.0202 24.8904 22.3596 24.6275 22.6311C24.3391 22.9281 23.9829 23.0809 23.5588 23.0809C23.1348 23.0809 22.7786 22.9281 22.4902 22.6311C22.2358 22.3596 22.1001 22.0202 22.1001 21.6044V19.0673H22.6938Z" fill="white"/>
    <path d="M26.3408 23.0045L27.172 18.7704L28.5205 21.8335L29.9199 18.7704L30.6662 23.0045H30.0555L29.6739 20.6286L28.512 23.1827L27.384 20.6201L26.9599 22.996H26.3408V23.0045Z" fill="white"/>
  </svg>


</template>

<style scoped>


</style>