<template>
  <svg width="50" height="15" viewBox="0 0 50 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.1287 9.61035H31.3765L30.417 14.0264H31.3631L31.5125 13.247H31.9676L32.115 14.0264H33.0767L32.1287 9.61035Z" fill="#CED0D0"/>
    <path d="M28.9356 11.7548L29.804 9.61055H28.8095L28.4085 10.6418V9.61055H27.4546V14.0268H28.4085V12.945L28.8076 14.0268H29.8702L28.9356 11.7548Z" fill="#CED0D0"/>
    <path d="M26.2046 10.9985C26.1639 10.7428 26.1116 10.5374 26.0398 10.3683C25.9663 10.1921 25.8749 10.0488 25.7683 9.94588C25.6637 9.84337 25.5475 9.76382 25.4233 9.71344C25.3044 9.66518 25.179 9.63502 25.0512 9.62397C24.9408 9.61586 24.8302 9.61132 24.7195 9.61035H23.9189V14.027H24.491C24.6555 14.027 24.811 14.0208 24.9524 14.0115C25.0995 14.001 25.2447 13.9724 25.3847 13.9261C25.528 13.879 25.6595 13.8016 25.7703 13.6993C25.8809 13.5966 25.9739 13.4608 26.0496 13.2939C26.1194 13.1372 26.1735 12.9393 26.2104 12.7067C26.2454 12.4799 26.2626 12.2047 26.2626 11.8884C26.2626 11.5416 26.2435 11.243 26.2046 10.9985ZM25.2879 11.8882C25.2879 12.0551 25.2856 12.2274 25.2801 12.4039C25.276 12.5612 25.2527 12.7026 25.2101 12.8267C25.1765 12.9278 25.1138 13.0168 25.03 13.0825C24.9891 13.1156 24.9329 13.1389 24.8613 13.1506V10.4886C24.9602 10.5104 25.0477 10.5676 25.1074 10.6494C25.1541 10.7136 25.1887 10.791 25.212 10.8783C25.2393 10.9808 25.2587 11.0896 25.2702 11.202C25.2819 11.3183 25.2877 11.4366 25.2877 11.5529V11.888L25.2879 11.8882Z" fill="#CED0D0"/>
    <path d="M22.7147 11.4117C22.7116 11.2534 22.6999 11.0954 22.6797 10.9384C22.6593 10.777 22.6256 10.6175 22.5791 10.4615C22.5335 10.3043 22.4634 10.1553 22.3716 10.0198C22.2789 9.88279 22.1563 9.76871 22.0129 9.68621C21.8657 9.60102 21.6836 9.55823 21.4741 9.55823C21.2648 9.55823 21.0845 9.60102 20.9352 9.68621C20.7924 9.76862 20.6699 9.88186 20.5765 10.0177C20.4852 10.1542 20.4152 10.3039 20.3689 10.4615C20.3225 10.6175 20.2888 10.777 20.2684 10.9384C20.2489 11.0956 20.2373 11.2545 20.2334 11.4097C20.2297 11.5607 20.2275 11.6964 20.2275 11.8186C20.2275 11.9407 20.2297 12.0765 20.2334 12.2276C20.2373 12.3846 20.2489 12.5437 20.2684 12.7009C20.2898 12.8615 20.3227 13.0224 20.3689 13.1755C20.4176 13.3404 20.4876 13.4896 20.5765 13.6195C20.6708 13.7565 20.7948 13.8705 20.9392 13.9529C21.0882 14.0364 21.2685 14.0809 21.4741 14.0809C21.6795 14.0809 21.86 14.0362 22.009 13.9529C22.1534 13.8705 22.2773 13.7565 22.3716 13.6195C22.4631 13.4831 22.5331 13.3334 22.5791 13.1757C22.6256 13.0224 22.6585 12.8615 22.6799 12.7009C22.6993 12.54 22.7129 12.3809 22.7149 12.2276C22.7224 11.9557 22.7222 11.6836 22.7147 11.4117ZM21.7299 12.4101C21.7202 12.5863 21.7011 12.7394 21.6698 12.8672C21.6426 12.9798 21.6058 13.0671 21.5613 13.127C21.5381 13.1582 21.5146 13.1679 21.4743 13.1679C21.4335 13.1679 21.4101 13.1582 21.3868 13.127C21.3421 13.0671 21.3055 12.9798 21.2785 12.8674C21.2493 12.7394 21.2279 12.5863 21.2182 12.4101C21.2065 12.2276 21.2026 12.0279 21.2026 11.8186C21.2026 11.6093 21.2065 11.4117 21.2182 11.2273C21.2279 11.0509 21.2493 10.8978 21.2785 10.7698C21.3055 10.6574 21.3423 10.5704 21.3868 10.5101C21.4101 10.479 21.4335 10.4693 21.4743 10.4693C21.5148 10.4693 21.5381 10.479 21.5613 10.5101C21.606 10.5704 21.6428 10.6593 21.6698 10.7698C21.7011 10.8978 21.7204 11.0509 21.7299 11.2273C21.7416 11.4154 21.7455 11.6132 21.7455 11.8186C21.7455 12.0242 21.7416 12.2237 21.7299 12.4099V12.4101Z" fill="#CED0D0"/>
    <path d="M18.4385 9.61035L18.1652 11.1985L18.1206 11.4716L17.8396 9.61035H16.8799L17.7369 14.0268H18.5007L19.367 9.61035H18.4385Z" fill="#CED0D0"/>
    <path d="M48.7776 0.688324H46.5654L45.6155 6.83163L45.6021 6.91896H47.4746L47.5814 5.76359H47.7286L47.843 6.91896H49.7137L48.7776 0.688324Z" fill="#CED0D0"/>
    <path d="M43.8843 0.729172H45.6154V6.91896H43.8843V0.729172Z" fill="#CED0D0"/>
    <path d="M42.727 3.78235L43.789 0.728981H41.771L41.4359 2.02984V0.728981H39.7026V6.91897H41.4357V5.62005L41.771 6.91897H43.8766L42.727 3.78235Z" fill="#CED0D0"/>
    <path d="M38.7486 0.688324H36.539L35.5752 6.91896H37.4479L37.5545 5.76359H37.7L37.8161 6.91896H39.6869L38.7486 0.688324Z" fill="#CED0D0"/>
    <path d="M34.1098 0.728981L33.9703 2.54354L33.8308 0.728981H31.9136L32.8731 6.92072H35.0947L36.0253 0.728981H34.1098Z" fill="#CED0D0"/>
    <path d="M30.1201 0.647285C28.2651 0.647285 28.2651 2.1905 28.2651 3.82455C28.2651 5.4588 28.2651 7.00182 30.1201 7.00182C31.9775 7.00182 31.9775 5.4588 31.9775 3.82455C31.9775 2.1905 31.9775 0.647285 30.1201 0.647285Z" fill="#CED0D0"/>
    <path d="M19.7119 0.647285C17.8569 0.647285 17.8569 2.1905 17.8569 3.82455C17.8569 5.4588 17.8569 7.00182 19.7119 7.00182C21.5693 7.00182 21.5693 5.4588 21.5693 3.82455C21.5693 2.1905 21.5693 0.647285 19.7119 0.647285Z" fill="#CED0D0"/>
    <path d="M28.5791 6.6202C28.5771 6.61631 28.4454 6.3549 28.3641 6.06002C28.2727 5.73831 28.2439 5.56598 28.2439 5.56598L28.2148 5.39326H26.9816V0.728981H25.2485V6.91897H28.7302L28.5791 6.6202Z" fill="#CED0D0"/>
    <path d="M23.8978 3.03972C23.8822 3.02805 23.8647 3.01443 23.8435 3.00082C23.7138 2.91193 23.4442 2.72579 23.4442 2.50288C23.4442 2.3749 23.4751 2.25664 23.6922 2.25664C24.0005 2.25664 24.1864 2.29359 24.6287 2.4136L24.8902 2.48538V1.10126L24.8769 0.951883L24.7412 0.901506C24.3282 0.748429 24.0549 0.647675 23.5683 0.647675C22.6512 0.647675 21.6705 1.12441 21.6705 2.46203C21.6705 3.41201 22.0097 4.01303 22.8393 4.53431C22.9597 4.61211 23.2388 4.81946 23.2388 5.10422C23.2388 5.22831 23.2096 5.39325 22.9498 5.39325C22.6028 5.39325 22.4615 5.36213 21.9768 5.23998L21.7557 5.18571L21.7226 5.41056C21.7226 5.41056 21.6818 5.68385 21.6335 5.93787C21.589 6.16097 21.5211 6.43036 21.5211 6.43231L21.4766 6.60289L21.6394 6.67272C22.0966 6.86839 22.7228 7.00221 23.1981 7.00221C24.3784 7.00221 25.0553 6.32572 25.0553 5.14506C25.0553 4.25325 24.7194 3.64269 23.8978 3.03972Z" fill="#CED0D0"/>
    <path d="M15.8968 0.728981V2.99907H15.7321V0.729176H13.999V6.91897H15.7321V4.52459H15.8968V6.91897H17.6299V0.728981H15.8968Z" fill="#CED0D0"/>
    <path d="M12.6497 2.21404C12.8551 2.21404 13.2992 2.23154 13.6345 2.25488L13.8554 2.2722V0.756209L13.679 0.730923C13.3089 0.678601 12.8105 0.647675 12.5412 0.647675C10.2886 0.647675 10.2886 2.40796 10.2886 3.82494C10.2886 5.3952 10.5794 7.00221 12.7252 7.00221C12.9753 7.00221 13.27 6.97498 13.679 6.91702L13.8554 6.89173V5.37769L13.6345 5.39325C13.303 5.41854 12.857 5.4341 12.6497 5.4341C12.1747 5.4341 12.0623 5.37575 12.0623 3.82494C12.0623 2.27414 12.1747 2.21404 12.6497 2.21404Z" fill="#CED0D0"/>
    <path d="M10.1938 5.39326H8.74028V4.52478H10.0604V2.99907H8.74028V2.25664H10.1436V0.728981H7.00684V6.91897H10.4442L10.1938 5.39326Z" fill="#CED0D0"/>
    <path d="M5.49642 5.39326L6.75468 1.87852V0.728981H3.45332V2.25664H4.68435L3.45312 5.54828L3.50175 6.946L6.86516 6.91683V5.39326H5.49642Z" fill="#CED0D0"/>
    <path d="M3.3447 5.37775L3.13152 5.39331C2.78841 5.4186 2.53439 5.43416 2.32899 5.43416C1.854 5.43416 1.74352 5.37581 1.74352 3.825C1.74352 2.2742 1.854 2.2141 2.32899 2.2141C2.53069 2.2141 2.78452 2.22966 3.13152 2.25475L3.35248 2.27226V0.75627L3.17606 0.730985C2.76527 0.672827 2.47253 0.647736 2.2224 0.647736C1.53618 0.647736 1.0398 0.808593 0.708356 1.14198C0.150121 1.70021 0.150121 2.61926 0.152066 3.68146V3.825C0.152066 5.24004 0.152066 7.00227 2.40446 7.00227C2.68571 7.00227 2.85824 6.98866 3.21497 6.93634L3.39916 6.91105L3.3447 5.37775Z" fill="#CED0D0"/>
    <path d="M31.9562 9.81868H31.5355L30.665 13.8216H31.1844L31.3339 13.0426H32.1305L32.2798 13.8216H32.8147L31.9562 9.81868ZM31.4131 12.6276L31.7331 10.5942H31.7428L31.9443 12.0481L32.0509 12.6276H31.4131Z" fill="white"/>
    <path d="M28.1951 11.7935L28.944 13.8222H29.5547L28.7053 11.7565L29.4909 9.81828H28.944L28.1951 11.7404V9.81828H27.6538V13.8222H28.1951V11.7935Z" fill="white"/>
    <path d="M25.9951 11.0322C25.9563 10.7957 25.906 10.602 25.8439 10.4506C25.7799 10.2974 25.7045 10.1795 25.6171 10.0939C25.5372 10.0136 25.4423 9.9498 25.3378 9.90601C25.2391 9.86481 25.1344 9.83985 25.0278 9.8321C24.9227 9.8231 24.8173 9.81862 24.7119 9.81868H24.1167V13.8216H24.483C24.6438 13.8216 24.7912 13.8179 24.929 13.8063C25.0593 13.7968 25.1881 13.7721 25.3127 13.7327C25.428 13.6939 25.5337 13.631 25.623 13.5483C25.712 13.4651 25.7897 13.3527 25.8536 13.2111C25.9176 13.0699 25.9661 12.8915 25.9989 12.6742C26.0338 12.4589 26.0491 12.1975 26.0491 11.889C26.0491 11.5539 26.032 11.2666 25.9951 11.0322ZM25.4872 11.889C25.4872 12.0596 25.4852 12.2341 25.4794 12.4124C25.4736 12.5908 25.4463 12.7516 25.3981 12.8952C25.3508 13.0335 25.2646 13.1554 25.1499 13.2461C25.0355 13.3371 24.8669 13.378 24.6479 13.3702V10.2703C24.7969 10.2625 24.9212 10.282 25.0219 10.3306C25.1229 10.3789 25.2042 10.4448 25.266 10.5302C25.3283 10.6156 25.375 10.7142 25.4059 10.827C25.4347 10.941 25.4563 11.0592 25.4677 11.1833C25.4814 11.3074 25.4872 11.4315 25.4872 11.5556V11.889Z" fill="white"/>
    <path d="M22.5039 11.416C22.4997 11.2649 22.4881 11.1141 22.4689 10.9641C22.4501 10.8146 22.4191 10.6669 22.3763 10.5224C22.3363 10.3852 22.2754 10.2551 22.1956 10.1365C22.1188 10.0259 22.0189 9.93336 21.9029 9.86516C21.7867 9.79922 21.6414 9.7644 21.4668 9.7644C21.2944 9.7644 21.1492 9.79942 21.0326 9.86516C20.9163 9.93293 20.8163 10.0256 20.7399 10.1365C20.6603 10.255 20.5995 10.3852 20.5598 10.5224C20.517 10.6657 20.4859 10.8132 20.4664 10.9641C20.4474 11.1178 20.4353 11.2687 20.4318 11.416C20.428 11.5503 20.426 11.6847 20.4258 11.8192C20.4258 11.9398 20.4277 12.0751 20.4316 12.2241C20.4355 12.3718 20.4472 12.5231 20.4666 12.6742C20.4861 12.8275 20.5168 12.9747 20.5598 13.1183C20.6022 13.2614 20.6623 13.3894 20.7399 13.5038C20.816 13.6151 20.9169 13.7074 21.0346 13.7732C21.1527 13.8413 21.2981 13.8761 21.4668 13.8761C21.6374 13.8761 21.7809 13.8411 21.9013 13.7732C22.0185 13.7068 22.1192 13.6147 22.1956 13.5038C22.2734 13.3894 22.3333 13.2614 22.3763 13.1183C22.4193 12.9731 22.4503 12.8245 22.4689 12.6742C22.4883 12.5231 22.5 12.3718 22.5039 12.2241C22.506 12.0751 22.5078 11.9396 22.5078 11.8192C22.5078 11.6994 22.506 11.5653 22.5039 11.416ZM21.9301 12.4221C21.9184 12.6118 21.897 12.7769 21.8622 12.9166C21.8291 13.0562 21.7809 13.1687 21.7189 13.2519C21.659 13.3352 21.5734 13.3762 21.4668 13.3762C21.3621 13.3762 21.2767 13.3354 21.2149 13.2517C21.1527 13.1687 21.1062 13.0562 21.0713 12.9166C21.0383 12.7769 21.0169 12.6118 21.0054 12.4221C20.9952 12.2213 20.99 12.0203 20.9899 11.8192C20.9899 11.6079 20.9959 11.4066 21.0054 11.2162C21.0171 11.0283 21.0385 10.8636 21.0713 10.7241C21.1064 10.5846 21.1527 10.4718 21.2149 10.3888C21.2767 10.3051 21.3621 10.2645 21.4668 10.2645C21.5734 10.2645 21.659 10.3053 21.7189 10.389C21.7809 10.4718 21.8293 10.5846 21.8622 10.7239C21.8972 10.8636 21.9186 11.0283 21.9301 11.2164C21.9509 11.6181 21.9509 12.0204 21.9301 12.4221Z" fill="white"/>
    <path d="M18.3236 13.822L19.1096 9.81828H18.6051L18.3608 11.2308L18.1484 12.5795L18.1217 12.8294H18.1114L17.6545 9.81828H17.1235L17.8988 13.822H18.3236Z" fill="white"/>
    <path d="M47.395 5.55761H47.9144L48.0309 6.71298H49.4749L48.6008 0.893532H46.7436L45.8423 6.71318H47.2884L47.395 5.55761ZM47.6004 2.99887L47.6432 2.42119H47.6761L47.7167 2.99887L47.8156 4.48374H47.5016L47.6004 2.99887Z" fill="white"/>
    <path d="M44.0903 0.934967H45.411V6.7126H44.0903V0.934967Z" fill="white"/>
    <path d="M41.2299 4.31919H41.3123L41.9315 6.71279H43.5822L42.5093 3.78274L43.4998 0.934963H41.9315L41.3123 3.32876H41.2299V0.934769H39.9092V6.71259H41.2299V4.31919Z" fill="white"/>
    <path d="M37.3685 5.55761H37.8882L38.0045 6.71298H39.4487L38.5723 0.893532H36.7151L35.8159 6.71318H37.2599L37.3685 5.55761ZM37.5743 2.99887L37.6149 2.42119H37.648L37.6904 2.99887L37.7892 4.48374H37.4753L37.5743 2.99887Z" fill="white"/>
    <path d="M34.9179 6.71473L35.7873 0.93515H34.3015L34.0292 4.48412L33.9877 5.14447H33.9547L33.9134 4.48412L33.6411 0.93515H32.1553L33.0504 6.71473H34.9179Z" fill="white"/>
    <path d="M30.1225 6.79448C31.7742 6.79448 31.7742 5.47436 31.7742 3.82475C31.7742 2.20606 31.7742 0.852684 30.1225 0.852684C28.4707 0.852684 28.4707 2.20587 28.4707 3.82475C28.4707 5.47436 28.4707 6.79448 30.1225 6.79448ZM30.1225 1.9676C30.3705 1.9676 30.4111 2.173 30.4111 3.82475C30.4111 5.47436 30.3705 5.6819 30.1225 5.6819C29.8743 5.6819 29.8334 5.47436 29.8334 3.82475C29.8334 2.173 29.8743 1.9676 30.1225 1.9676Z" fill="white"/>
    <path d="M28.1652 6.11585C28.0705 5.77546 28.0411 5.59846 28.0411 5.59846H26.7761V0.934967H25.4556V6.71279H28.3957C28.3957 6.71279 28.2534 6.43192 28.1652 6.11585Z" fill="white"/>
    <path d="M24.8492 5.14467C24.8492 4.31919 24.5521 3.77437 23.7761 3.20485C23.6524 3.11402 23.2394 2.87478 23.2394 2.50327C23.2394 2.17319 23.4458 2.04929 23.6934 2.04929C24.0237 2.04929 24.23 2.09053 24.684 2.21443V1.1001C24.2629 0.943324 24.0237 0.852295 23.5695 0.852295C22.7444 0.852295 21.8775 1.26543 21.8775 2.46203C21.8775 3.3698 22.2159 3.89808 22.9506 4.36042C23.1982 4.51719 23.4458 4.77316 23.4458 5.10344C23.4458 5.43351 23.2396 5.59846 22.9506 5.59846C22.5791 5.59846 22.4219 5.56306 21.9267 5.43935C21.9267 5.43935 21.8858 5.71808 21.8355 5.97658C21.8013 6.14619 21.7631 6.31494 21.7207 6.48268C22.1529 6.66883 22.7444 6.79526 23.1982 6.79526C24.1888 6.79526 24.8492 6.30004 24.8492 5.14467Z" fill="white"/>
    <path d="M19.7125 6.79448C21.3641 6.79448 21.3641 5.47436 21.3641 3.82475C21.3641 2.20606 21.3641 0.852684 19.7125 0.852684C18.0625 0.852684 18.0625 2.20587 18.0625 3.82475C18.0625 5.47436 18.0625 6.79448 19.7125 6.79448ZM19.7125 1.9676C19.9605 1.9676 20.0033 2.173 20.0033 3.82475C20.0033 5.47436 19.9605 5.6819 19.7125 5.6819C19.4661 5.6819 19.4256 5.47436 19.4256 3.82475C19.4256 2.173 19.4661 1.9676 19.7125 1.9676Z" fill="white"/>
    <path d="M15.5268 4.31919H16.1044V6.71279H17.4251V0.934963H16.1044V3.20466H15.5268V0.934769H14.2061V6.71259H15.5268V4.31919Z" fill="white"/>
    <path d="M12.7261 6.79526C12.9986 6.79526 13.3038 6.76219 13.6506 6.71279V5.59826C13.3122 5.62316 12.8648 5.63969 12.6505 5.63969C11.9323 5.63969 11.8578 5.29308 11.8578 3.82378C11.8578 2.35467 11.9323 2.00805 12.6505 2.00805C12.865 2.00805 13.3122 2.02459 13.6506 2.04929V0.93496C13.3038 0.885361 12.8156 0.852295 12.5429 0.852295C10.62 0.852295 10.4961 2.18136 10.4961 3.82378C10.4961 5.46639 10.803 6.79526 12.7261 6.79526Z" fill="white"/>
    <path d="M10.0202 5.59846H8.53457V4.31919H9.85527V3.20467H8.53457V2.0493H9.93774V0.934967H7.21387V6.71279H10.2034L10.0202 5.59846Z" fill="white"/>
    <path d="M3.66191 5.58232L3.70217 6.73847L6.66004 6.7126V5.59846H5.20474L6.55014 1.84312V0.934967H3.66113V2.0493H4.98183L3.66191 5.58232Z" fill="white"/>
    <path d="M2.40608 6.79526C2.67839 6.79526 2.84022 6.78203 3.18683 6.73263L3.14734 5.59846C2.8089 5.62316 2.54496 5.63969 2.33041 5.63969C1.61229 5.63969 1.5378 5.29308 1.5378 3.82378C1.5378 2.35467 1.61229 2.00805 2.33022 2.00805C2.54496 2.00805 2.8089 2.02459 3.14734 2.04929V0.93496C2.80073 0.885361 2.49536 0.852295 2.22285 0.852295C0.299763 0.852295 0.359087 2.18136 0.359087 3.82378C0.359087 5.46639 0.482794 6.79526 2.40608 6.79526Z" fill="white"/>
  </svg>
</template>
