<template>
  <div class="burger-border" v-bind:class="{
    'theme-dark': theme === 'dark',
    'theme-light': theme === 'light',
    }">
    <div v-bind:class="{'burger': true, 'close': showClose}">
      <span></span>
      <span></span>
    </div>
  </div>

</template>

<script>


export default {
  name: "HamburgerMenuButton",
  props: {
    buttonText: {
      type: String,
      default: ''
    },
    showClose: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light'
    },
  }
}
</script>

<style lang="scss" scoped>
.burger-border {
  border: 3px solid white;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  position: relative;
}

.burger {
  position: absolute;
  left: -2%;
  top: 2%;
  width: 40px;
  height: 30px;
  background: transparent;
  cursor: pointer;
  display: block;
  transform: translate(25%, 45%);

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  span:nth-of-type(1) {
    top: 25%;
    transform-origin: left center;
  }

  span:nth-of-type(2) {
    top: 65%;
    transform: translateY(-50%);
    transform-origin: left center;
  }
}

.close {
  span:nth-of-type(1) {
    top: 0;
    left: 5px;
    transform: rotate(405deg);
  }

  span:nth-of-type(2) {
    top: 28px;
    left: 5px;
    transform: rotate(-405deg);
  }
}

.theme-dark {
  color: #1B3B3D;
  border: 3px solid #1B3B3D;

  .burger span {
    background: #1B3B3D;
  }
}

.theme-light {
  color: #ffffff;
}

</style>