import {createStore} from 'vuex';
import cocktail from './cocktail';
import products from "./products";
import utils from "./utils";

export default createStore({
    modules: {
        cocktail,
        products,
        utils,
    },
});
