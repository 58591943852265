<template>
<div class="filter-button-wrapper">
  {{ buttonText }}
</div>
</template>

<script>
export default {
  name: "CocktailsFilterButton",
  props: {
    buttonText: {
      type: String
    }
  },
}
</script>

<style lang="scss" scoped>
.filter-button-wrapper {
  background: #C0FF6F;
  color: #4E5768;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 10px;
  font-family: $font-bold;
  text-align: center;
  &:hover {
    background: #1B3B3D;
    transition: 0.3s ease-in-out;
    color: #FFFFFF;
  }
}
.button-active {
  background: #1B3B3D;
  transition: 0.3s ease-in-out;
}
@media screen and (min-width: 768px){
  .filter-button-wrapper {
    padding: 10px 20px;
  }
}
</style>
