<template>
  <MobileProductsMapComponent v-if="isMobile && showMobileMap"
                              :width="winW * 2"
                              :height="winH * 1.5"
                              :products="ProductsMapConfig"/>
  <LoaderComponent :progress="progress" v-if="!isMobile && progress < 97"/>
  <div ref="navigation" class="navigation-wrapper" v-bind:class="{'hide-navigation': !showNavigationBar}">
    <NavigationBar @onBrandClick="onBrandClick"/>
  </div>
  <canvas id="animationContainer"></canvas>
  <div class="d-flex justify-content-end">
    <SideContent
      ref="sideContent"
      :product="product"
      :scroll-position="(scrollPosition / 4) * 100"
      @backButtonClicked="goBackToIdle"
      @nextBrandButtonClicked="goToNextCheckpoint"
    />
  </div>
  <WelcomeComponent ref="welcomeComponent" @startJourney="onStartJourney"
                    v-if="route.name === 'home' && progress >= 97"/>
</template>

<style lang="scss" scoped>
.navigation-wrapper {
  position: fixed;
  width: 90%;
  left: 5%;
  bottom: 0;
  padding-bottom: 30px;
  transition: transform 1s;
}

.hide-navigation {
  transform: translateY(100%);
}

#animationContainer {
  position: fixed;
  z-index: -1;
}
</style>

<script setup>
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import gsap from "gsap"
import {SceneManager} from "@/webgl/SceneManager";
import {Studio} from "@/webgl/Studio";
import NavigationBar from "@/components/NavigationBar.vue";
import SideContent from "@/components/SideContent.vue";
import store from "@/store";
import WelcomeComponent from "@/components/Components/WelcomeComponent.vue";
import LoaderComponent from "@/components/Components/LoaderComponent.vue";
import {useRoute} from "vue-router";
import {Brands} from "@/enum/brands";
import {ProductsMapConfig} from "@/utils/ProductsMapConfig";
import MobileProductsMapComponent from "@/components/Components/MobileProductsMapComponent.vue";
import router from "@/router";

const isMobile = window.innerWidth < 768;

const winW = ref(window.innerWidth);
const winH = ref(window.innerHeight);

const showMobileMap = ref(false);
const selectedCheckpoint = ref(null);
const isSplitScreen = ref(false);
const sideContent = ref(null);
const scrollPosition = ref(0);
const navigation = ref(null);
const welcomeComponent = ref(null);
const product = ref(null)

const allLoadersProgress = ref(0)
const mapProgress = ref(0)
const sphereProgress = ref(0)

const showNavigationBar = ref(false);


const progress = computed(() => {
  const value = (mapProgress.value + sphereProgress.value + allLoadersProgress.value) / 3;
  return (value).toFixed(0);
});

const route = useRoute();
const showStudio = computed(() => parseInt(route.query.studio) === 1)

let sceneManager;
let studio;

const brandNameCheckpointMap = {};
brandNameCheckpointMap[Brands.TATRA_TEA] = 1;
brandNameCheckpointMap[Brands.ON_LEMON] = 2;
brandNameCheckpointMap[Brands.GIN_1689] = 3;
brandNameCheckpointMap[Brands.BERKSHIRE] = 4;
brandNameCheckpointMap[Brands.MATES] = 5;
brandNameCheckpointMap[Brands.LALA] = 6;
brandNameCheckpointMap[Brands.SAN_COSME] = 7;
brandNameCheckpointMap[Brands.PADRE_AZUL] = 8;
brandNameCheckpointMap[Brands.XIAMAN] = 9;
brandNameCheckpointMap[Brands.GELSTONS] = 10;
brandNameCheckpointMap[Brands.CZECHOSLOVAKIA] = 11;
brandNameCheckpointMap[Brands.BANDOENG] = 12;

const checkpointToBrandNameMap = {};
checkpointToBrandNameMap[1] = Brands.TATRA_TEA;
checkpointToBrandNameMap[2] = Brands.ON_LEMON;
checkpointToBrandNameMap[3] = Brands.GIN_1689;
checkpointToBrandNameMap[4] = Brands.BERKSHIRE;
checkpointToBrandNameMap[5] = Brands.MATES;
checkpointToBrandNameMap[6] = Brands.LALA;
checkpointToBrandNameMap[7] = Brands.SAN_COSME;
checkpointToBrandNameMap[8] = Brands.PADRE_AZUL;
checkpointToBrandNameMap[9] = Brands.XIAMAN;
checkpointToBrandNameMap[10] = Brands.GELSTONS;
checkpointToBrandNameMap[11] = Brands.CZECHOSLOVAKIA;
checkpointToBrandNameMap[12] = Brands.BANDOENG;

async function executeSingleBrandAnimation() {
  if (!isMobile) {
    if (route.name === 'SingleProduct' && progress.value >= 97) {
      const brandCheckpoint = brandNameCheckpointMap[route.params.brand]
      onBrandClick({checkpoint: brandCheckpoint});
    }
  } else {
    console.log('executeSingleBrandAnimation')
    if (route.name === 'SingleProduct') {
      showMobileMap.value = false;
      await loadProduct(brandNameCheckpointMap[route.params.brand]);
      hideHeaderAndNavigation();
    } else {
      showMobileMap.value = true;
      product.value = null;
      showHeaderAndNavigation();
    }
  }
}

watch(progress, executeSingleBrandAnimation)
watch(route, executeSingleBrandAnimation, {flush: 'pre', immediate: true, deep: true})


onMounted(async () => {
  if (!isMobile) {
    const container = document.getElementById("animationContainer");
    sceneManager = new SceneManager(container, showStudio.value);
    studio = new Studio(sceneManager, showStudio.value);
    render();
    registerMapListeners();
  } else if (route.name === 'SingleProduct') {
    await loadProduct(brandNameCheckpointMap[route.params.brand]);
    hideHeaderAndNavigation();
    return;
  } else {
    product.value = null;
    showMobileMap.value = true;
  }

  showHeaderAndNavigation();
});

onUnmounted(() => {
  window.removeEventListener("wheel", onScroll);
  window.removeEventListener("resize", onWindowResize);
  window.removeEventListener("mapDownloadProgress", () => {
  });
  window.removeEventListener("sphereDownloadProgress", () => {
  });
  window.removeEventListener("allLoadersProgress", () => {
  });
});

function registerMapListeners() {

  window.addEventListener("wheel", onScroll);

  window.addEventListener("mapDownloadProgress", (e) => {
    mapProgress.value = e.detail.progress();
  });

  window.addEventListener("sphereDownloadProgress", (e) => {
    console.log(e.detail.progress() + ' map');
    sphereProgress.value = e.detail.progress();
  });

  window.addEventListener("allLoadersProgress", (e) => {
    allLoadersProgress.value = e.detail.progress();
  });

  window.addEventListener("resize", onWindowResize);

}

function onStartJourney() {
  showHeaderAndNavigation();
  welcomeComponent.value.startAnimation();
}

function splitScreen() {
  isSplitScreen.value = true;

  // sceneManager.camera.aspect = window.innerWidth / 2 / window.innerHeight;
  // sceneManager.camera.updateProjectionMatrix();
  // sceneManager.renderer.setSize(window.innerWidth / 2, window.innerHeight);
  // render();

  const aspect = window.innerWidth / 2 / window.innerHeight;
  const width = window.innerWidth / 2;

  executeScreenAnimations(aspect, width)
}

function removeSplitScreen() {
  isSplitScreen.value = false;

  const aspect = window.innerWidth / window.innerHeight;
  const width = window.innerWidth;

  executeScreenAnimations(aspect, width)
}

function executeScreenAnimations(aspect, width) {
  gsap.to(sceneManager.camera, {
    duration: 1,
    aspect: aspect,
    onUpdate: () => {
      sceneManager.camera.updateProjectionMatrix();
      render();
    },
  });

  gsap.to(sceneManager.renderer.size, {
    duration: 1,
    width: width,
    onUpdate: () => {
      sceneManager.camera.updateProjectionMatrix();
      render();
    },
  });

  const container = document.getElementById("animationContainer");
  gsap.to(container, {
    duration: 1,
    width: width,
  });
}

function onWindowResize() {
  if (selectedCheckpoint.value !== null && isSplitScreen.value) {
    return splitScreen();
  }
  sceneManager.camera.aspect = window.innerWidth / window.innerHeight;
  sceneManager.camera.updateProjectionMatrix();
  sceneManager.renderer.setSize(window.innerWidth, window.innerHeight);
  render();
}

function render() {
  sceneManager.renderer.render(sceneManager.scene, sceneManager.camera);
}

async function onBrandClick(event) {
  if (isMobile) {
    await loadProduct(event.checkpoint);
    router.push({name: 'SingleProduct', params: {brand: checkpointToBrandNameMap[event.checkpoint]}})
    return;
  }

  selectedCheckpoint.value = null;

  hideHeaderAndNavigation();

  await loadProduct(event.checkpoint);
  await studio.getSheet("idleToCheckpoint" + event.checkpoint).sequence.play({
    iterationCount: 1, range: [0, 4]
  });

  studio.getSheet("checkpoint" + event.checkpoint + "Scroll").sequence.position = 0;

  selectedCheckpoint.value = event.checkpoint;
  splitScreen();
  sideContent.value.showPage();
}

async function goBackToIdle() {

  sideContent.value.hidePage();
  removeSplitScreen();

  await studio.getSheet("idleToCheckpoint" + selectedCheckpoint.value).sequence.play({
    iterationCount: 1,
    range: [0, 4],
    direction: "reverse"
  }).then(() => {
    showHeaderAndNavigation();
  });

  selectedCheckpoint.value = null;
}


function onScroll(event) {
  if (selectedCheckpoint.value === null) {
    console.log("scrollBlocked");
    return;
  }

  let sheet = studio.getSheet("checkpoint" + selectedCheckpoint.value + "Scroll");
  if (!sheet) {
    console.error(`Sheet 'checkpoint${selectedCheckpoint.value}Scroll' does not exist.`);
    return;
  }


  //4 - the total seconds of the animation
  if (scrollPosition.value >= 4) {
    if (event.deltaY < 0) {
        const delta = event.deltaY * 0.0005;
        if (sheet.sequence.position + delta < 0) {
          sheet.sequence.position = 0;
        } else {
          sheet.sequence.position += delta;
        }
        scrollPosition.value = sheet.sequence.position;
        return;
    }
  } else {
    const delta = event.deltaY * 0.0005;
    if (sheet.sequence.position + delta < 0) {
      sheet.sequence.position = 0;
    } else {
      sheet.sequence.position += delta;
    }
    scrollPosition.value = sheet.sequence.position;
  }
}

function goToNextCheckpoint() {
  if (selectedCheckpoint.value === 12) {
    goBackToIdle();
    return;
  }

  const currentCheckpoint = selectedCheckpoint.value;
  selectedCheckpoint.value += 1;

  removeSplitScreen();
  sideContent.value.hidePage();
  loadProduct(selectedCheckpoint.value)

  const sheet = studio.getSheet(`checkpoint${currentCheckpoint}toNext`);

  sheet.sequence.play({
    iterationCount: 1, range: [0, 4]
  }).then(() => {
    splitScreen();
    sideContent.value.showPage();
    scrollPosition.value = 0;
    studio.getSheet("checkpoint" + selectedCheckpoint.value + "Scroll").sequence.position = 0;
  });
}

function hideHeaderAndNavigation() {
  store.dispatch("utils/toggleHeader", false);
  showNavigationBar.value = false;
}

function showHeaderAndNavigation() {
  store.dispatch("utils/toggleHeader", true);
  showNavigationBar.value = true;
}

async function loadProduct(productId) {
  product.value = await store.dispatch("products/getProduct", {
    id: productId,
  });
}
</script>
