<script>
export default {
  name: "DrinkPlusLogo",
  props: {
    theme: {
      type: String,
      default: 'light',
    },
    size: {
      type: String,
      default: 'regular',
    }
  },
  data() {
    return {
      width: 240,
      height: 29
    }
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.width = 200;
    }
    if (this.$props.size === 'small') {
      this.width = 200;
    }
  }
}
</script>

<template>
  <svg class="logo-drinkplus" v-if="theme === 'light'" :width="width" :height="height" viewBox="0 0 240 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="shadow"   d="M2.89489 27.2947C3.84542 23.7351 4.75068 20.2667 5.74647 16.6158C3.93595 16.6158 2.26121 16.6158 0.450684 16.6158C0.903315 14.9272 1.26542 13.4212 1.71805 11.8696C1.76332 11.6414 2.17068 11.4589 2.44226 11.4132C3.70963 11.3676 5.02226 11.3676 6.28963 11.4132C6.92332 11.4589 7.24016 11.2307 7.37595 10.5918C8.10016 7.71667 8.91489 4.84157 9.6391 1.92084C9.77489 1.32756 10.0012 1.05374 10.6802 1.05374C15.8854 1.09938 21.0907 1.09938 26.2507 1.05374C26.8391 1.05374 27.1559 1.23629 27.3823 1.78393C28.3781 3.92884 29.5096 6.02812 30.5054 8.17304C30.6865 8.58377 30.777 9.17704 30.6865 9.63341C29.917 12.7367 29.057 15.84 28.197 18.9433C28.1065 19.3083 27.9254 19.7191 27.6538 19.9929C25.3002 22.3204 22.9465 24.6478 20.5475 26.9296C20.3212 27.1578 19.9138 27.3404 19.597 27.3404C14.1202 27.3404 8.64332 27.3404 3.21174 27.3404C3.12121 27.3404 3.07595 27.3404 2.89489 27.2947ZM10.5444 22.0465C12.4002 22.0465 14.1654 21.9553 15.8854 22.0922C17.5602 22.2291 18.8728 21.9096 19.9138 20.4949C20.4117 19.8103 21.1359 19.3083 21.6791 18.6694C21.9054 18.3956 22.1317 18.0762 22.2223 17.7567C22.8559 15.4749 23.4896 13.1931 24.0328 10.8656C24.1233 10.5005 24.1233 10.0441 23.9875 9.72468C23.5802 8.76631 23.1275 7.80795 22.6296 6.89521C22.4938 6.66703 22.1317 6.43885 21.8602 6.43885C19.6875 6.39321 17.4696 6.39321 15.297 6.43885C15.0254 6.43885 14.6181 6.66703 14.5728 6.89521C14.1202 8.35558 13.7581 9.86159 13.3507 11.5045C14.7991 11.5045 16.1117 11.5045 17.5149 11.5045C17.1075 13.1474 16.7454 14.6991 16.2928 16.2051C16.2475 16.342 15.7949 16.5245 15.5233 16.5702C14.5275 16.6158 13.577 16.5702 12.5812 16.6158C12.3096 16.6158 11.9475 16.8896 11.857 17.0722C11.4044 18.7151 10.997 20.3123 10.5444 22.0465Z" fill="#E0E0E0"/>
    <path class="shadow"   d="M54.3043 27.2695C52.1316 27.2695 50.0495 27.2695 47.8769 27.2695C47.2432 24.3032 46.6548 21.3368 46.0211 18.3704C43.8032 18.3704 41.6306 18.3704 39.3674 18.3704C38.6885 20.9261 37.919 23.4817 37.3306 26.083C37.1043 27.1326 36.6516 27.4065 35.6559 27.3608C34.0716 27.2695 32.4874 27.3152 30.7222 27.3152C33.0759 18.5073 35.3843 9.8364 37.738 1.02855C38.1001 1.02855 38.4169 1.02855 38.7337 1.02855C44.1201 1.02855 49.4611 1.02855 54.8474 1.02855C55.6169 1.02855 56.0243 1.25673 56.2959 1.94128C56.658 2.76274 57.0201 3.58419 57.4727 4.31438C58.378 5.68347 58.3327 7.00693 58.0159 8.60421C57.3822 11.6619 56.2053 14.2631 53.5348 15.9517C52.1769 16.8188 52.0864 17.9141 52.4938 19.3744C53.1727 21.9301 53.6706 24.577 54.3043 27.2695ZM40.5895 13.7155C42.2643 13.7155 43.758 13.5786 45.2064 13.7611C47.4695 13.9893 49.1895 13.396 50.6832 11.6162C51.8148 10.2471 51.9506 7.37202 50.638 6.18547C50.5022 6.04857 50.2759 6.00293 50.0948 6.00293C47.7864 6.00293 45.5232 6.00293 43.2148 6.00293C42.9885 6.00293 42.5811 6.23111 42.5359 6.41366C41.8569 8.74112 41.2685 11.1599 40.5895 13.7155Z" fill="#E0E0E0"/>
    <path class="shadow"   d="M77.7759 1.07655C77.459 2.2631 77.2327 3.35838 76.9159 4.36238C76.8253 4.59056 76.418 4.91002 76.1464 4.91002C75.3316 5.00129 74.4716 4.95566 73.5664 4.95566C71.9369 11.1166 70.3074 17.2319 68.6327 23.4841C69.6737 23.4841 70.6243 23.4841 71.8011 23.4841C71.439 24.8532 71.1674 26.0854 70.8053 27.3175C66.5959 27.3175 62.4769 27.3175 58.2222 27.3175C58.539 26.131 58.8106 24.9901 59.1727 23.8492C59.218 23.7123 59.4443 23.621 59.6253 23.5297C59.7611 23.4841 59.8969 23.5297 60.0327 23.5297C62.4316 23.4841 62.4316 23.4841 63.0653 21.1566C64.4232 16.0453 65.7811 10.934 67.139 5.82275C67.1843 5.59457 67.2295 5.36638 67.2748 5.00129C66.2337 5.00129 65.2832 5.00129 64.1969 5.00129C64.5137 3.72347 64.7853 2.58256 65.1474 1.48728C65.1927 1.30474 65.5548 1.12219 65.7359 1.12219C69.6737 1.07655 73.6569 1.07655 77.7759 1.07655Z" fill="#E0E0E0"/>
    <path class="shadow"   d="M107.74 1.09607C105.386 9.90391 103.078 18.6205 100.724 27.3827C99.0496 27.3827 97.3748 27.4283 95.7454 27.3371C95.4738 27.3371 95.1117 26.8807 94.9759 26.5612C92.9843 21.7238 90.9927 16.8407 89.0464 11.9576C88.8653 11.4556 88.639 10.9536 88.3222 10.269C87.5075 13.2354 86.7832 16.0192 86.059 18.803C85.3801 21.4043 84.6559 23.96 84.0222 26.5612C83.8864 27.1545 83.6148 27.3827 82.9811 27.3371C81.3064 27.2914 79.6317 27.3371 77.7759 27.3371C78.319 25.1921 78.8622 23.1841 79.4053 21.1761C81.0801 14.8327 82.8001 8.48918 84.4748 2.10007C84.7011 1.23298 85.0632 0.959158 85.9232 1.00479C87.3264 1.05043 88.6843 1.09607 90.0875 1.00479C90.9475 0.959158 91.2643 1.27861 91.5359 2.0088C93.3011 6.66372 95.0664 11.3186 96.8769 15.9736C97.0127 16.293 97.1485 16.5668 97.3748 17.0688C98.2348 13.783 99.0948 10.6797 99.9096 7.57645C100.453 5.61408 100.951 3.65171 101.494 1.68934C101.584 1.41552 101.946 1.05043 102.173 1.05043C103.938 1.05043 105.794 1.09607 107.74 1.09607Z" fill="#E0E0E0"/>
    <path class="shadow"   d="M131.322 27.2583C129.104 27.2583 127.022 27.2583 124.895 27.2583C123.854 23.151 122.813 19.0438 121.727 14.7539C120.912 15.4385 120.233 16.123 119.418 16.6707C116.883 18.3136 115.39 20.5041 114.982 23.5618C114.892 24.3832 114.484 25.2047 114.349 26.0261C114.213 27.1671 113.67 27.3952 112.583 27.3496C110.999 27.2583 109.415 27.304 107.74 27.304C110.094 18.4505 112.402 9.77954 114.711 1.06297C116.793 1.06297 118.83 1.06297 120.957 1.06297C120.007 4.75953 119.011 8.36481 118.06 12.0157C118.105 12.0614 118.196 12.0614 118.241 12.107C120.504 10.1903 122.768 8.27354 125.031 6.35681C126.932 4.75953 128.788 3.11661 130.689 1.56497C131.051 1.29115 131.594 1.06297 132.047 1.06297C133.902 1.01733 135.803 1.01733 137.75 1.24552C136.528 2.29516 135.305 3.3448 134.083 4.3488C131.956 6.17426 129.783 7.99972 127.611 9.73391C127.022 10.1903 126.977 10.6466 127.158 11.2855C128.561 16.5794 129.919 21.8732 131.322 27.2583Z" fill="#E0E0E0"/>
    <path class="shadow"   d="M144.675 1.07655C148.567 1.07655 152.324 1.07655 156.126 1.07655C158.027 1.07655 159.974 1.12219 161.875 1.07655C162.508 1.07655 162.871 1.21346 163.142 1.80674C163.957 3.49529 164.817 5.1382 165.586 6.82675C165.722 7.14621 165.677 7.60257 165.677 7.96767C165.677 8.24148 165.496 8.46967 165.496 8.74348C165.134 12.2575 163.052 14.6762 160.653 17.0493C158.616 19.0573 156.624 19.9701 153.818 19.6962C151.238 19.4681 148.658 19.6506 145.987 19.6506C145.399 21.9324 144.765 24.123 144.222 26.3592C144.041 27.0894 143.724 27.3175 143 27.3175C141.28 27.2719 139.56 27.3175 137.75 27.3175C140.058 18.4641 142.366 9.83876 144.675 1.07655ZM147.3 14.7675C149.02 14.7675 150.65 14.585 152.234 14.8131C154.678 15.1326 156.624 14.4024 157.937 12.3488C158.571 11.3448 159.068 10.0669 159.114 8.92603C159.159 8.0133 158.344 7.10057 157.846 6.23348C157.801 6.09657 157.484 6.09657 157.303 6.09657C154.95 6.09657 152.596 6.09657 150.242 6.09657C149.971 6.09657 149.563 6.37039 149.518 6.55293C148.748 9.19985 148.07 11.938 147.3 14.7675Z" fill="#E0E0E0"/>
    <path class="shadow"   d="M178.901 1.07655C177.045 8.05894 175.235 14.9044 173.379 21.8868C177.271 21.8868 181.074 21.8868 185.011 21.8868C184.514 23.6666 184.106 25.3095 183.608 26.9525C183.563 27.135 183.201 27.2719 182.975 27.3175C177.317 27.3175 171.704 27.3175 166.046 27.3175C165.956 27.3175 165.865 27.2719 165.684 27.1806C166.182 25.3095 166.68 23.3928 167.178 21.5217C168.853 15.1326 170.618 8.74348 172.247 2.35437C172.519 1.35037 172.926 1.07655 173.877 1.12219C175.506 1.12219 177.09 1.07655 178.901 1.07655Z" fill="#E0E0E0"/>
    <path class="shadow"   d="M190.292 1.09938C192.283 1.09938 194.275 1.09938 196.402 1.09938C196.221 1.92083 196.04 2.69665 195.814 3.47247C194.501 8.40122 193.188 13.2843 191.876 18.2131C191.695 18.9433 192.464 21.4076 193.053 21.8184C193.234 21.9553 193.505 21.9096 193.732 21.9096C194.999 21.9096 196.221 21.9553 197.488 21.9096C198.258 21.864 199.254 22.0009 199.706 21.5902C200.883 20.4949 202.332 19.6734 202.784 17.848C204.097 12.5085 205.591 7.21467 206.994 1.92084C207.175 1.23629 207.446 1.05374 208.08 1.05374C209.8 1.09938 211.52 1.05374 213.376 1.05374C213.195 1.69265 213.104 2.24029 212.923 2.78793C211.384 8.6294 209.845 14.4252 208.261 20.2667C208.125 20.7231 207.854 21.2251 207.492 21.5445C205.681 23.3244 203.825 25.0585 201.924 26.7471C201.607 27.0666 201.064 27.2947 200.612 27.2947C196.764 27.3404 192.917 27.2947 189.069 27.3404C188.436 27.3404 188.074 27.1578 187.802 26.5646C186.987 24.876 186.082 23.2787 185.222 21.5902C185.086 21.2707 184.951 20.86 185.041 20.5405C186.716 14.1971 188.391 7.85358 190.111 1.46447C190.156 1.32756 190.246 1.23629 190.292 1.09938Z" fill="#E0E0E0"/>
    <path class="shadow"   d="M213.375 25.5351C213.919 23.5727 214.371 21.7473 214.914 19.8762C216.318 20.3782 217.63 20.9258 218.988 21.3366C220.12 21.656 221.342 21.8842 222.519 21.9755C223.786 22.0667 225.099 21.8842 226.366 22.0211C228.629 22.2036 229.58 20.4238 229.715 18.6896C229.761 18.0051 229.353 17.2749 228.448 17.138C226.14 16.7273 223.831 16.1796 221.478 15.8145C220.391 15.632 219.758 15.0843 218.988 14.3085C217.404 12.7569 217.359 11.2052 217.857 9.06033C218.581 6.00268 219.848 3.62959 222.473 1.94104C223.288 1.3934 224.012 0.982669 225.008 1.02831C228.222 1.07394 231.435 1.07394 234.604 1.02831C236.505 0.982669 237.818 2.30613 239.402 2.99067C239.492 3.03631 239.583 3.4014 239.538 3.58395C239.13 5.22686 238.678 6.86978 238.225 8.60396C236.912 7.91942 235.69 7.28051 234.468 6.68723C234.106 6.50469 233.653 6.36778 233.246 6.36778C230.892 6.32214 228.539 6.32214 226.14 6.36778C225.099 6.36778 224.42 7.09796 224.058 7.87378C223.695 8.60397 223.288 9.56233 224.193 10.2925C224.555 10.5663 225.008 10.7489 225.415 10.8402C228.041 11.3422 230.666 11.7985 233.246 12.3462C233.653 12.4374 234.061 12.7569 234.378 13.0763C236.279 14.9474 236.505 17.0467 235.69 19.648C234.921 22.2493 233.925 24.3942 231.617 25.9458C230.349 26.8129 229.172 27.4518 227.588 27.3606C225.144 27.2237 222.654 27.2237 220.21 27.3606C217.721 27.4062 215.684 26.174 213.375 25.5351Z" fill="#E0E0E0"/>
  </svg>
  <svg class="logo-drinkplus" v-if="theme === 'dark'" :width="width" :height="height" viewBox="0 0 240 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="shadow"   d="M2.89489 27.2947C3.84542 23.7351 4.75068 20.2667 5.74647 16.6158C3.93595 16.6158 2.26121 16.6158 0.450684 16.6158C0.903315 14.9272 1.26542 13.4212 1.71805 11.8696C1.76332 11.6414 2.17068 11.4589 2.44226 11.4132C3.70963 11.3676 5.02226 11.3676 6.28963 11.4132C6.92332 11.4589 7.24016 11.2307 7.37595 10.5918C8.10016 7.71667 8.91489 4.84157 9.6391 1.92084C9.77489 1.32756 10.0012 1.05374 10.6802 1.05374C15.8854 1.09938 21.0907 1.09938 26.2507 1.05374C26.8391 1.05374 27.1559 1.23629 27.3823 1.78393C28.3781 3.92884 29.5096 6.02812 30.5054 8.17304C30.6865 8.58377 30.777 9.17704 30.6865 9.63341C29.917 12.7367 29.057 15.84 28.197 18.9433C28.1065 19.3083 27.9254 19.7191 27.6538 19.9929C25.3002 22.3204 22.9465 24.6478 20.5475 26.9296C20.3212 27.1578 19.9138 27.3404 19.597 27.3404C14.1202 27.3404 8.64332 27.3404 3.21174 27.3404C3.12121 27.3404 3.07595 27.3404 2.89489 27.2947ZM10.5444 22.0465C12.4002 22.0465 14.1654 21.9553 15.8854 22.0922C17.5602 22.2291 18.8728 21.9096 19.9138 20.4949C20.4117 19.8103 21.1359 19.3083 21.6791 18.6694C21.9054 18.3956 22.1317 18.0762 22.2223 17.7567C22.8559 15.4749 23.4896 13.1931 24.0328 10.8656C24.1233 10.5005 24.1233 10.0441 23.9875 9.72468C23.5802 8.76631 23.1275 7.80795 22.6296 6.89521C22.4938 6.66703 22.1317 6.43885 21.8602 6.43885C19.6875 6.39321 17.4696 6.39321 15.297 6.43885C15.0254 6.43885 14.6181 6.66703 14.5728 6.89521C14.1202 8.35558 13.7581 9.86159 13.3507 11.5045C14.7991 11.5045 16.1117 11.5045 17.5149 11.5045C17.1075 13.1474 16.7454 14.6991 16.2928 16.2051C16.2475 16.342 15.7949 16.5245 15.5233 16.5702C14.5275 16.6158 13.577 16.5702 12.5812 16.6158C12.3096 16.6158 11.9475 16.8896 11.857 17.0722C11.4044 18.7151 10.997 20.3123 10.5444 22.0465Z" fill="#1B3B3D"/>
    <path class="shadow"   d="M54.3043 27.2695C52.1316 27.2695 50.0495 27.2695 47.8769 27.2695C47.2432 24.3032 46.6548 21.3368 46.0211 18.3704C43.8032 18.3704 41.6306 18.3704 39.3674 18.3704C38.6885 20.9261 37.919 23.4817 37.3306 26.083C37.1043 27.1326 36.6516 27.4065 35.6559 27.3608C34.0716 27.2695 32.4874 27.3152 30.7222 27.3152C33.0759 18.5073 35.3843 9.8364 37.738 1.02855C38.1001 1.02855 38.4169 1.02855 38.7337 1.02855C44.1201 1.02855 49.4611 1.02855 54.8474 1.02855C55.6169 1.02855 56.0243 1.25673 56.2959 1.94128C56.658 2.76274 57.0201 3.58419 57.4727 4.31438C58.378 5.68347 58.3327 7.00693 58.0159 8.60421C57.3822 11.6619 56.2053 14.2631 53.5348 15.9517C52.1769 16.8188 52.0864 17.9141 52.4938 19.3744C53.1727 21.9301 53.6706 24.577 54.3043 27.2695ZM40.5895 13.7155C42.2643 13.7155 43.758 13.5786 45.2064 13.7611C47.4695 13.9893 49.1895 13.396 50.6832 11.6162C51.8148 10.2471 51.9506 7.37202 50.638 6.18547C50.5022 6.04857 50.2759 6.00293 50.0948 6.00293C47.7864 6.00293 45.5232 6.00293 43.2148 6.00293C42.9885 6.00293 42.5811 6.23111 42.5359 6.41366C41.8569 8.74112 41.2685 11.1599 40.5895 13.7155Z" fill="#1B3B3D"/>
    <path class="shadow"   d="M77.7759 1.07655C77.459 2.2631 77.2327 3.35838 76.9159 4.36238C76.8253 4.59056 76.418 4.91002 76.1464 4.91002C75.3316 5.00129 74.4716 4.95566 73.5664 4.95566C71.9369 11.1166 70.3074 17.2319 68.6327 23.4841C69.6737 23.4841 70.6243 23.4841 71.8011 23.4841C71.439 24.8532 71.1674 26.0854 70.8053 27.3175C66.5959 27.3175 62.4769 27.3175 58.2222 27.3175C58.539 26.131 58.8106 24.9901 59.1727 23.8492C59.218 23.7123 59.4443 23.621 59.6253 23.5297C59.7611 23.4841 59.8969 23.5297 60.0327 23.5297C62.4316 23.4841 62.4316 23.4841 63.0653 21.1566C64.4232 16.0453 65.7811 10.934 67.139 5.82275C67.1843 5.59457 67.2295 5.36638 67.2748 5.00129C66.2337 5.00129 65.2832 5.00129 64.1969 5.00129C64.5137 3.72347 64.7853 2.58256 65.1474 1.48728C65.1927 1.30474 65.5548 1.12219 65.7359 1.12219C69.6737 1.07655 73.6569 1.07655 77.7759 1.07655Z" fill="#1B3B3D"/>
    <path class="shadow"   d="M107.74 1.09607C105.386 9.90391 103.078 18.6205 100.724 27.3827C99.0496 27.3827 97.3748 27.4283 95.7454 27.3371C95.4738 27.3371 95.1117 26.8807 94.9759 26.5612C92.9843 21.7238 90.9927 16.8407 89.0464 11.9576C88.8653 11.4556 88.639 10.9536 88.3222 10.269C87.5075 13.2354 86.7832 16.0192 86.059 18.803C85.3801 21.4043 84.6559 23.96 84.0222 26.5612C83.8864 27.1545 83.6148 27.3827 82.9811 27.3371C81.3064 27.2914 79.6317 27.3371 77.7759 27.3371C78.319 25.1921 78.8622 23.1841 79.4053 21.1761C81.0801 14.8327 82.8001 8.48918 84.4748 2.10007C84.7011 1.23298 85.0632 0.959158 85.9232 1.00479C87.3264 1.05043 88.6843 1.09607 90.0875 1.00479C90.9475 0.959158 91.2643 1.27861 91.5359 2.0088C93.3011 6.66372 95.0664 11.3186 96.8769 15.9736C97.0127 16.293 97.1485 16.5668 97.3748 17.0688C98.2348 13.783 99.0948 10.6797 99.9096 7.57645C100.453 5.61408 100.951 3.65171 101.494 1.68934C101.584 1.41552 101.946 1.05043 102.173 1.05043C103.938 1.05043 105.794 1.09607 107.74 1.09607Z" fill="#1B3B3D"/>
    <path class="shadow"   d="M131.322 27.2583C129.104 27.2583 127.022 27.2583 124.895 27.2583C123.854 23.151 122.813 19.0438 121.727 14.7539C120.912 15.4385 120.233 16.123 119.418 16.6707C116.883 18.3136 115.39 20.5041 114.982 23.5618C114.892 24.3832 114.484 25.2047 114.349 26.0261C114.213 27.1671 113.67 27.3952 112.583 27.3496C110.999 27.2583 109.415 27.304 107.74 27.304C110.094 18.4505 112.402 9.77954 114.711 1.06297C116.793 1.06297 118.83 1.06297 120.957 1.06297C120.007 4.75953 119.011 8.36481 118.06 12.0157C118.105 12.0614 118.196 12.0614 118.241 12.107C120.504 10.1903 122.768 8.27354 125.031 6.35681C126.932 4.75953 128.788 3.11661 130.689 1.56497C131.051 1.29115 131.594 1.06297 132.047 1.06297C133.902 1.01733 135.803 1.01733 137.75 1.24552C136.528 2.29516 135.305 3.3448 134.083 4.3488C131.956 6.17426 129.783 7.99972 127.611 9.73391C127.022 10.1903 126.977 10.6466 127.158 11.2855C128.561 16.5794 129.919 21.8732 131.322 27.2583Z" fill="#1B3B3D"/>
    <path class="shadow"   d="M144.675 1.07655C148.567 1.07655 152.324 1.07655 156.126 1.07655C158.027 1.07655 159.974 1.12219 161.875 1.07655C162.508 1.07655 162.871 1.21346 163.142 1.80674C163.957 3.49529 164.817 5.1382 165.586 6.82675C165.722 7.14621 165.677 7.60257 165.677 7.96767C165.677 8.24148 165.496 8.46967 165.496 8.74348C165.134 12.2575 163.052 14.6762 160.653 17.0493C158.616 19.0573 156.624 19.9701 153.818 19.6962C151.238 19.4681 148.658 19.6506 145.987 19.6506C145.399 21.9324 144.765 24.123 144.222 26.3592C144.041 27.0894 143.724 27.3175 143 27.3175C141.28 27.2719 139.56 27.3175 137.75 27.3175C140.058 18.4641 142.366 9.83876 144.675 1.07655ZM147.3 14.7675C149.02 14.7675 150.65 14.585 152.234 14.8131C154.678 15.1326 156.624 14.4024 157.937 12.3488C158.571 11.3448 159.068 10.0669 159.114 8.92603C159.159 8.0133 158.344 7.10057 157.846 6.23348C157.801 6.09657 157.484 6.09657 157.303 6.09657C154.95 6.09657 152.596 6.09657 150.242 6.09657C149.971 6.09657 149.563 6.37039 149.518 6.55293C148.748 9.19985 148.07 11.938 147.3 14.7675Z" fill="#1B3B3D"/>
    <path class="shadow"   d="M178.901 1.07655C177.045 8.05894 175.235 14.9044 173.379 21.8868C177.271 21.8868 181.074 21.8868 185.011 21.8868C184.514 23.6666 184.106 25.3095 183.608 26.9525C183.563 27.135 183.201 27.2719 182.975 27.3175C177.317 27.3175 171.704 27.3175 166.046 27.3175C165.956 27.3175 165.865 27.2719 165.684 27.1806C166.182 25.3095 166.68 23.3928 167.178 21.5217C168.853 15.1326 170.618 8.74348 172.247 2.35437C172.519 1.35037 172.926 1.07655 173.877 1.12219C175.506 1.12219 177.09 1.07655 178.901 1.07655Z" fill="#1B3B3D"/>
    <path class="shadow"   d="M190.292 1.09938C192.283 1.09938 194.275 1.09938 196.402 1.09938C196.221 1.92083 196.04 2.69665 195.814 3.47247C194.501 8.40122 193.188 13.2843 191.876 18.2131C191.695 18.9433 192.464 21.4076 193.053 21.8184C193.234 21.9553 193.505 21.9096 193.732 21.9096C194.999 21.9096 196.221 21.9553 197.488 21.9096C198.258 21.864 199.254 22.0009 199.706 21.5902C200.883 20.4949 202.332 19.6734 202.784 17.848C204.097 12.5085 205.591 7.21467 206.994 1.92084C207.175 1.23629 207.446 1.05374 208.08 1.05374C209.8 1.09938 211.52 1.05374 213.376 1.05374C213.195 1.69265 213.104 2.24029 212.923 2.78793C211.384 8.6294 209.845 14.4252 208.261 20.2667C208.125 20.7231 207.854 21.2251 207.492 21.5445C205.681 23.3244 203.825 25.0585 201.924 26.7471C201.607 27.0666 201.064 27.2947 200.612 27.2947C196.764 27.3404 192.917 27.2947 189.069 27.3404C188.436 27.3404 188.074 27.1578 187.802 26.5646C186.987 24.876 186.082 23.2787 185.222 21.5902C185.086 21.2707 184.951 20.86 185.041 20.5405C186.716 14.1971 188.391 7.85358 190.111 1.46447C190.156 1.32756 190.246 1.23629 190.292 1.09938Z" fill="#1B3B3D"/>
    <path class="shadow"   d="M213.375 25.5351C213.919 23.5727 214.371 21.7473 214.914 19.8762C216.318 20.3782 217.63 20.9258 218.988 21.3366C220.12 21.656 221.342 21.8842 222.519 21.9755C223.786 22.0667 225.099 21.8842 226.366 22.0211C228.629 22.2036 229.58 20.4238 229.715 18.6896C229.761 18.0051 229.353 17.2749 228.448 17.138C226.14 16.7273 223.831 16.1796 221.478 15.8145C220.391 15.632 219.758 15.0843 218.988 14.3085C217.404 12.7569 217.359 11.2052 217.857 9.06033C218.581 6.00268 219.848 3.62959 222.473 1.94104C223.288 1.3934 224.012 0.982669 225.008 1.02831C228.222 1.07394 231.435 1.07394 234.604 1.02831C236.505 0.982669 237.818 2.30613 239.402 2.99067C239.492 3.03631 239.583 3.4014 239.538 3.58395C239.13 5.22686 238.678 6.86978 238.225 8.60396C236.912 7.91942 235.69 7.28051 234.468 6.68723C234.106 6.50469 233.653 6.36778 233.246 6.36778C230.892 6.32214 228.539 6.32214 226.14 6.36778C225.099 6.36778 224.42 7.09796 224.058 7.87378C223.695 8.60397 223.288 9.56233 224.193 10.2925C224.555 10.5663 225.008 10.7489 225.415 10.8402C228.041 11.3422 230.666 11.7985 233.246 12.3462C233.653 12.4374 234.061 12.7569 234.378 13.0763C236.279 14.9474 236.505 17.0467 235.69 19.648C234.921 22.2493 233.925 24.3942 231.617 25.9458C230.349 26.8129 229.172 27.4518 227.588 27.3606C225.144 27.2237 222.654 27.2237 220.21 27.3606C217.721 27.4062 215.684 26.174 213.375 25.5351Z" fill="#1B3B3D"/>
  </svg>

</template>

