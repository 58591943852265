import * as THREE from "three";
import {GLTFLoader, RGBELoader} from "three/addons";
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader'
import {GUI} from 'lil-gui'

export class SceneManager {

    model = null;
    model2 = null;

    container = null;
    camera = null;
    scene = null;
    renderer = null;
    models = {};

    lights = {}
    gui = null;
    showStudio = false;

    constructor(container, showStudio = false) {
        this.showStudio = showStudio;
        if (showStudio) {
            this.gui = new GUI();
        }

        this.container = container;
        this.buildRender();
        this.buildCamera();
        this.buildScene();
        this.createSceneSubjects();
    }

    buildScene() {
        this.scene = new THREE.Scene();
    }

    async buildRender() {
        this.renderer = new THREE.WebGLRenderer({antialias: true, canvas: this.container});
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 1;
    }

    async buildCamera() {
        const aspect = window.innerWidth / window.innerHeight;
        this.camera = new THREE.PerspectiveCamera(45, aspect, 0.25, 9000);
    }

    async createSceneSubjects() {
        let vm = this;
        const manager = new THREE.LoadingManager();

        manager.onLoad = function () {
            setTimeout(()=>{
                window.dispatchEvent(new CustomEvent("allLoadersProgress", {
                    detail: {progress: () => 100},
                }));
            }, 200)
        };

        const lights = [
            //Checkpoint 1: TatraTea
            {x: -6.9, y: 13.4, z: 1.4},
            {x: -7.6, y: 13.9, z: 1},
            {x: -6.6, y: 13.7, z: 1.2},

            //  Checkpoint 2: OnLemon
            {x: -7.8, y: 8.4, z: 1.7},
            {x: -8.3, y: 8.3, z: 1.8},

            //  Checkpoint 3: Gin 1689
            {x: -5.2, y: 3.7, z: 1.6},
            {x: -5, y: 3.1, z: 1.5},

            //  Checkpoint 4: Berkshire
            {x: -2.5, y: 8.4, z: 2.1},
            {x: -1.9, y: 8.7, z: 1.9},

            //  Checkpoint 5: Mates
            {x: -1.4, y: 14.5, z: 1},
            {x: -1.6, y: 15.2, z: 1.3},

            //  Checkpoint 6: LaLa
            {x: 5.6, y: 13.6, z: 1.5},
            {x: 6.4, y: 14.1, z: 1.5},

            //  Checkpoint 7: San Cosme
            {x: 8.9, y: 5, z: 1.1},
            {x: 9.4, y: 5, z: 1},

            //  Checkpoint 8: San Cosme
            {x: 2.7, y: 2.2, z: 1.1},
            {x: 2.9, y: 2.16, z: 1.1},

            //  Checkpoint 9: Xiaman
            {x: 10.6, y: -0.5, z: 1.1},
            {x: 11.1, y: -0.4, z: 1},

            //  Checkpoint 10: Samuel Gelston’s
            {x: 3.8, y: -3.3, z: 1.4},
            {x: 3.9, y: -3, z: 1},

            //  Checkpoint 11: Vodka Chechoslovakia
            {x: -2.6, y: -4.6, z: 1},
            {x: -2.1, y: -4.2, z: 1.1},

            //  Checkpoint 12: Bandoeng
            {x: -8.3, y: -6.4, z: 1.2},
            {x: -8.8, y: -6.1, z: 1},
        ];

        lights.forEach((item) => {
            vm.scene.add(vm.addLight(item));
        })

        const light = new THREE.HemisphereLight( 0xffffff, 0xffffff, 5);
        vm.scene.add( light );

        new RGBELoader(manager)
            .setPath('/static/')
            .load('pure-sky.hdr',
                function (texture) {
                    texture.mapping = THREE.EquirectangularReflectionMapping;
                    vm.scene.background = texture;
                    vm.scene.environment = texture;
                    vm.update();

                    const draco = new DRACOLoader(manager)
                    draco.setDecoderPath('/static/js/libs/draco/')

                    const loader = new GLTFLoader(manager)
                    loader.setDRACOLoader(draco)
                    loader.load(
                        '/static/map.glb',
                        async function (gltf) {
                            const model = gltf.scene;
                            // wait until the model can be added to the scene without blocking due to shader compilation
                            await vm.renderer.compileAsync(model, vm.camera, vm.scene);
                            vm.scene.add(model);
                            vm.models.map = model;
                            // vm.models.map.position.set(0,0,0);

                            vm.update();
                        },
                        (xhr) => {
                            const progress = (xhr.loaded / 609818800) * 10000;
                            window.dispatchEvent(new CustomEvent("mapDownloadProgress", {
                                detail: {progress: () => progress},
                            }));
                            console.log(progress + '% loaded map')
                        },
                        (error) => {
                            console.log(error)
                        }
                    )
                },
                function (xhr) {
                    const progress = (xhr.loaded / 1085114) * 100;
                    window.dispatchEvent(new CustomEvent("sphereDownloadProgress", {
                        detail: {progress: () => progress},
                    }));
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    addLight(position) {
        const spotLight = new THREE.PointLight(0xffffff);
        spotLight.position.set(position.x, position.y, position.z);
        spotLight.castShadow = true;
        return spotLight;
    }

    update() {
        this.renderer.render(this.scene, this.camera);
    }
}