<template>
  <div class="cocktails-container d-flex justify-content-center">
    <div class="cocktails-wrapper d-flex ">
      <div class="our-story-left-wrapper col-md-5 d-none d-md-block">
        <div class="our-story-title">Our Story</div>
        <div class="our-story-nav d-flex">
          <a href="#the-beginning">
            <CocktailsFilterButton :button-text="'The beginning'"/>
          </a>
          <a href="#our-tireless-spirit">
            <CocktailsFilterButton :button-text="'Our tireless spirit'"/>
          </a>
          <a href="#pursuit-of-quality">
            <CocktailsFilterButton :button-text="'Pursuit of quality'"/>
          </a>
          <a href="#the-philosophy">
            <CocktailsFilterButton :button-text="'The philosophy'"/>
          </a>
        </div>
      </div>
      <div class="scrollable-content col-md-7 col-12">
        <div>
          <div class="our-story-title d-md-none">Our Story</div>
          <div class="our-story-subtitle" id="the-beginning">The beginning</div>
          <div class="our-story-paragraph">At the very beginning, we at DRINKPLUS focused on unwavering commitment to product quality, professionalism, loyalty, and understanding with our partners, and these remained our top priorities</div>
        </div>
        <div>
          <div class="our-story-subtitle" id="our-tireless-spirit">Our tireless spirit</div>
          <div class="our-story-paragraph">In 2018, when we established the alcoholic beverages division of Absolut Plus Ltd with the introduction of the TATRATEA brand, we were aware that the challenges ahead would not be easy. Thanks to the tireless spirit of our team and the support of our brand-believing partners, bartenders, and friends, the division managed to establish itself in the market as a strong competitor.</div>
        </div>
        <div>
          <div class="our-story-subtitle" id="pursuit-of-quality">Pursuit of quality</div>
          <div class="our-story-paragraph">With the expansion of our distribution channels and the establishment of numerous partnerships nationwide, expanding our portfolio with new brands was inevitable. The selection of brands like Tatratea, Berkshire, Gin 1689, Padre Azul, LALA Tequila, Mates, San Cosme is the result of our pursuit of quality alcoholic beverages that carry rich history and spirit.</div>
        </div>
        <div>
          <div class="our-story-subtitle" id="the-philosophy">The philosophy</div>
          <div class="our-story-paragraph">The philosophy of the team, composed of professionals with extensive experience in the industry, is that success is built on trust and mutual respect. We strive to build lasting relationships with our partners based on loyalty, transparency, and open communication. We are motivated, ambitious, and tirelessly work to offer our customers the best.</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script >
import CocktailsFilterButton from "@/components/Common/Buttons/CocktailsFilterButton";


export default {
  name: "AllCocktailsView",
  components: {
    CocktailsFilterButton,
  },
  data() {
    return {

    };
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

@import "~@/assets/scss/fonts.scss";

.cocktails-container {
  background: #FFFFFF;
  padding-top: 100px;
  height: 100vh;
}
.cocktails-wrapper {
  background: #F2F2E4;
  width: 90%;
  border-radius: 20px;
  margin-bottom: 20px;
  padding-right: 15px;
  overflow: hidden;
  .our-story-left-wrapper {
    padding: 50px;
    border-right: 1px solid #DADAB7;
    .our-story-title {
      font-family: $font-bold;
      font-size: 160px;
      line-height: 120px;
      color: #FFFFFF;
    }
    .our-story-nav {
      position: fixed;
      bottom: 7%;
      left: 7%;
    }
    a {
      text-decoration: none;
      margin: 0 5px;
    }
  }
  .scrollable-content {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    color: #515146;
    .our-story-title {
      font-family: $font-bold;
      font-size: 110px;
      line-height: 90px;
      color: #FFFFFF;
      margin-bottom: 50px;
      padding-left: 30px;
      z-index: 1;
    }
    .our-story-subtitle {
      font-size: 20px;
      padding: 0 30px;
      z-index: 2;
      position: relative;
    }
    .our-story-paragraph {
      font-family: $font-bold;
      font-size: 35px;
      line-height: 45px;
      padding: 20px 10px 50px 30px ;
      width: 100%;
      z-index: 2;
      position: relative;
    }

  }
}

.scrollable-content::-webkit-scrollbar {
  width: 4px; /* Set the width of the scrollbar */

}
.scrollable-content::-webkit-scrollbar-track {
  background: transparent;
}
.scrollable-content::-webkit-scrollbar-thumb {
  background: #1b3b3d;
}

@media only screen and (min-width: 768px) {
  .cocktails-container {
    height: 100vh;
  }
.cocktails-wrapper {

  .cocktails-nav {
    .nav-filters {
      margin-top: 0;
    }
  }
  .scrollable-content {
    padding-top: 10px;
    width: 58%;
    .our-story-paragraph {
      font-size: 50px;
      padding: 20px 40px 50px ;
      line-height: 45px;
    }
    .our-story-subtitle {
      padding: 0 40px;
    }
  }
}

}
</style>
