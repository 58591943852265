import {getProject} from '@theatre/core'
import state from "@/assets/animations/state.json";
// import studio from "@theatre/studio";

export class Studio {

    sceneManager = null;
    sheets = {};

    constructor(sceneManager) {
        this.sceneManager = sceneManager;

        this.createAnimationSheet('idleToCheckpoint1', 'Idle->Checkpoint 1');
        this.createAnimationSheet('checkpoint1Scroll', 'Checkpoint 1 - Scroll');
        this.createAnimationSheet('checkpoint1toNext', 'Checkpoint 1 to 2');

        this.createAnimationSheet('idleToCheckpoint2', 'Idle->Checkpoint 2');
        this.createAnimationSheet('checkpoint2Scroll', 'Checkpoint 2 - Scroll');
        this.createAnimationSheet('checkpoint2toNext', 'Checkpoint 2 to 3');

        this.createAnimationSheet('idleToCheckpoint3', 'Idle->Checkpoint 3');
        this.createAnimationSheet('checkpoint3Scroll', 'Checkpoint 3 - Scroll');
        this.createAnimationSheet('checkpoint3toNext', 'Checkpoint 3 to 4');

        this.createAnimationSheet('idleToCheckpoint4', 'Idle->Checkpoint 4');
        this.createAnimationSheet('checkpoint4Scroll', 'Checkpoint 4 - Scroll');
        this.createAnimationSheet('checkpoint4toNext', 'Checkpoint 4 to 5');

        this.createAnimationSheet('idleToCheckpoint5', 'Idle->Checkpoint 5');
        this.createAnimationSheet('checkpoint5Scroll', 'Checkpoint 5 - Scroll');
        this.createAnimationSheet('checkpoint5toNext', 'Checkpoint 5 to 6');

        this.createAnimationSheet('idleToCheckpoint6', 'Idle->Checkpoint 6');
        this.createAnimationSheet('checkpoint6Scroll', 'Checkpoint 6 - Scroll');
        this.createAnimationSheet('checkpoint6toNext', 'Checkpoint 6 to 7');

        this.createAnimationSheet('idleToCheckpoint7', 'Idle->Checkpoint 7');
        this.createAnimationSheet('checkpoint7Scroll', 'Checkpoint 7 - Scroll');
        this.createAnimationSheet('checkpoint7toNext', 'Checkpoint 7 to 8');

        this.createAnimationSheet('idleToCheckpoint8', 'Idle->Checkpoint 8');
        this.createAnimationSheet('checkpoint8Scroll', 'Checkpoint 8 - Scroll');
        this.createAnimationSheet('checkpoint8toNext', 'Checkpoint 8 to 9');

        this.createAnimationSheet('idleToCheckpoint9', 'Idle->Checkpoint 9');
        this.createAnimationSheet('checkpoint9Scroll', 'Checkpoint 9 - Scroll');
        this.createAnimationSheet('checkpoint9toNext', 'Checkpoint 9 to 10');

        this.createAnimationSheet('idleToCheckpoint10', 'Idle->Checkpoint 10');
        this.createAnimationSheet('checkpoint10Scroll', 'Checkpoint 10 - Scroll');
        this.createAnimationSheet('checkpoint10toNext', 'Checkpoint 10 to 11');

        this.createAnimationSheet('idleToCheckpoint11', 'Idle->Checkpoint 11');
        this.createAnimationSheet('checkpoint11Scroll', 'Checkpoint 11 - Scroll');
        this.createAnimationSheet('checkpoint11toNext', 'Checkpoint 11 to 12');

        this.createAnimationSheet('checkpoint12Scroll', 'Checkpoint 12 - Scroll')
        this.createAnimationSheet('idleToCheckpoint12', 'Idle->Checkpoint 12');

        // studio.initialize()
    }

    getSheet(name) {
        return this.sheets[name];
    }

    createAnimationSheet(name, title) {
        let vm = this;
        const stateProject = getProject('DrinkPlus', {state});
        const sheet = stateProject.sheet(title);
        sheet.object("Camera", {position: {x: 0, y: 0, z: 0}, rotation: {UpDown: 0, LeftRight: 0}})
            .onValuesChange((v)=>{
                vm.onValuesChangeCamera(v)
            });

        sheet.object("Map Object", {rotation: {x: 0, y: 0}, position: {x: 0, y: 0}})
            .onValuesChange((v)=>{
                vm.onValuesChangeObject(v)
            });

        this.sheets[name] = sheet
    }


    onValuesChangeCamera(v) {
        if (this.sceneManager.camera) {

            this.sceneManager.camera.position.x = v.position.x;
            this.sceneManager.camera.position.y = v.position.y;
            this.sceneManager.camera.position.z = v.position.z;

            this.sceneManager.camera.rotation.x = v.rotation.UpDown;
            this.sceneManager.camera.rotation.y = v.rotation.LeftRight;

            this.sceneManager.update();
        }
    }

    onValuesChangeObject(v) {
        if (this.sceneManager.models.map) {
            this.sceneManager.models.map.rotation.x = v.rotation.x;
            this.sceneManager.models.map.rotation.y = v.rotation.y;

            this.sceneManager.models.map.position.x = v.position.x;
            this.sceneManager.models.map.position.y = v.position.y;

            this.sceneManager.update();
        }
    }
}