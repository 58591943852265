<template>
  <section class="about-section">

    <div :style="{ backgroundImage: 'url(' + imageUrl + ')' }" class="about-image" v-if="imageUrl"></div>
    <h2 class="about-title" v-if="title">{{ title }}</h2>
    <div class="about-description">
      <p v-for="(paragraph, key) in paragraphs" v-bind:key="key"> {{ paragraph }} </p>
    </div>

  </section>
</template>

<script>
export default {
  name: 'OneProductAboutComponent',
  props: {
    title: {
      type: String,
    },
    imageUrl: {
      type: String,
    },
    body: {
      type: String,
    },
    paragraphs: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.about-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  color: #fff;
  background-color: #1b3b3d;

  .about-title {
    margin-top: 30px;
    margin-bottom: 0;
    font-family: $font-black;
    font-size: 50px;
    text-align: left;
    justify-content: left;
    display: flex;
    text-transform: uppercase;
  }

  .about-image {
    border-radius: 20px;
    background-size: cover;
    background-position: top;
    padding-top: 100%;
    background-repeat: no-repeat;
    margin-bottom: 10px;
  }

  .about-description {
    margin-top: 30px;
    font-family: $font-regular;
    font-size: 20px;
  }
}


@media (max-width: 991px) {
  .about-section {
    padding: 20px;

    .about-title {
      font-size: 30px;
    }

    .about-description {
      margin-top: 20px;
    }
  }


}

@media (min-width: 768px) {
  .about-section {
    border-radius: 30px;
  }
}
</style>
