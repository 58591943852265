<template>
  <div class="cocktails-filter-wrapper" v-if="filterType === 'brand'">
    <div class="close-button" @click="closeModal">
      <xCloseIcon/>
    </div>
    <div class="filter-by-brand" v-if="brands">
      <div class="d-none d-md-flex justify-content-center">
        <div class="button-filter" v-for="(text, index) in brandsRow1" :key="'filterByBrandRow1_' + index">
          <CocktailsFilterButton :selected-brands="selectedBrands" :button-text="text" @button-clicked="setActiveButton"/>
        </div>
      </div>
      <div class="d-none d-md-flex justify-content-center">
        <div class="button-filter" v-for="(text, index) in brandsRow2" :key="'filterByBrandRow2_' + index">
          <CocktailsFilterButton :button-text="text" @button-clicked="setActiveButton"/>
        </div>
      </div>
      <div class="mobile-carousel d-md-none">
        <Carousel v-bind="settings" :breakpoints="breakpoints">
          <Slide v-for="(text, index) in brands" :key="'slide_' + index">
            <div class="carousel__item">
              <CocktailsFilterButton :button-text="text" @button-clicked="setActiveButton"/>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>

  <div class="cocktails-filter-wrapper" v-if="filterType === 'type'">
    <div class="close-button" @click="closeModal">
      <xCloseIcon/>
    </div>
    <div class="filter-by-type d-flex justify-content-center">
      <div class="button-filter d-none d-md-block" v-for="(text, index) in filterByType" :key="'filterByType_' + index">
        <CocktailsFilterButton :button-text="text" @button-clicked="setActiveButton"/>
      </div>
      <div class="mobile-carousel d-md-none">
        <Carousel v-bind="settings" :breakpoints="breakpoints">
          <Slide v-for="(text, index) in filterByType" :key="'filterByType_' + index">
            <div class="carousel__item">
              <CocktailsFilterButton :button-text="text" @button-clicked="setActiveButton"/>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import CocktailsFilterButton from "@/components/Common/Buttons/CocktailsFilterButton";
import xCloseIcon from "@/components/Common/Icons/xCloseIcon";
import {defineComponent} from 'vue'
import {Carousel, Slide} from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: "CocktailsFilterModal",
  components: {
    CocktailsFilterButton,
    xCloseIcon,
    Carousel,
    Slide
  },
  props: {
    filterType: {
      type: String
    },
    brands: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    // carousel settings
    filterByType: ['All Cocktails', 'Alcoholic', 'Non-alcoholic'],
    selectedBrands: [],
    brandsRow1: [],
    brandsRow2: [],
    settings: {
      itemsToShow: 3,
      snapAlign: 'start',
    },
  }),
  mounted() {
    if (this.brands) {
      const midpoint = Math.ceil(this.brands.length / 2);
      this.brandsRow1 = this.brands.slice(0, midpoint);
      this.brandsRow2 = this.brands.slice(midpoint);
    }
  },
  methods: {
    closeModal() {
      // Emit event to close the modal
      this.$emit('close-modal');
    },
    setActiveButton(item) {

      ['Non-alcoholic', 'Alcoholic', 'All Cocktails'].includes(item)
      if (item === 'Non-alcoholic') {
        this.$emit('onBrandFilter', 'On Lemon');
        return;
      }
      if (item === 'Alcoholic') {
        this.selectedBrands.filter((item) => {
          return item.subtitle !== 'On Lemon';
        })
        this.$emit('onBrandFilter', this.selectedBrands);
        return;
      }
      if (item === 'All Cocktails') {
        this.selectedBrands = this.brands
        this.$emit('onBrandFilter', this.selectedBrands);
        return;
      }

      if (this.selectedBrands.includes(item)) {
        const index = this.selectedBrands.indexOf(item);
        if (index > -1) {
          this.selectedBrands.splice(index, 1);
        }
      } else {
        this.selectedBrands.push(item);
      }
      if (this.selectedBrands.includes('All Cocktails')) {
        this.selectedBrands = [];
      }
      this.$emit('onBrandFilter', this.selectedBrands);
    }
  },
})
</script>

<style lang="scss">
.cocktails-filter-wrapper {
  background: #E6E6D8;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0px;
  overflow: hidden;

  .button-filter {
    margin: 10px;
  }

  .close-button {
    position: absolute;
    top: 5%;
    right: 2%;
    cursor: pointer;
    z-index: 9998;
  }
}

.carousel__item {
  width: 100% !important;
  min-height: 150px !important;
}

.carousel__slide {
  padding: 0 !important;
}

@media screen and (min-width: 768px) {
  .cocktails-filter-wrapper {
    padding: 30px;
  }
}

</style>