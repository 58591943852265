import axios from "axios";

export default {
    namespaced: true,
    state: {
        categories: [],
        productModal: {
            open: false,
        }
    },
    mutations: {
        SET_PRODUCT_MODAL_INFO(state, payload) {
            state.productModal = payload;
            state.productModal.open = true;
        },
        CLOSE_PRODUCT_MODAL(state) {
            state.productModal.open = false;
        }
    },
    actions: {
        async getProduct({state}, payload) {
            if (!state) {
                return;
            }
            const response = await axios.get('/mock-api/products/' + payload.id + '.json');
            return response.data;
        },
        async openProductModal({commit}, payload) {
            commit('SET_PRODUCT_MODAL_INFO', payload);
        },
        async closeProductModal({commit}) {
            commit('CLOSE_PRODUCT_MODAL');
        },
    }
};
