<template>
  <div class="carousel-modal-wrapper row">
    <div class="close-button" @click="closeModal">
      <ArrowLeftIcon :color="'black'"/>
    </div>
    <div class=" left-column d-flex justify-content-center align-items-center">
      <img :src="imageUrl" alt="Decorative image" class="image"/>
    </div>
    <div class=" right-column  justify-content-center ">

      <div class="modal-title d-block"> {{ title }}</div>
      <div class="scrollable-wrapper">
        <div class="scrollable-content">
          <div class="modal-description"> {{ description }}</div>
        </div>
      </div>


    </div>
  </div>

</template>

<script>
import ArrowLeftIcon from "@/components/Common/Icons/ArrowLeftIcon";

export default {
  name: "OneProductModal",
  components: {
    ArrowLeftIcon
  },
  props: {
    imageUrl: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    }
  }
}
</script>

<style lang="scss" >


.scrollable-wrapper {
  position: relative;
  display: flex;
  max-height: 250px;
}

.scrollable-content {
  overflow-y: scroll;

  .modal-description {
    font-size: 14px;
    padding: 0px 10px;

  }
}

.carousel-modal-wrapper {
  height: 100vh;
  border-radius: 0px;
  position: fixed;


  .close-button {
    top: 5%;
    left: 5%;
    position: absolute;
    cursor: pointer;
  }

  .modal-title {
    font-family: $font-bold;
    font-size: 25px;
    text-align: center;
    margin-bottom: 10px;
  }

  .left-column {
    background: #f0f0e5;
    padding: 50px 50px 0;

    .image {
      max-height: 250px;
    }
  }

  .right-column {
    background: #f0f0e5;
    padding: 50px;
    position: relative;
    overflow: scroll;
  }

}

.scrollable-content::-webkit-scrollbar {
  width: 4px; /* Set the width of the scrollbar */
  padding: 10px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: transparent;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: #1b3b3d;
  margin-right: 10px;
}

@media only screen and (min-width: 768px) {

}

</style>
