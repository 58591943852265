<template>
  <div :style="containerStyle" class="number-container">
    <div
        class="numbers"
        v-for="page in totalPages"
        :key="page"
        :class="{ 'number-highlight': page === currentPage }"
        @click="changePage(page)"
    >
      {{ page }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CocktailsPagination",
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    }
  },
  computed: {
    containerStyle() {
      const widthPerPage = 40; // Adjust based on your design
      const padding = 80; // Adjust based on your design
      const totalWidth = this.totalPages * widthPerPage + padding;
      return {
        width: `${totalWidth}px`,
      };
    }
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.$emit('page-changed', page);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.number-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 224px;
  background-color: #1b3b3d;
  color: #fff;
  text-align: center;
  padding: 15px 15px;
  font-size: 13px;

  .numbers {
    margin: 0 15px;
    cursor: pointer;

    &:hover {
      color: #C0FF6F;
    }
  }
}

.number-highlight {
  font-weight: 700;
  color: rgba(192, 255, 111, 1);
}

@media screen and (min-width: 768px) {
  .number-container {
    .numbers {
      margin: 0 20px;
    }
  }
}
</style>
