<template>
  <section class="content-container">
    <article class="section" v-for="(item, index) in items" :key="index">
      <h2 class="section-title">{{ item.title }}</h2>
      <p class="section-text">{{ item.text }}</p>
    </article>
  </section>
</template>

<script>
export default {
  name: 'OneProductTitleBodyLoop',
  props: {
    items: {
      type: Array,
      required: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.content-container {
  color: #1b3b3d;
  padding: 0 20px;
}

.section-title {
  font-family: $font-black;
  text-transform: uppercase;
  font-size: 57px;
}

.section-text {
  margin-top: 10px;
  width: 100%;
  font-family: $font-regular;
  font-size: 20px;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 37px;
  }
  .section-text {
    font-size: 16px;
  }
}

</style>
