<script setup>

</script>

<template>

  <svg width="42" height="31" viewBox="0 0 42 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.06451 28.3356C2.95965 28.3356 2.08603 27.4363 2.08603 26.3314C2.08603 26.0488 2.13742 25.7661 2.26589 25.5092C2.44575 25.0724 2.80548 24.7126 3.24229 24.5328C3.85896 24.2758 4.55271 24.3272 5.11799 24.6869C5.47772 24.9182 5.96591 24.8668 6.24855 24.5842L6.68536 24.1474C6.89092 23.9418 6.89092 23.6335 6.68536 23.4279C6.68536 23.4279 6.68536 23.4279 6.65967 23.4022C5.42633 22.323 3.6534 22.0918 2.18881 22.8369C1.41797 23.1967 0.826996 23.8133 0.467272 24.5842C-0.843152 27.4877 1.2638 30.3397 4.03882 30.3397C5.40063 30.3397 6.65967 29.646 7.37911 28.4897L5.78605 27.3335C5.42633 27.9502 4.78396 28.3356 4.06451 28.3356Z" fill="white"/>
    <path d="M33.9728 29.1835L33.8187 22.4001L30.3499 26.1001C30.17 26.28 29.8617 26.3057 29.6818 26.1258L29.6561 26.1001L26.1874 22.4001L26.0332 29.1835C26.0332 29.1835 26.0332 29.1835 26.0332 29.2092C26.0589 29.7488 26.5214 30.1342 27.061 30.1085C27.5492 30.0828 27.9346 29.6974 27.9603 29.2092V27.6932C27.9603 27.5133 28.1145 27.3335 28.32 27.3335C28.4228 27.3335 28.5256 27.3849 28.6027 27.4619L30.0416 29.2092L31.4805 27.4619C31.6089 27.3078 31.8145 27.2821 31.9687 27.4106C32.0457 27.4876 32.0971 27.5904 32.0971 27.6932V29.1835C32.1228 29.7231 32.5853 30.1085 33.1249 30.0828C33.5617 30.0828 33.9471 29.6974 33.9728 29.1835Z" fill="white"/>
    <path d="M40.5509 28.464H38.3412C38.1356 28.464 37.9558 28.2842 37.9558 28.0786C37.9558 27.8731 38.1356 27.6932 38.3412 27.6932H39.9857C40.4482 27.6675 40.8079 27.3078 40.7822 26.8453C40.7565 26.4085 40.4225 26.0488 39.9857 26.0488H38.9065C38.4954 26.0488 38.11 25.7918 38.0072 25.3807C37.8787 24.9182 38.187 24.43 38.6495 24.3015C38.7266 24.2758 38.8037 24.2758 38.8551 24.2758H40.3711C40.6537 24.3015 40.9364 24.1474 41.0648 23.9161C41.3218 23.5307 41.1933 22.9911 40.8079 22.7599C40.6794 22.6828 40.5252 22.6314 40.3711 22.6314H37.0308H36.9023C36.4398 22.6314 36.0801 22.9911 36.0801 23.4536V29.4661C36.1572 29.8516 36.4655 30.1085 36.8252 30.1599H40.5509C41.0134 30.1599 41.3732 29.7745 41.3732 29.312C41.3732 28.8238 41.0134 28.464 40.5509 28.464Z" fill="white"/>
    <path d="M12.775 30.1856C10.5652 30.1856 8.7666 28.464 8.7666 26.3314C8.7666 24.1987 10.5652 22.4772 12.775 22.4772C14.9847 22.4772 16.7833 24.1987 16.7833 26.3314C16.7833 28.464 14.9847 30.1856 12.775 30.1856ZM12.775 24.4557C11.6444 24.4557 10.7451 25.3036 10.7451 26.3314C10.7451 27.3592 11.6444 28.2071 12.775 28.2071C13.9055 28.2071 14.8048 27.3592 14.8048 26.3314C14.8048 25.3036 13.9055 24.4557 12.775 24.4557Z" fill="white"/>
    <path d="M22.8471 26.1001C22.3332 25.7918 21.7937 25.5606 21.2284 25.355C21.2284 25.355 20.6374 25.2008 20.6374 24.7897C20.6374 24.4814 20.843 24.173 21.4596 24.173C21.7166 24.173 21.9478 24.2501 22.1791 24.3786C22.3075 24.4557 22.4617 24.5071 22.5902 24.5071C22.8471 24.5071 23.0784 24.4043 23.2582 24.2244L23.4895 23.9932C23.6437 23.8133 23.6437 23.5307 23.4638 23.3765C23.4638 23.3765 23.4638 23.3765 23.4381 23.3508C22.8214 22.7855 21.9992 22.4772 21.1513 22.4772C19.8152 22.4772 18.5818 23.3251 18.5818 24.7897C18.5818 25.5092 18.9672 26.1772 19.5839 26.5626C19.8152 26.6911 20.1749 26.871 20.4575 26.9994C20.843 27.1793 21.6652 27.4876 21.7423 27.5133C21.9478 27.5904 22.0763 27.7703 22.0763 27.9758C22.0763 28.8752 20.5346 28.4897 20.5346 28.4897C20.1235 28.3613 19.7381 28.1557 19.4297 27.8474L18.145 28.7981C18.5047 29.1321 19.7381 30.3141 21.4339 30.3141C22.7444 30.3141 23.8492 29.7745 24.1062 28.387C24.3631 26.7682 22.8471 26.1001 22.8471 26.1001Z" fill="white"/>
    <path d="M41.3473 9.11604C41.3473 8.31951 40.8077 7.54867 39.523 7.67714C38.7264 7.75423 38.0584 8.31951 38.0584 9.11604C38.0584 9.14173 38.0584 9.14173 38.0584 9.16743V12.5848C38.0584 13.2272 37.6987 13.5612 37.1848 13.33L29.6562 7.44589C29.6562 7.67714 29.6819 19.4453 29.6819 19.4453C29.6819 20.2418 30.4014 20.8842 31.6347 20.8842C32.4313 20.8842 33.0993 20.2418 33.0993 19.4453V15.514C33.125 15.18 33.4077 14.923 33.7417 14.9487C33.8445 14.9487 33.9472 14.9744 34.0243 15.0258L41.373 20.8585L41.3473 9.11604Z" fill="white"/>
    <path d="M25.9304 18.9828L20.2262 7.29172L14.522 18.9828C14.3678 19.3168 14.3164 19.6765 14.4449 20.0362C14.5734 20.396 14.856 20.73 15.5241 20.7814C15.5241 20.7814 16.963 21.0383 17.6567 20.0876C17.8623 19.7793 18.1963 19.5994 18.556 19.5994H20.0463H20.4831H21.9477C22.3074 19.5994 22.6415 19.7793 22.847 20.0876C23.5408 21.0383 24.9797 20.7814 24.9797 20.7814C25.6477 20.73 25.9304 20.396 26.0588 20.0362C26.1359 19.6765 26.1102 19.2911 25.9304 18.9828ZM20.2262 17.0043C19.7637 17.0043 19.4553 16.5418 19.4553 16.0536C19.4553 15.5654 19.738 14.7431 20.2005 14.7431H20.2262C20.663 14.7431 20.997 15.5654 20.997 16.0536C20.997 16.5418 20.6887 17.0043 20.2262 17.0043Z" fill="white"/>
    <path d="M9.07487 13.5355C8.17556 13.0216 7.25055 12.5848 6.27416 12.2508C6.27416 12.2508 5.24638 11.9681 5.22068 11.3001C5.19499 10.7862 5.6061 10.2209 6.63388 10.2209C7.07069 10.2466 7.5075 10.3494 7.86722 10.5806C8.07278 10.7091 8.32973 10.7862 8.58667 10.7862C9.02348 10.7862 9.43459 10.6063 9.74293 10.298L10.1283 9.88686C10.411 9.57853 10.411 9.11602 10.1027 8.83338L10.077 8.80769C9.02348 7.8056 7.61028 7.26602 6.14569 7.29171C3.83317 7.29171 1.70052 8.73061 1.70052 11.3001C1.70052 12.5591 2.34289 13.7154 3.42206 14.3577C3.80748 14.5633 4.42415 14.8973 4.93804 15.1286C5.58041 15.4369 7.045 15.9765 7.14778 16.0279C7.53319 16.2334 7.73875 16.4647 7.73875 16.8501C7.73875 18.4175 5.06651 17.7494 5.06651 17.7494C4.34707 17.5438 3.7047 17.1584 3.16512 16.6445L0.929688 18.3147C1.54636 18.9057 3.67901 20.9355 6.60819 20.9355C8.86931 20.9355 10.7964 20.0105 11.2075 17.5952C11.67 14.7174 9.07487 13.5355 9.07487 13.5355Z" fill="white"/>
    <path d="M15.5755 2.60734H15.113V1.27122L14.3165 2.06775L13.4942 1.27122V2.60734H13.0317V0.166351L14.2908 1.42539L15.5498 0.166351V2.60734H15.5755Z" fill="white"/>
    <path d="M18.094 0.269104V0.731606H16.7322V1.2198H17.6829V1.68231H16.7322V2.1705H18.094V2.60731H16.2954V0.269104H18.094Z" fill="white"/>
    <path d="M20.792 0.269104L19.61 2.1705H20.7406V2.60731H18.8135L19.9954 0.705912H18.9163V0.269104H20.792Z" fill="white"/>
    <path d="M22.4873 2.60731C22.1533 2.60731 21.8963 2.50453 21.6651 2.27328C21.4338 2.04203 21.3311 1.75939 21.3311 1.45106C21.3311 1.11703 21.4338 0.860081 21.6651 0.628829C21.8963 0.397578 22.179 0.2948 22.4873 0.2948C22.8213 0.2948 23.0783 0.397578 23.3095 0.628829L23.0012 0.937164C22.8727 0.808691 22.6929 0.731608 22.4873 0.731608C22.2818 0.731608 22.1276 0.808691 21.9734 0.937164C21.8193 1.06564 21.7679 1.2455 21.7679 1.45106C21.7679 1.65661 21.8449 1.81078 21.9734 1.96495C22.1019 2.09342 22.2818 2.1705 22.4873 2.1705C22.6929 2.1705 22.847 2.09342 23.0012 1.96495L23.3095 2.27328C23.0783 2.50453 22.7956 2.60731 22.4873 2.60731Z" fill="white"/>
    <path d="M26.2133 2.60734H25.7251L25.5967 2.3247H24.646L24.5175 2.60734H24.0293L25.1085 0.166351L26.2133 2.60734ZM24.8515 1.88789H25.3911L25.1085 1.27122L24.8515 1.88789Z" fill="white"/>
    <path d="M27.3951 0.269104V2.1705H28.7826V2.60731H26.9326V0.269104H27.3951Z" fill="white"/>
  </svg>


</template>

<style scoped>


</style>