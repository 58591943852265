<template>
  <div ref="pageWrapper" class="page-wrapper">
    <div ref="content" v-if="product !== null"
         :style="{'transform': 'translateY(-' + (scrollPosition * divHeight)   + '%)'}">
      <div>
        <OneProductHeaderComponent
            :title="product.OneProductHeaderComponent.title"
            :subtitle="product.OneProductHeaderComponent.subtitle"
            :mobilePosterUrl="product.OneProductHeaderComponent.mobilePosterUrl"
            :desktopPosterUrl="product.OneProductHeaderComponent.desktopPosterUrl"
            :mobileVideoUrl="product.OneProductHeaderComponent.mobileVideoSource"
            :siteUrl="product.OneProductHeaderComponent.siteUrl"
            :shopUrl="product.OneProductHeaderComponent.shopUrl"
            :desktopVideoUrl="product.OneProductHeaderComponent.desktopVideoSource"
            @goBackToMainScreen="$emit('backButtonClicked')"
            @playFullVideo="handlePlayFullVideo"
        >
          <div class="logo-container">
            <div v-if="product.OneProductHeaderComponent.title === 'Tatratea'">
              <TatrateaLogo/>
            </div>
            <div v-if="product.OneProductHeaderComponent.title === 'On lemon'">
              <OnLemonLogo/>
            </div>
            <div v-if="product.OneProductHeaderComponent.title === 'Berkshire'">
              <BerkshireLogo/>
            </div>
            <div v-if="product.OneProductHeaderComponent.title === 'GIN 1689'">
              <Gin1689Logo/>
            </div>
            <div
                v-if="product.OneProductHeaderComponent.title === 'Padre azul'"
            >
              <PadreAzulLogo/>
            </div>
            <div
                v-if="product.OneProductHeaderComponent.title === 'lala tequila'"
            >
              <LalaTequilaLogo/>
            </div>
            <div
                v-if="
                product.OneProductHeaderComponent.subtitle === 'Irish whiskey'
              "
            >
              <SamuelGelstonLogo/>
            </div>
            <div v-if="product.OneProductHeaderComponent.title === 'BANDOENG'">
              <BandoengLogo/>
            </div>
            <div
                v-if="product.OneProductHeaderComponent.title === 'xiaman mezcal'"
            >
              <XiamanLogo/>
            </div>
            <div v-if="product.OneProductHeaderComponent.title === 'San Cosme'">
              <SanCosmeLogo/>
            </div>
            <div v-if="product.OneProductHeaderComponent.title === 'Mates'">
              <MatesLogo/>
            </div>
          </div>
        </OneProductHeaderComponent>
      </div>
      <div class="intro-paragraph">
        <OneProductIntroComponent
            :paragraphs="product.OneProductIntroComponent.paragraphs"
            :subtitle="product.OneProductIntroComponent.subtitle"
            :object-path="product.OneProductHeaderComponent.objectPath"
        />
      </div>
      <div>
        <OneProductSloganComponent
            :title="product.OneProductSloganComponent.title"
            :imageUrl="product.OneProductSloganComponent.imageUrl"
        />
      </div>
      <div class="translate-to-bottle">
        <div class="about-component">
          <OneProductAboutComponent
              :paragraphs="product.OneProductAboutComponent.paragraphs1"
              :imageUrl="product.OneProductAboutComponent.imageUrl1"
          />
        </div>
        <div class="about-component">
          <OneProductQuoteComponent
              :title1="product.OneProductQuoteComponent.quoteTitle1"
              :title2="product.OneProductQuoteComponent.quoteTitle2"
              :title3="product.OneProductQuoteComponent.quoteTitle3"
              :body="product.OneProductQuoteComponent.quoteBody"
          />
        </div>
        <div class="about-component">
          <OneProductAboutComponent
              :title="product.OneProductAboutComponent.title"
              :paragraphs="product.OneProductAboutComponent.paragraphs2"
              :imageUrl="product.OneProductAboutComponent.imageUrl2"
          />
        </div>
        <div class="about-component">
          <OneProductTitleBodyLoop
              :items="product.OneProductTitleBodyLoop.items"
          />
        </div>
        <div class="process-component">
          <OneProductProcessComponent
              :production-steps="
              product.OneProductProcessComponent.productionSteps
            "
          />
        </div>
        <div class="carousel-component">
          <ProductsCarousel ref="carousel" :carouselSlides="product.ProductsCarousel.carouselSlides"/>
        </div>
        <div class="product-navigation d-flex justify-content-center" v-if="false">
          <OneProductNavigation/>
        </div>
        <div class="d-flex justify-content-center mb-5">
          <BorderOutlineButton
              theme="dark"
              :button-text="'View next brand'"
              @click="$emit('nextBrandButtonClicked')"
          />
        </div>
      </div>
    </div>
  </div>
  <ModalComponent ref="videoModal">
    <div v-if="product">
      <iframe v-if="product.OneProductHeaderComponent.fullVideo.type === 'youtube'"
              height="315"
              style="width: 100%"
              v-bind:src="product.OneProductHeaderComponent.fullVideo.source"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
      </iframe>
      <video v-else
             autoplay="true"
             controls="true"
             class="modal-video"
             :poster="product.OneProductHeaderComponent.mobileVideoSource"
             ref="videoPlayer"
             style="width: 100%"
      >
        <source v-bind:src="product.OneProductHeaderComponent.fullVideo.source" type="video/mp4"/>
        Your browser does not support the video tag.
      </video>
    </div>
  </ModalComponent>
</template>

<script>
import OneProductHeaderComponent from "@/components/Components/OneProductHeaderComponent";
import OneProductIntroComponent from "@/components/Components/OneProductIntroComponent";
import OneProductSloganComponent from "@/components/Components/OneProductSloganComponent";
import OneProductAboutComponent from "@/components/Components/OneProductAboutComponent";
import OneProductProcessComponent from "@/components/Components/OneProductProcessComponent";
import OneProductQuoteComponent from "@/components/Components/OneProductQuoteComponent";
import ProductsCarousel from "@/components/Components/ProductsCarousel";
import OneProductNavigation from "@/components/Components/OneProductNavigation";
import TatrateaLogo from "@/components/Common/Icons/TatrateaLogo.vue";
import OnLemonLogo from "@/components/Common/Icons/OnLemonLogo.vue";
import BerkshireLogo from "@/components/Common/Icons/BerkshireLogo.vue";
import Gin1689Logo from "@/components/Common/Icons/Gin1689Logo.vue";
import PadreAzulLogo from "@/components/Common/Icons/PadreAzulLogo.vue";
import LalaTequilaLogo from "@/components/Common/Icons/LalaTequilaLogo.vue";
import SamuelGelstonLogo from "@/components/Common/Icons/SamuelGelstonLogo.vue";
import BandoengLogo from "@/components/Common/Icons/BandoengLogo.vue";
import XiamanLogo from "@/components/Common/Icons/XiamanLogo.vue";
import SanCosmeLogo from "@/components/Common/Icons/SanCosmeLogo.vue";
import MatesLogo from "@/components/Common/Icons/MatesLogo.vue";
import ModalComponent from "@/components/Common/ModalComponent.vue";
import OneProductTitleBodyLoop from "@/components/Components/OneProductTitleBodyLoop";
import gsap from "gsap"
import BorderOutlineButton from "@/components/Common/Buttons/BorderOutlineButton.vue";

export default {
  components: {
    BorderOutlineButton,
    ModalComponent,
    MatesLogo,
    SanCosmeLogo,
    XiamanLogo,
    BandoengLogo,
    SamuelGelstonLogo,
    LalaTequilaLogo,
    PadreAzulLogo,
    Gin1689Logo,
    BerkshireLogo,
    OnLemonLogo,
    OneProductHeaderComponent,
    OneProductIntroComponent,
    OneProductSloganComponent,
    OneProductAboutComponent,
    OneProductProcessComponent,
    OneProductQuoteComponent,
    ProductsCarousel,
    OneProductNavigation,
    OneProductTitleBodyLoop,
    TatrateaLogo,
  },
  props: {
    selectedCheckpoint: Number,
    scrollPosition: Number,
    product: Object,
  },
  watch: {
    product() {
      this.$nextTick(() => {
        if (this.$refs.content) {
          this.divHeight = 1.03 - Math.abs(window.innerHeight / this.$refs.content.clientHeight);
        }
      });
    }
  },
  data() {
    return {
      divHeight: 0,
      showVideoModal: false,
    }
  },
  methods: {
    hidePage() {
      const page = this.$refs.pageWrapper;
      gsap.to(page, {right: '-100%', duration: 1})
    },
    showPage() {
      const page = this.$refs.pageWrapper;
      gsap.to(page, {right: 0, duration: 1})
    },
    slideToNextProduct() {
      return this.$refs.carousel.slideToNext();
    },
    slideToPrevProduct() {
      return this.$refs.carousel.slideToPrev();
    },
    handlePlayFullVideo() {
      this.$refs.videoModal.showModal();
    }
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  width: 100%;
  background: #ffffff;
  overflow: hidden;

  .translate-to-bottle {
    margin-top: 100px;
  }

  .about-component {
    padding: 30px 0;
    transform: translateY(0%);
  }

  .carousel-component {
    margin: 40px 0;
    padding-bottom: 100px;
  }

  .product-navigation {
    position: fixed;
    bottom: 5%;
    left: 0%;
    width: 100%;
    z-index: 22;
  }

}

.logo-container {
  transform: scale(2);
  margin-bottom: 50px;
}

.intro-paragraph {
}

.modal-video {
  max-height: 80vh;
  max-width: 100%;
}

@media only screen and (min-width: 768px) {
  .page-wrapper {
    position: absolute;
    top: 0;
    right: -100%;
    height: 100%;
    width: 50%;

    .about-component {
      padding: 20px 40px;
      transform: translateY(0%);
    }

    .product-navigation {
      bottom: 5%;
      left: 50%;
      width: 50%;
    }
  }
}
</style>
