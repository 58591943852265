<template>
  <div class="menu-container">
    <nav class="menu-items">
      <div class="menu-item">
        <div class="menu-item-dot">&bull;</div>
        <div class="menu-item-text">Video</div>
      </div>
      <div class="menu-item">
        <div class="menu-item-dot">&bull;</div>
        <div class="menu-item-text">Our story</div>
      </div>
      <div class="menu-item">
        <div class="menu-item-dot">&bull;</div>
        <div class="menu-item-text">{{ $t('production') }}</div>
      </div>
      <div class="menu-item">
        <div class="menu-item-dot">&bull;</div>
        <div class="menu-item-text">All products</div>
      </div>
    </nav>
  </div>
</template>

<script>

export default {
  name: 'OneProductNavigation',
  props: {
    title: {
      type: String,
    },
    imageUrl: {
      type: String,
    },
    body: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 641px;
  padding: 16px;
  border-radius: 224px;
  background-color: #1b3b3d;
  color: #fff;
  font-weight: 700;
  text-align: center;
  line-height: 93%;
  box-shadow: 0px 0px 125px rgba(192, 255, 111, 0.3);
  position: relative;
}

.menu-items {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 451px;

}
.menu-item {
  margin: 0 20px;
  cursor: pointer;
  font-size: 12px;
  .menu-item-dot{
    font-size: 20px;
  }
  &:hover {
    color: #C0FF6F;
  }
}


@media (max-width: 991px) {
  .menu-container {
    padding: 10px 20px;
  }
  .menu-item {
    margin: 0;
  }
  .menu-items {
    min-width: 300px;
  }
}


@media (max-width: 991px) {
  .menu-items {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

.menu-item {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

@media (max-width: 991px) {
  .menu-item {
    white-space: initial;
  }
}

.menu-item-text {
  margin-top: 6px;
  font-family: Commissioner, sans-serif;
}

</style>