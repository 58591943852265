<template>
  <div v-if="isModalVisible" class="modal-wrapper">
    <div class="modal-backdrop" @click="hideModal"></div>
    <div class="modal-body" v-bind:class="{'modal-large': size === 'large'}">
      <div class="d-flex modal-header">
        <div v-if="title" class="modal-title">{{ title }}</div>
        <div class="ms-auto icon close-icon" @click="hideModal">
          <HamburgerMenuButton theme="dark" :show-close="true" @click="hideModal"/>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import HamburgerMenuButton from "@/components/Common/Buttons/HamburgerMenuButton.vue";

export default {
  components: {
    HamburgerMenuButton,
  },
  props: {
    offices: {
      type: Array
    },
    title: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'medium'
    },
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    hideModal() {
      this.isModalVisible = false;
      this.$emit('onClose');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-header {
  .modal-title {
    font-family: $font-bold;
  }
}

.modal-wrapper {
  .modal-body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    padding: 20px;
    background: $color-white;
    border-radius: 30px;
    z-index: 999;
  }

  .icon {
    cursor: pointer;
    z-index: 111;
    position: absolute;
    right: 5%;
    top: 5%;
  }

  .modal-backdrop {
    position: fixed;
    z-index: 998;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(1, 1, 1, 0.3);
  }
  .close-icon {
    position: absolute;
    top: 10px!important;
    right: 10px;
  }
}


@media screen and (min-width: 1024px) {
  .modal-wrapper {
    .modal-body {
      width: 900px;
      height: auto;
      padding: 70px;
    }

    .modal-large {
      min-width: 800px;
    }

    .icon {
      top: 5%;
    }
  }
}

</style>