<template>
  <div class="image-container ">
    <div>
      <img :src="imageUrl" alt="Decorative image" class="image"/>
      <div class="carousel-item-title"> {{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OneProductCarouselItem",
  components: {},
  props: {
    imageUrl: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
  }
}
</script>

<style lang="scss" scoped>

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 69px 60px;
  background-color: #f0f0e5;
  border-radius: 48px;
  max-height: 300px;
  min-height: 250px;
  position: relative;
  transition: .3s ease-in-out;
  cursor: pointer;

  &:hover {
    transition: .3s ease-in-out;
    max-height: 330px;

    .carousel-item-title {
      display: block;
    }
  }
}

.image {
  max-height: 200px;
  object-fit: auto;
  object-position: center;
}

.carousel-item-title {
  font-family: $font-bold;
  text-align: center;
  margin-top: 40px;
  display: none;
  color: #1B3B3D;
}

@media (max-width: 991px) {
  .image-container {
    padding: 20px 20px;
  }
}

</style>
