<script setup>

</script>

<template>

  <svg width="50" height="28" viewBox="0 0 50 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_110_2837)">
      <path d="M0.0292969 27.7959V15.0009H2.80768V25.3989H6.02124V27.7959H0.0292969Z" fill="white"/>
      <path d="M18.7481 6.4666C16.9673 6.4666 15.7756 7.91533 15.7756 9.58796C15.7756 11.2935 16.9673 12.7686 18.7749 12.7686C20.5223 12.7686 21.7006 11.2672 21.7006 9.62747C21.7073 7.9746 20.529 6.4666 18.7481 6.4666ZM18.7214 15.1787C15.6484 15.1787 13.0039 12.8344 13.0039 9.75258C13.0039 8.95578 13.1378 8.20508 13.4324 7.46754C13.5931 7.04609 13.8609 6.63123 14.1353 6.26905C15.2534 4.82032 16.8869 4.06961 18.7347 4.06961C21.9081 4.06961 24.4857 6.47319 24.4857 9.62088C24.4857 12.7883 21.9081 15.1787 18.7214 15.1787Z" fill="white"/>
      <path d="M31.6027 14.9746L27.4585 8.46847L27.4251 8.48164L27.4987 14.9746H24.8408V4.27374H27.2376L31.442 10.9972L31.4688 10.9642L31.355 4.27374H34.0128V14.9746H31.6027Z" fill="white"/>
      <path d="M34.0127 4.27375C34.0127 4.27375 34.0127 0 39.6431 0C39.6431 0 39.6565 4.27375 34.0127 4.27375Z" fill="white"/>
      <path d="M6.67725 27.7959V17.1016H12.716V19.5052H9.33512V21.1646H12.6089V23.5616H9.33512V25.3989H12.716V27.7959H6.67725Z" fill="white"/>
      <path d="M22.2833 27.7959L22.3704 19.7949L22.3436 19.762L20.0205 27.7959H18.1861L15.8629 19.762L15.8294 19.7949L15.9232 27.7959H13.3657V17.1016H16.9341L19.0966 24.1411H19.1233L21.2523 17.1016H24.8408V27.7959H22.2833Z" fill="white"/>
      <path d="M30.8927 19.301C29.1118 19.301 27.9268 20.7432 27.9268 22.4224C27.9268 24.1279 29.1185 25.603 30.9261 25.603C32.6735 25.603 33.8518 24.1016 33.8518 22.4685C33.8451 20.8024 32.6735 19.301 30.8927 19.301ZM30.8659 28C27.7929 28 25.1484 25.6623 25.1484 22.5804C25.1484 21.7836 25.2823 21.0329 25.5769 20.2954C25.7376 19.8739 26.0054 19.4591 26.2799 19.0969C27.3979 17.6482 29.0248 16.8975 30.8793 16.8975C34.0527 16.8975 36.6235 19.2944 36.6235 22.4487C36.6235 25.6162 34.0527 28 30.8659 28Z" fill="white"/>
      <path d="M43.7067 27.809L39.5625 21.3095L39.5291 21.3227L39.6027 27.809H36.9448V17.1148H39.3416L43.5393 23.8382L43.5728 23.8053L43.4523 17.1148H46.1101V27.809H43.7067Z" fill="white"/>
      <path d="M47.9447 17.7469H47.784V18.0828H47.938C48.0652 18.0828 48.1322 18.0301 48.1322 17.905C48.1389 17.7799 48.0518 17.7469 47.9447 17.7469ZM48.159 18.7281C48.1322 18.6755 48.1255 18.5108 48.1188 18.445C48.0987 18.3199 48.0384 18.2738 47.9045 18.2738H47.784V18.7281H47.4828V17.5428H47.9916C48.2058 17.5428 48.4334 17.6284 48.4334 17.8787C48.4334 18.0169 48.3598 18.1091 48.2393 18.1618C48.487 18.2672 48.3732 18.5306 48.4468 18.7281H48.159ZM47.9514 17.2926C47.4694 17.2926 47.1012 17.6811 47.1012 18.1421C47.1012 18.603 47.4761 18.9915 47.9447 18.9915C48.4201 18.9915 48.7883 18.603 48.7883 18.1421C48.795 17.6877 48.4268 17.2926 47.9514 17.2926ZM47.9514 19.2154C47.3556 19.2154 46.8735 18.7347 46.8735 18.1486C46.8735 17.556 47.3623 17.0818 47.9581 17.0818C48.554 17.0818 49.0293 17.5691 49.0293 18.1486C49.0293 18.7347 48.5473 19.2154 47.9514 19.2154Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_110_2837">
        <rect width="49" height="28" fill="white" transform="translate(0.0292969)"/>
      </clipPath>
    </defs>
  </svg>


</template>

<style scoped>


</style>