<template>
<div class="filter-button-wrapper" @click="handleClick" :class="{ 'button-active': active }">
  {{ buttonText }}
</div>
</template>

<script>
export default {
  name: "CocktailsFilterButton",
  props: {
    buttonText: {
      type: String
    },
    selectedBrands: {
      type: Array,
      default: null
    }
  },
  computed: {
      active() {
        if (!this.$props.selectedBrands) {
          return false;
        }
        return this.$props.selectedBrands.includes(this.$props.buttonText)
      }
  },
  methods: {
    handleClick() {
      this.$emit('button-clicked', this.$props.buttonText);
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-button-wrapper {
  background: #F6F6EC;
  color: #4E5768;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 10px;
  font-family: $font-bold;
  &:hover {
    background: #C0FF6F;
    transition: 0.3s ease-in-out;
  }
}
.button-active {
  background: #C0FF6F;
  transition: 0.3s ease-in-out;
}
@media screen and (min-width: 768px){
  .filter-button-wrapper {
    padding: 10px 20px;
  }
}
</style>
