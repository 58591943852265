<template>
  <div class="d-flex justify-content-center align-items-center one-product-wrapper">
    <div>
      <div class="d-flex justify-content-center logo-container">
        <div class="me-1" v-if=" buttonIcon === Brands.TATRA_TEA ">
          <TatrateaLogo/>
        </div>
        <div class="me-1" v-if=" buttonIcon === Brands.CZECHOSLOVAKIA ">
          <CzechoslovakiaLogo/>
        </div>
        <div class="me-1" v-if=" buttonIcon === Brands.MATES ">
          <MatesLogo/>
        </div>
        <div class="me-1" v-if=" buttonIcon === Brands.BERKSHIRE ">
          <BerkshireLogo/>
        </div>
        <div class="me-1" v-if=" buttonIcon === Brands.XIAMAN ">
          <XiamanLogo/>
        </div>
        <div class="me-1" v-if=" buttonIcon === 'karloff' ">
          <KarloffLogo/>
        </div>
        <div class="me-1" v-if=" buttonIcon === Brands.GIN_1689 ">
          <Gin1689Logo/>
        </div>
        <div class="me-1" v-if=" buttonIcon === Brands.LALA ">
          <LalaTequilaLogo/>
        </div>
        <div class="me-1" v-if=" buttonIcon === Brands.PADRE_AZUL ">
          <PadreAzulLogo/>
        </div>
        <div class="me-1" v-if=" buttonIcon === Brands.SAN_COSME ">
          <SanCosmeLogo/>
        </div>
        <div class="me-1" v-if=" buttonIcon === Brands.ON_LEMON ">
          <OnLemonLogo/>
        </div>
        <div class="me-1" v-if=" buttonIcon === Brands.GELSTONS ">
          <SamuelGelstonLogo :width="40" :height="40"/>
        </div>
        <div class="me-1" v-if=" buttonIcon === Brands.BANDOENG ">
          <BandoengLogo :height="30"/>
        </div>
      </div>
      <div class="brand-title"> {{ buttonText }}</div>
    </div>


  </div>

</template>

<script>

import BerkshireLogo from "@/components/Common/Icons/BerkshireLogo.vue";
import Gin1689Logo from "@/components/Common/Icons/Gin1689Logo.vue";
import KarloffLogo from "@/components/Common/Icons/KarloffLogo.vue";
import CzechoslovakiaLogo from "@/components/Common/Icons/CzechoslovakiaLogo.vue";
import LalaTequilaLogo from "@/components/Common/Icons/LalaTequilaLogo.vue";
import MatesLogo from "@/components/Common/Icons/MatesLogo.vue";
import PadreAzulLogo from "@/components/Common/Icons/PadreAzulLogo.vue";
import SanCosmeLogo from "@/components/Common/Icons/SanCosmeLogo.vue";
import TatrateaLogo from "@/components/Common/Icons/TatrateaLogo.vue";
import XiamanLogo from "@/components/Common/Icons/XiamanLogo.vue";
import OnLemonLogo from "@/components/Common/Icons/OnLemonLogo"
import {Brands} from "@/enum/brands";
import SamuelGelstonLogo from "@/components/Common/Icons/SamuelGelstonLogo.vue";
import BandoengLogo from "@/components/Common/Icons/BandoengLogo.vue";
export default {
  name: "OneProductBox",
  computed: {
    Brands() {
      return Brands
    }
  },
  components: {
    BandoengLogo, SamuelGelstonLogo,
    BerkshireLogo,
    Gin1689Logo,
    KarloffLogo,
    CzechoslovakiaLogo,
    LalaTequilaLogo,
    MatesLogo,
    PadreAzulLogo,
    SanCosmeLogo,
    TatrateaLogo,
    XiamanLogo,
    OnLemonLogo
  },
  props: {
    buttonIcon: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
  }
}
</script>

<style lang="scss" scoped>
.one-product-wrapper {
  background: #2F3745;
  color: #707C91;
  padding: 30px 5px;
  border-radius: 10px;
  height: 120px;
}
.brand-title {
  padding-top: 10px;
  font-size: 14px;
}
@media only screen and (min-width: 768px) {

}

</style>