<template>
  <div class="d-none d-md-flex page-wrapper">
    <div class="all-products-nav d-flex align-items-center justify-content-center">
      <ul>
        <li class="vertical-line"></li>
        <li class="all-products-nav-item" v-for="(product, index) in products" :key="index">
          <MainButton @click="$router.push({name: 'SingleProduct', params: {brand: product.buttonIcon}})"
                      :size="'small'" :button-text="product.buttonText" :button-icon="product.buttonIcon"
                      :theme="'green-hover'"/>
        </li>
      </ul>
    </div>
    <div class="flex-container">
      <ProductsMapComponent :products="products"/>
    </div>
  </div>
  <div class="d-md-none products-wrapper-mobile container ">
    <div class="d-flex align-items-center button-back">
      <ArrowLeftIcon/>
      <div class="ms-1">Back</div>
    </div>
    <div class="products-title">All products</div>
    <div class="row">
      <div class="one-product-box col-6" v-for="(product, index) in products" :key="index">
        <router-link :to="{name: 'SingleProduct', params: {brand: product.buttonIcon}}">
          <OneProductBox :button-icon="product.buttonIcon" :button-text="product.buttonText"/>
        </router-link>
      </div>
    </div>

  </div>

</template>

<script>
import MainButton from "@/components/Common/Buttons/MainButton";
import OneProductBox from "@/components/Components/OneProductBox";
import ArrowLeftIcon from "@/components/Common/Icons/ArrowLeftIcon"
import ProductsMapComponent from "@/components/Components/ProductsMapComponent.vue";
import {ProductsMapConfig} from "@/utils/ProductsMapConfig";

export default {
  name: "AllProductsView",
  components: {
    ProductsMapComponent,
    MainButton,
    OneProductBox,
    ArrowLeftIcon,
  },
  props: {
    buttonText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      products: []
    };
  },
  mounted() {
    this.products = ProductsMapConfig;
  }
}


</script>

<style lang="scss" scoped>

@import "~@/assets/scss/fonts.scss";

.page-wrapper {
  background: #252B37;
}


.flex-container {
  display: flex;
  background-color: transparent;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  width: 100%; /* Set width of the container */
  height: 100vh; /* Set height of the container */
  padding: 50px;

}


.products-wrapper-mobile {
  background: #252B37;
  padding-top: 30px;
  padding-bottom: 30px;

  .button-back {
    color: #FFFFFF;
    font-size: 12px;
  }

  .one-product-box {
    padding-top: 20px;
  }

  .products-title {
    color: #FF557E;
    font-family: CommissionerBlack;
    padding: 30px 0;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 2px 2px 60px rgba(255, 85, 126, 0.6);

  }
}

ul {
  list-style-type: disc; /* Use disc bullets */
  color: #FFFFFF;
  position: relative;

  li:hover {
    color: #C0FF6F;
    position: relative;
  }

  .vertical-line {
    height: 100%;
    width: 0.1px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    transform: translateX(-2px);
    list-style-type: none;
    z-index: 9;
    left: 6.7%;
  }
}


.all-products-nav {
  width: 700px;
  height: 100vh;
  overflow: auto;

  .all-products-nav-item {
    padding: 10px 0;
  }
}

* {
  scrollbar-width: none;
}

@media only screen and (min-width: 1900px) {
  ul {
    .vertical-line {
      left: 7%;
    }
  }


}
</style>