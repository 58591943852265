<template>
  <div class="contacts-container d-md-flex justify-content-center">
    <div class="contacts-wrapper row g-0">
      <div class="map-wrapper col-md-5 col-12 ">
        <GoogleMap style="width: 100%;
                   height: 100%" api-key="AIzaSyB4WqbVck19lMj4zvEBU9Y_CPILPW_237Q" :zoom="7"
                   :center="{lat: 42.49988365271612, lng: 25.231609612925894}"
                   :styles="mapConfig" :disableDefaultUi="true">
          <Marker v-for="(marker, index) in markers" v-bind:key="index" :options="marker"/>
        </GoogleMap>
      </div>
      <div class="scrollable-content col-md-7 col-12">
        <div class="d-md-flex">
          <div class="col-md-6 contacts-box border-right-desktop border-bottom-mobile">
            <div class="contacts-title">Contacts</div>
            <div class="contacts-item">E: office@drinkplus.bg</div>
            <div class="contacts-item">M: +359 899 008 868</div>
            <div class="contacts-item">T: +359 2 86 23 710</div>
            <div class="contacts-item">Marketing: d.borisov@drinkplus.bg</div>
          </div>
          <div class="col-md-6 contacts-box ">
            <div class="contacts-title">Locations</div>
            <div class="contacts-item d-flex align-items">
              <MapPinWhite/>
              <div class="ms-2">5 "Lyubotran" str., Sofia
              </div>
            </div>
            <div class="contacts-item d-flex align-items">
              <MapPinWhite/>
              <div class="ms-2">35 "Lozengrad" str., Plovdiv
              </div>
            </div>
            <div class="contacts-item d-flex align-items">
              <MapPinWhite/>
              <div class="ms-2">19 "Todor Ikonomov" str., Varna
              </div>
            </div>
            <div class="contacts-item d-flex align-items-center ">
              <MapPinWhite/>
              <div class="ms-2">13 "Antim I" str., Burgas
              </div>
            </div>
          </div>
        </div>

        <div class="contacts-box border-top">
          <div class="contacts-title">Stay in touch</div>
          <div class="d-md-flex">
            <div class="col-md-6 padding-right">
              <div class="contacts-placeholder">Your name</div>
              <input type="text" v-model="form.name" v-bind:class="{'form-error': errors.includes('name')}">
            </div>
            <div class="col-md-6 padding-left">
              <div class="contacts-placeholder">Email</div>
              <input type="email" v-model="form.email" v-bind:class="{'form-error': errors.includes('email')}">
            </div>
          </div>
          <div>
            <div class="contacts-placeholder">Email</div>
            <textarea v-model="form.message" v-bind:class="{'form-error': errors.includes('message')}"></textarea>
          </div>
          <div class="col-md-2 button-send mt-3">
            <GreenButton :button-text="'Send now'" @click="send"/>
          </div>
        </div>
      </div>
    </div>
    <ModalComponent ref="successModal">
      Your message was sent successfully!
    </ModalComponent>
  </div>
</template>

<script setup>
import GreenButton from "@/components/Common/Buttons/GreenButton";
import MapPinWhite from "@/components/Common/Icons/MapPinWhite";
import {GoogleMap, Marker} from 'vue3-google-map';
import mapOptions from "@/configs/MapOptions";
import {ref} from "vue";
import ModalComponent from "@/components/Common/ModalComponent.vue";
import {useReCaptcha} from "vue-recaptcha-v3";
import axios from "axios";
const {executeRecaptcha, recaptchaLoaded} = useReCaptcha()

const fullUrl = location.protocol + '//' + location.host;

const mapConfig = ref(null)
const successModal = ref(null)
const errors = ref([])
const form = ref({
    name: '',
    email: '',
    message: '',
})

mapConfig.value = mapOptions

const markers = ref([])
markers.value = [
  {
    position: {lat: 42.6698829337403, lng: 23.31526392759556,},
    icon: {url: fullUrl + '/static/images/map-pin.png', size: {width: 30, height: 30}, anchor: {x: 20, y: 20},},
  },
  {
    position: {lat: 42.13869470144174, lng: 24.761099835570132,},
    icon: {url: fullUrl + '/static/images/map-pin.png', size: {width: 30, height: 30}, anchor: {x: 20, y: 20},},
  },
  {
    position: {lat: 43.20986497613678, lng: 27.926175647630572,},
    icon: {url: fullUrl + '/static/images/map-pin.png', size: {width: 30, height: 30}, anchor: {x: 20, y: 20},},
  },
  {
    position: {lat: 42.49214605384901, lng: 27.476626886807736,},
    icon: {url: fullUrl + '/static/images/map-pin.png', size: {width: 30, height: 30}, anchor: {x: 20, y: 20},},
  },
]

async function send() {
  const isMailValid = String(form.value.email)
      .toLowerCase()
      .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  errors.value = [];
  if (form.value.name.length < 2) {
    errors.value.push('name')
  }
  if (form.value.email.length < 2 || !isMailValid) {
    errors.value.push('email')
  }
  if (form.value.message.length < 2) {
    errors.value.push('message')
  }

  if (errors.value.length > 0) {
    return
  }

  await recaptchaLoaded()
  const token = await executeRecaptcha('login')
  try {
    await axios.post('https://intera.bg/api/mailing/send', {
      app: 'drinkplus',
      token: token,
      form: form.value,
    });
  } catch (err) {
    console.log(err)
  } finally {
    successModal.value.showModal();
  }
}
</script>

<style lang="scss" scoped>

@import "~@/assets/scss/fonts.scss";

.contacts-container {
  background: #FFFFFF;
  padding-top: 100px;
  //height: 100vh;
}

.contacts-wrapper {
  background: #F2F2E4;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .map-wrapper {
    background-image: url("~@/assets/images/map-contacts.png");
    background-size: contain;
    border-right: 1px solid #DADAB7;
    height: 400px;
  }

  .scrollable-content {
    color: #515146;
    overflow-y: scroll;

    .border-top {
      border-top: 1px solid #DADAB7;
    }


    .contacts-box {
      padding: 25px 45px;

      input, textarea {
        background: #F7F7EE;
        border: none;
        border-radius: 20px;
        padding: 10px;
        width: 100%;
      }

      textarea {
        min-height: 90px;
      }

      .contacts-placeholder {
        font-size: 14px;
        margin: 20px 0 10px;
      }
    }

    .contacts-title {
      font-family: $font-bold;
      font-size: 50px;
      line-height: 45px;
      color: #FFFFFF;
      z-index: 1;
      margin-bottom: 15px;
    }

    .contact-item {
      font-size: 18px;
      font-family: $font-regular;
      margin-bottom: 10px;
    }

  }

}

@media only screen and (max-width: 600px) {
  .border-bottom-mobile {
    border-bottom: 1px solid #DADAB7;
  }
}

@media only screen and (min-width: 768px) {

  .border-right-desktop {
    border-right: 1px solid #DADAB7;
  }
  .padding-left {
    padding-left: 10px;
  }
  .padding-right {
    padding-right: 10px;
  }

  .contacts-container {
    //height: 100vh;
  }
  .contacts-wrapper {
    width: 90%;

    .scrollable-content {
      overflow: hidden;
    }

    .map-wrapper {
      height: 100%;
    }
  }
}

.form-error {
  outline: 1px solid #f18d8d;
}
</style>
