<template>
  <div class="">
    <div class="title-wrapper">
      <div class="d-flex">
        <div
            class="title-solid"
            :style="{ transform: `translateX(${solidTranslate}px)` }"
        >
          {{ title }}
        </div>
        <div
            class="title-outline"
            :style="{ transform: `translateX(${outlineTranslate}px)` }"
        >
          {{ title }}
        </div>
      </div>

      <div class="d-flex">
        <div
            class="title-outline"
            :style="{ transform: `translateX(${outlineTranslate}px)` }"
        >
          {{ title }}
        </div>
        <div
            class="title-solid"
            :style="{ transform: `translateX(${solidTranslate}px)` }"
        >
          {{ title }}
        </div>
      </div>

    </div>
    <div class="position-relative">
      <div class="image-url" :style="{ backgroundImage: 'url(' + imageUrl + ')' }"></div>

    </div>
  </div>

</template>

<script>
import {ref} from 'vue';

export default {
  name: "OneProductSloganComponent",
  components: {},
  props: {
    title: {
      type: String,
    },
    imageUrl: {
      type: String,
    },
  },
  setup() {
    const solidTranslate = ref(-10);
    const outlineTranslate = ref(20);

    // const handleScroll = () => {
    //   const scrollY = window.deltaY;
    //   solidTranslate.value += scrollY; // Adjust the multiplier as needed
    //   outlineTranslate.value += -scrollY; // Adjust the multiplier as needed
    // };
    //
    // onMounted(() => {
    //   window.addEventListener('wheel', handleScroll);
    // });
    //
    // onBeforeUnmount(() => {
    //   window.removeEventListener('wheel', handleScroll);
    // });

    return {
      solidTranslate,
      outlineTranslate
    };
  }

}
</script>

<style lang="scss" scoped>
.title-wrapper {
  width: 500%;
  height: 300%;
  font-family: $font-gilroy-bold;
  font-size: 85px;
  line-height: 65px;
  color: #1B3B3D;
  transform: rotate(-5deg) translateX(-10%);
  padding-bottom: 100px;
  position: relative;
  text-transform: uppercase;


  .title-outline {
    color: transparent;
    -webkit-text-stroke: 1.5px #1B3B3D;
    top: 50%;
  }

  .text-solid {

  }
}

.image-url {
  background: no-repeat center;
  background-size: cover;
  position: absolute;
  height: 500px;
  width: 500px;
  top: 0%;
  left: 0%;
  transform: translate(50%, -40%);

}


@media (max-width: 767px) {
  .image-url {
    left: -55%;
    transform: translate(50%, -45%);
    height: 400px;
    width: 400px;
  }
  .title-wrapper {
    font-size: 55px;
    line-height: 50px;
  }
}

@media screen and (min-width: 768px) {
  .image-url {

  }

}

@media screen and (min-width: 1900px) {
  .title-wrapper {
    transform: rotate(-5deg) translateX(-4%);
  }
}


</style>
