<template>
  <div id="intro-3d-object" v-if="show">
  </div>
</template>

<script>
import { defineComponent } from "vue";
import * as THREE from "three";
import { GLTFLoader } from "three/addons";

export default defineComponent({
  name: 'RotatingOObjectComponent',
  props: {
    objectPath: {
      type: String,
    },
  },
  watch: {
    objectPath() {
      const obj = document.getElementById('intro-3d-object');
      obj.innerHTML = '';
      this.init3DScene();
    }
  },
  data() {
    return {
      show: true
    }
  },
  mounted() {
    this.init3DScene();
  },
  methods: {
    init3DScene() {
      const container = document.getElementById('intro-3d-object');

      // Set up the scene, camera, and renderer with transparent background
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, container.offsetWidth / container.offsetHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true }); // Enable transparency
      renderer.setSize(container.offsetWidth, container.offsetHeight);
      renderer.setClearColor(0x000000, 0); // Transparent background
      container.appendChild(renderer.domElement);

      // Position the camera
      camera.position.z = 1;

      // Add lighting to the scene
      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 6); // Light from above and below
      hemiLight.position.set(0, 1, 0);
      scene.add(hemiLight);

      const spotLight = new THREE.SpotLight(0xffffff, 1);
      spotLight.position.set(0, 5, 5);
      spotLight.castShadow = true; // Enable shadow
      spotLight.shadow.mapSize.width = 1024;
      spotLight.shadow.mapSize.height = 1024;
      spotLight.shadow.camera.near = 0.5;
      spotLight.shadow.camera.far = 20;
      scene.add(spotLight);

      // Load the .glb model
      const loader = new GLTFLoader();
      let model; // Define a variable to hold the loaded model
      loader.load(this.$props.objectPath, (gltf) => {
        model = gltf.scene;
        scene.add(model);

        // Optionally, adjust the model's position and scale
        model.position.set(0, 0, 0);
        model.scale.set(1, 1, 1);
      });

      // Animation loop to render the scene
      const animate = () => {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      };
      animate();

      // Scroll and wheel event listener for rotating the model
      window.addEventListener('scroll', () => {
        if (model) {
          const scrollY = window.scrollY; // Get the current scroll position
          model.rotation.y = scrollY * 0.003; // Rotate the model based on scroll
          model.rotation.x = scrollY * 0.003; // Optional: rotate on X-axis as well
        }
      });

      window.addEventListener('wheel', (event) => {
        if (model) {
          const deltaY = event.deltaY; // Get the wheel scroll delta
          model.rotation.y += deltaY * 0.003; // Rotate on the Y-axis based on the wheel scroll
          model.rotation.x += deltaY * 0.003; // Rotate on the Y-axis based on the wheel scroll
        }
      });

      // Adjust renderer size on window resize
      window.addEventListener('resize', () => {
        camera.aspect = container.offsetWidth / container.offsetHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(container.offsetWidth, container.offsetHeight);
      });
    }
  },
});
</script>

<style scoped>
#intro-3d-object {
  width: 400px;
  height: 400px;

  canvas {
    width: 400px;
    height: 400px;
  }
}
</style>
