<template>

  <button class="button type1">
    <span class="btn-txt">
      <div>Start</div>
      <div>journey</div>
    </span>
  </button>

</template>

<script>

export default {
  name: "JourneyButton",

}
</script>

<style scoped>
@import "~@/assets/scss/fonts.scss";


.button {
  height: 100px;
  width: 100px;
  padding: 10px;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  border: 4px solid #C0FF6F;
  overflow: hidden;
  border-radius: 50%;
  color: #C0FF6F;
  transition: all 0.2s ease-in-out;
  line-height: 20px;
}

.btn-txt {
  z-index: 1;
  font-weight: 800;

}

.type1::after {
  content: "";
  position: absolute;
  left: 0%;
  bottom: -100%;
  transition: all 0.2s ease-in-out;
  background-color: #C0FF6F;
  border-radius: 50%;
  visibility: hidden;
  height: 100px;
  width: 100px;
  z-index: -1;
  box-shadow: 0px 0px 25px rgba(192, 255, 111, 1.5);
}

.button:hover {
  box-shadow: 0px 0px 155px rgba(192, 255, 111, 0.5);
  color: #1B3B3D;



}

.type1:hover::after {
  visibility: visible;
  transform:  translateY(-87%);
}


</style>