<template>
  <div class="container">
    <div class="position-relative">
      <div class="left-column">
        <p v-for="(paragraph, key) in paragraphs" v-bind:key="key"> {{ paragraph }} </p>
        <div class="intro-subtitle"> {{ subtitle }}</div>
      </div>
      <div style="position: absolute; top: 0; right: 0" v-if="objectPath && false">
        <div>
          <RotatingOObjectComponent :object-path="objectPath"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RotatingOObjectComponent from "@/components/Components/RotatingObjectComponent.vue";

export default {
  name: "OneProductIntroComponent",
  components: {RotatingOObjectComponent},
  props: {
    paragraphs: {
      type: Array,
    },
    subtitle: {
      type: String,
      default: ''
    },
    objectPath: {
      type: String,
      default: null
    },
  },
  mounted() {
  },
  data() {
    return {};
  },
}
</script>

<style lang="scss" scoped>

.left-column {
  width: 70%;
  padding: 40px;

  p {
    color: #2F3745;
    font-family: $font-bold;
    font-size: 27px;
    margin-bottom: 30px;
  }
}

.right-column {
  width: 30%;
}

@media (max-width: 767px) {
  .left-column, .right-column {
    width: 100%;
  }

  .right-column {
    height: 350px;
    transform: translate(-0%, 10%);
  }
}

@media screen and (min-width: 1900px) {
  .left-column {
    padding: 50px;

    p {
      font-size: 35px;
    }
  }
}


</style>
