<template>
  <div class="cocktails-carousel-wrapper">
    <Carousel>
      <Slide v-for="(image, index) in imageUrls" :key="index">
        <div class="carousel__item d-flex justify-content-center align-items-center">
          <img :src="image" alt="Decorative image" class="image" >
        </div>
      </Slide>

      <template #addons>
        <Navigation>
          <template #next>
            <span class="carousel-arrow-right"><ArrowRightIcon :color="'black'" /></span>
          </template>
          <template #prev>
            <span class="carousel-arrow-left"><ArrowLeftIcon :color="'black'" /></span>
          </template>
        </Navigation>
        <div class="carousel-pagination d-flex justify-content-center align-items-center">
          <Pagination />
        </div>
      </template>
    </Carousel>
  </div>


</template>

<script>
import {defineComponent} from 'vue'
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel'
import ArrowLeftIcon from "@/components/Common/Icons/ArrowLeftIcon";
import ArrowRightIcon from "@/components/Common/Icons/ArrowRightIcon";

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'CocktailsCarousel',
  props: {
    imageUrls: {
      type: Array,
      required: true
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ArrowLeftIcon,
    ArrowRightIcon
  },

})
</script>

<style lang="scss">
.image {
  max-height: 300px;
}

.carousel__item {
  min-height: 200px;
  width: 10%;
  background-color: transparent;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background: transparent;
}

.carousel__pagination {
  background: #1b3b3d;
  border-radius: 50px;
  padding: 0px 20px;
  display: flex;
}

.cocktails-carousel-wrapper {
  .carousel__viewport {
    width: 370px;
  }
}


@media screen and (min-width: 768px) {
  .cocktails-carousel-wrapper {
    .carousel__viewport {
      width: 550px;
    }
  }
  .image {
    max-height: 500px;
  }

}
</style>
