<script setup>

</script>

<template>
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.88574 1.87781L15.9995 15.9915" stroke="black" stroke-width="2" stroke-linecap="round"/>
    <path d="M15.9995 1.88501L1.88062 16.0039" stroke="black" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>
