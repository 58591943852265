<template>
  <div class="d-flex justify-content-center align-items-center one-product-wrapper">
    <div>
      <div class="thumbnail-container">
        <img :src="thumbUrl" alt="Decorative image" class="image">
      </div>
      <div class="brand-title"> {{ cocktailTitle }}</div>
      <div class="brand-subtitle"> {{ cocktailSubtitle }}</div>
    </div>


  </div>

</template>

<script>

export default {
  name: "OneCocktailBox",
  components: {

  },
  props: {
    cocktailTitle: {
      type: String,
      default: ''
    },
    cocktailSubtitle: {
      type: String,
      default: ''
    },
    thumbUrl: {
      type: String,
      default: '',
    },
  },

}
</script>

<style lang="scss" scoped>
.thumbnail-container {
  height: 200px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .image {
    transition: 0.3s ease-in-out;
    max-height: 180px;
    max-width: 120px;
  }
  &:hover {
  .image {
    transform: scale(1.05);
    transition: 0.3s ease-in-out;
  }
}
}
.one-product-wrapper {
  text-align: center;
  cursor: pointer;
  .brand-title {
    padding-top: 5px;
    font-size: 14px;
    font-family: $font-bold;
  }
  .brand-subtitle {
    padding-top: 5px;
    font-size: 13px;
  }
}
.brand-title {
  padding-top: 10px;
  font-size: 14px;
}
@media only screen and (min-width: 768px) {
  .thumbnail-container {
    height: 350px;
    .image {
      max-height: 320px;
      max-width: 235px;
    }
  }

}

</style>