import { createRouter, createWebHistory } from "vue-router";
// import ComingSoonView from "../views/ComingSoonView.vue";
import ProductsView from "../views/ProductsView.vue";
import AllProductsView from "@/views/AllProductsView";
import AllCocktailsView from "@/views/AllCocktailsView";
import OurStoryView from "@/views/OurStoryView";
import ContactsView from "@/views/ContactsView";
import TermsView from "@/views/TermsView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: ProductsView,
  },
  {
    path: "/products/:brand",
    name: "SingleProduct",
    component: ProductsView,
  },
  {
    path: "/all-products",
    name: "AllProductsView",
    component: AllProductsView,
  },
  {
    path: "/all-cocktails",
    name: "AllCocktailsView",
    component: AllCocktailsView,
  },
  {
    path: "/our-story",
    name: "OurStoryView",
    component: OurStoryView,
  },
  {
    path: "/contacts",
    name: "ContactsView",
    component: ContactsView,
  },
  {
    path: "/terms",
    name: "TermsView",
    component: TermsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
