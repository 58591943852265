<template>
  <button class="button type1" v-bind:class="{'dark-theme': theme === 'dark', 'dark-red-theme': theme === 'red'}">
    <span class="btn-txt d-flex align-items-center">
      <div class="icon-roll" v-if="showIcon">
        <div class="icon-dark icon-down">
           <PlayIcon :theme="'dark'"/>
        </div>
        <div class="icon-light icon-down">
           <PlayIcon :theme="'light'"/>
        </div>
      </div>
      <div class="button-text-roll ms-2">
        <span class="label-up "> {{ buttonText }}</span>
        <span class="label-up ">{{ buttonText }}</span>
      </div>
    </span>
  </button>
</template>

<script>
import PlayIcon from "@/components/Common/Icons/PlayIcon";
export default {
  name: "BorderOutlineButton",
  components: {
    PlayIcon
  },
  props: {
    buttonText: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: ''
    },
    showIcon: {
      type: Boolean,
      default: true
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/fonts.scss";


.button {
  padding: 5px 70px;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  border: 2px solid #FFFFFF;
  overflow: hidden;
  border-radius: 50px;
  color: #FFFFFF;
  transition: all 0.2s ease-in-out;
  line-height: 20px;
  .button-text-roll, .icon-roll {
    display: inline-block;
    height: 40px;
    text-align: center;
    overflow: hidden;
    position: relative;
    color: white;
    transition: 0.3s;

    .label-up, .icon-down {
      display: block;
      margin: 0 0;
      height: 100%;
      position: relative;
      top: 20%;
      transition: 0.5s;
      font-size: 14px;
    }
    .icon-down {
      top: -82.5%;
    }
  }
  &:hover {
    box-shadow: 0px 0px 155px rgba(192, 255, 111, 0.5);
    color: #1B3B3D;
    background: #FFFFFF;
    .icon-roll .icon-down {
      top: 17.5%;
    }
    .button-text-roll .label-up,  {
      top: -80%;
      color: #1B3B3D;
    }
  }

  .btn-txt {
    z-index: 1;
  }



  .type1::after {
    content: "";
    position: absolute;
    left: 0%;
    bottom: -87%;
    transition: all 0.2s ease-in-out;
    background-color: #FFFFFF;
    border-radius: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    z-index: -1;
    box-shadow: 0px 0px 25px rgba(192, 255, 111, 1.5);
  }

  .button:hover {
    box-shadow: 0px 0px 155px rgba(192, 255, 111, 0.5);
    color: #1B3B3D;
  }

  .type1:hover::after {
    visibility: visible;
    transform: translateY(-87%);
  }
}

.dark-theme {
  border: 2px solid #2f9358;
  .button-text-roll {
    color: #2f9358;
  }
}

.dark-red-theme {
  border: 2px solid #932f4d;
  .button-text-roll {
    color: #751e38;
  }

}


</style>