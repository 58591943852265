export default {
    namespaced: true,
    state: {
        showHeader: true
    },
    mutations: {
        TOGGLE_HEADER(state, payload) {
            state.showHeader = payload;
        },
    },
    actions: {
        toggleHeader({commit}, payload) {
            commit('TOGGLE_HEADER', payload);
        },
    }
};
