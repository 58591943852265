<script>
export default {
  name: "MapPinIcon",
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>

  <div class="map-pin-wrapper">
    <div>{{ title }}</div>
    <div class="d-flex justify-content-center mt-1">
      <svg width="29" height="36" viewBox="0 0 29 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_401_59)">
          <path
              d="M0.39502 14.3144C0.433487 7.51657 5.37656 1.82317 12.1468 0.547394C19.9365 -0.899757 26.9183 4.45089 28.3416 11.2106C29.5918 17.0373 27.0722 23.3781 21.6098 26.4057C21.0713 26.7104 20.6866 27.0912 20.3596 27.6053C18.994 29.8903 17.6092 32.1753 16.2244 34.4412C15.6858 35.3361 14.7241 35.717 13.8586 35.3171C13.4547 35.1267 13.07 34.7649 12.82 34.3841C11.4159 32.1372 10.0696 29.8522 8.66552 27.6053C8.35778 27.1293 7.91541 26.6913 7.41533 26.4057C3.43395 23.9684 1.08743 20.5028 0.491188 15.9139C0.452721 15.3997 0.433487 14.8475 0.39502 14.3144ZM14.551 24.3492C19.975 24.3683 24.6488 20.5219 24.7449 14.2572C24.8219 8.75426 20.1865 4.20335 14.5895 4.18431C9.01173 4.16527 4.33793 8.6781 4.3764 14.1811C4.41487 20.4457 9.06943 24.3492 14.551 24.3492Z"
              fill="white"/>
          <circle cx="14.5257" cy="14.2832" r="3.06325" fill="#C0FF6F"/>
        </g>
        <defs>
          <clipPath id="clip0_401_59">
            <rect width="28.2736" height="35.1696" fill="white" transform="translate(0.39502 0.318909)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>


</template>

<style lang="scss" scoped>
.map-pin-wrapper {
  color: #FFFFFF;
  font-size: 12px;
  transition: 0.5s ease-in-out;
}

circle {
  transition: 0.5s ease-in-out;
}

.map-pin-wrapper:hover {
  animation: bounce 0.5s ease-in-out;
  transform: translateY(-15px); /* Adjust the value to control the movement */
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-12px); /* Start and end at the original position */
  }
  50% {
    transform: translateY(-20px); /* Bounce up */
  }
}

.map-pin-wrapper:hover circle {
  r: 7; /* Change this value to your desired radius */
  transition: r 0.5s ease-in-out; /* Adjust duration and timing function as needed */
}

</style>