<template>
  <div class="container d-flex align-items-center">
    <div class="icon-container">
      <div class="me-1" v-if=" buttonIcon === 'language'">
        <LanguageIcon :theme="theme === 'light' ? 'light' : 'dark'"/>
      </div>
      <div class="me-1" v-if=" buttonIcon === 'facebook' ">
        <FacebookIcon :theme="theme === 'light' ? 'light' : 'dark'"/>
      </div>
      <div class="me-1" v-if=" buttonIcon === 'instagram' ">
        <InstagramIcon :theme="theme === 'light' ? 'light' : 'dark'"/>
      </div>
      <div class="me-1 d-flex  align-content-start " v-if=" buttonIcon === 'arrow-left' ">
        <ArrowLeftIcon :color="'white'"/>
      </div>
      <div class="me-1 d-flex  align-content-start " v-if=" buttonIcon === 'arrow-left-black' ">
        <ArrowLeftIcon :color="'black'"/>
      </div>
      <div class="me-1 d-flex align-content-center" v-if=" buttonIcon === 'arrow-up' ">
        <ArrowUpIcon/>
      </div>
      <div class="me-1 d-flex align-content-center" v-if=" buttonIcon === 'store' ">
        <StoreIcon/>
      </div>
      <div class="me-2 product-logos" v-if=" buttonIcon === Brands.TATRA_TEA ">
        <TatrateaLogo/>
      </div>
      <div class="me-2 product-logos" v-if=" buttonIcon === Brands.CZECHOSLOVAKIA ">
        <CzechoslovakiaLogo/>
      </div>
      <div class="me-2 product-logos" v-if=" buttonIcon === Brands.MATES ">
        <MatesLogo/>
      </div>
      <div class="me-2 product-logos" v-if=" buttonIcon === Brands.BERKSHIRE ">
        <BerkshireLogo/>
      </div>
      <div class="me-2 product-logos" v-if=" buttonIcon === Brands.XIAMAN ">
        <XiamanLogo :height="20"/>
      </div>
      <div class="me-2 product-logos" v-if=" buttonIcon === 'karloff' ">
        <KarloffLogo/>
      </div>
      <div class="me-2 product-logos" v-if=" buttonIcon === Brands.GIN_1689 ">
        <Gin1689Logo/>
      </div>
      <div class="me-2 product-logos" v-if=" buttonIcon === Brands.LALA ">
        <LalaTequilaLogo/>
      </div>
      <div class="me-2 product-logos" v-if=" buttonIcon === Brands.PADRE_AZUL ">
        <PadreAzulLogo/>
      </div>
      <div class="me-2 product-logos" v-if=" buttonIcon === Brands.SAN_COSME ">
        <SanCosmeLogo/>
      </div>
      <div class="me-2 product-logos" v-if=" buttonIcon === Brands.ON_LEMON ">
        <OnLemonLogo/>
      </div>
      <div class="me-2 product-logos" v-if=" buttonIcon === Brands.GELSTONS ">
        <SamuelGelstonLogo :width="40" :height="40"/>
      </div>
      <div class="me-2 product-logos" v-if=" buttonIcon === Brands.BANDOENG ">
        <BandoengLogo :height="30"/>
      </div>
    </div>

    <div class="button" v-bind:class="{
    'theme-dark': theme === 'dark',
    'theme-light': theme === 'light',
    'hover-green': theme === 'green-hover',
    'size-small': size === 'small',
    }">
      <span class="label-up "> {{ buttonText }}</span>
      <span class="label-up ">{{ buttonText }}</span>
    </div>
    <div>
      <div class="ms-1 d-flex align-self-center" v-if=" buttonIcon === 'arrow-down' ">
        <ArrowDownIcon :color="'dark'"/>
      </div>
    </div>
  </div>

</template>

<script>
import LanguageIcon from "@/components/Common/Icons/LanguageIcon";
import FacebookIcon from "@/components/Common/Icons/FacebookIcon";
import InstagramIcon from "@/components/Common/Icons/InstagramIcon";
import BerkshireLogo from "@/components/Common/Icons/BerkshireLogo.vue";
import Gin1689Logo from "@/components/Common/Icons/Gin1689Logo.vue";
import KarloffLogo from "@/components/Common/Icons/KarloffLogo.vue";
import CzechoslovakiaLogo from "@/components/Common/Icons/CzechoslovakiaLogo.vue";
import LalaTequilaLogo from "@/components/Common/Icons/LalaTequilaLogo.vue";
import MatesLogo from "@/components/Common/Icons/MatesLogo.vue";
import PadreAzulLogo from "@/components/Common/Icons/PadreAzulLogo.vue";
import SanCosmeLogo from "@/components/Common/Icons/SanCosmeLogo.vue";
import TatrateaLogo from "@/components/Common/Icons/TatrateaLogo.vue";
import XiamanLogo from "@/components/Common/Icons/XiamanLogo.vue";
import OnLemonLogo from "@/components/Common/Icons/OnLemonLogo";
import ArrowLeftIcon from "@/components/Common/Icons/ArrowLeftIcon";
import ArrowDownIcon from "@/components/Common/Icons/ArrowDownIcon";
import ArrowUpIcon from "@/components/Common/Icons/ArrowUpIcon";
import StoreIcon from "@/components/Common/Icons/StoreIcon";
import BandoengLogo from "@/components/Common/Icons/BandoengLogo.vue";
import SamuelGelstonLogo from "@/components/Common/Icons/SamuelGelstonLogo.vue";
import {Brands} from "@/enum/brands";

export default {
  name: "MainButton",
  computed: {
    Brands() {
      return Brands
    }
  },
  components: {
    SamuelGelstonLogo,
    BandoengLogo,
    OnLemonLogo,
    LanguageIcon,
    FacebookIcon,
    InstagramIcon,
    BerkshireLogo,
    Gin1689Logo,
    KarloffLogo,
    CzechoslovakiaLogo,
    LalaTequilaLogo,
    MatesLogo,
    PadreAzulLogo,
    SanCosmeLogo,
    TatrateaLogo,
    XiamanLogo,
    ArrowLeftIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    StoreIcon,
  },
  props: {
    buttonText: {
      type: String,
      default: ''
    },
    buttonIcon: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'light'
    },
    hover: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/fonts.scss";

.container {
  width: 100%;
  text-align: center;
  margin: 0 0;
  font-family: sans-serif;
  cursor: pointer;
}

.product-logos {
  width: 90px;
}


.button {
  display: inline-block;
  height: 30px;
  text-align: center;
  overflow: hidden;
  position: relative;
  color: white;
  transition: 0.3s;

  .label-up {
    display: block;
    margin: 0 0;
    height: 100%;
    position: relative;
    top: 20%;
    transition: 0.5s;
    font-size: 14px;
  }

  &:hover {
    background: transparent;

    .label-up {
      top: -80%;
    }
  }

}

.theme-dark {
  color: #2F3745;
}

.theme-light {
  color: #ffffff;
}

.hover-green {
  color: #4E5768;

  &:hover .label-up {
    color: #C0FF6F;
  }
}

.size-small {
  text-align: left;

  .label-up {
    font-size: 11px;
  }
}

</style>